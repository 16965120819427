.apply_form_wrapper .inner-get-free-quote h2 {
    margin-bottom: 50px;
}
.apply_form_wrapper .apply-form-field .inner-fields {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 15px;
}
.apply_form_wrapper .apply-form-field > div {
    padding: 0 10px;
}
.apply_form_wrapper .get-quote-inner-field.full.button input {
    background: #f24a34;
    width: 230px;
    border: 0;
    color: #fff;
    font-size: 17px;
    text-transform: capitalize;
    font-weight: 600;
    letter-spacing: 1px;
    border-radius: 4px;
    float: right;
    margin-left: auto;
    cursor: pointer;
    border: 1px solid transparent;
    padding: 18px;
    -webkit-appearance: none;
    font-family: poppins !important;
}
.apply_form_wrapper .get-quote-inner-field.full.button input:focus {
    outline: 0;
}
.apply_form_wrapper .get-quote-inner-field.full.button input:hover {
    background: transparent;
    border: 1px solid #f24934;
    color: #f24934;
}
.apply_form_wrapper .willing-reloc {
    margin: 25px 0;
}

.apply_form_wrapper .willing-reloc legend {
    color: #444a60;
    font-family: Poppins;
    letter-spacing: 1px;
    font-size: 18px;
    padding-bottom: 10px;
    font-weight: 600;
}


.willing-reloc.file input {
    color: #2b2b2b;
    -webkit-appearance: none;
    box-shadow: none;
    border: 0;
    border-radius: 3px;
    min-width: 150px;
    font-size: 14px;
    outline: 0;
    cursor: pointer;
    width: 350px;
    box-sizing: border-box;
    margin: 10px 0;
}
.apply_form_wrapper .willing-reloc .input_file_custom {
    position: relative;
}
.apply_form_wrapper .willing-reloc .input_file_custom::-ms-clear {
    display: none;
    visibility: hidden;
    opacity: 0;
    box-shadow: none;
    overflow: hidden;
}
/* .apply_form_wrapper .willing-reloc .input_file_custom::before {
    content: "Choose File";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    line-height: 1;
    font-size: 14px;
    background: #dadada;
    padding: 8px 12px;
    z-index: 1;
    pointer-events: none;
    width: 90px;
    text-align: center;
} */
.get-quote-inner-field.full.button {
    margin-top: 10px;
}
/* .apply_form_wrapper .willing-reloc.file span {
    color: #2b2b2b;
    font-size: 14px;
} */
.flex-direction-row {
    flex-direction: row !important;
}
.inner-fields + span.des_count.red {
    color: red;
    margin-top: -10px;
    display: block;
    margin-bottom: 10px;
}

@media (max-width: 959.98px) {
    .apply_form_wrapper input[type="submit"] {
        margin: 0 auto;
        float: none;
    }
}

@media only screen and (max-width:767px) {
    .apply_form_wrapper .apply-form-field {
        padding: 30px 0 0 0;
    }
}

@media (max-width:575.98px){
    .loader-style-form {
        float: none;
        margin: 8px 0px 0 0;
        display: block;
    }
    .get-quote-inner-field.full.button .form-fields {
        width: 100%;
        text-align: center;
    }
    .apply_form_wrapper .get-quote-inner-field.full.button input {
        float: none;
    }
    .loader-style-form >div, .loader-style-form >svg, .loader-style-form >img {
        display: block;
        margin: 0 auto;
        text-align: center;
    }
}