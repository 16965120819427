.get-free-quote-wrapper .get-free-quote-outer-form {
    max-width: 900px;
    margin: 0 auto;
}
.get-free-quote-wrapper .apply-form-field .inner-fields {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 15px;
}
.get-free-quote-wrapper .apply-form-field > div {
    padding: 0 10px;
}
.get-free-quote-wrapper .get-free-quote-form label {
    color: #444a60;
    font-size: 19px;
    position: static;
}
.get-free-quote-wrapper .get-free-quote-form {
    padding: 70px 0 0;
}

.get-free-quote-wrapper .get-free-quote-form .form-fields {
    width: 100%;
    padding: 0 0 30px 0;
}

.get-free-quote-wrapper .get-free-quote-form .form-fields label {
    font-size: 18px;
    color: #444a60;
    text-transform: uppercase;
    padding-bottom: 15px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    letter-spacing:1px;
}

.get-free-quote-wrapper .get-free-quote-form .form-fields input, 
.get-free-quote-wrapper .get-free-quote-form .form-fields textarea {
    padding: 18px;
    border: 2px solid #e9e9e9;
    outline: 0;
    font-size: 14px;
    color: #8b8b8e;
}
.get-free-quote-wrapper .get-free-quote-form .form-fields textarea
{
    height: 120px;
}
.get-free-quote-wrapper .get-quote-inner-field.full{
    width: 100%;
}
.get-free-quote-wrapper .inner-get-free-quote.sec_padding {
    width: 100%;
}

.get-free-quote-wrapper .inner-get-free-quote h2 {
    margin-bottom: 15px;
}
.get-free-quote-wrapper .get-free-quote-form .MuiGrid-grid-xs-6-111 {
    padding: 0 15px;
}
.get-free-quote-wrapper .get-quote-inner-field.full.button input {
    background: #e05640;
    width: 230px;
    border: 0;
    color: #fff;
    font-size: 17px;
    text-transform: capitalize;
    font-weight: 600;
    font-family: poppins;
    letter-spacing: 1px;
    border-radius: 4px;
    float: right;
    margin-left: auto;
    cursor: pointer;
    border:1px solid transparent;
    padding: 18px;
    -webkit-appearance: none;
}
.get-free-quote-wrapper .get-quote-inner-field.full.button input:hover {
    background: transparent;
    border: 1px solid #f24934;
    color: #f24934;
}
.get-free-quote-wrapper .innerpagesbanner .btnWrap {
    display: none;
}
form #select-service_category {
    color: #757575;
}
form #select-service_category:focus {
    background: transparent;
}


@media (max-width: 1279.98px) {
    .get-free-quote-wrapper .inner-get-free-quote h2 {
        font-size: 30px !important;
    }
}

@media only screen and (max-width:767px)
{
    .get-free-quote-wrapper .get-quote-inner-field.full.button input:hover {
        background: transparent;
        border: 1px solid #f24934;
        color: #f24934;
    }
    .get-free-quote-wrapper .inner-get-free-quote h2 {
        padding: 0;
        line-height: 1.2 !important;
    }
    .get-free-quote-wrapper .get-free-quote-form {
        padding: 30px 0 0;
    }
    .get-free-quote-wrapper .get-free-quote-form .form-fields textarea {
        height: 60px;
    }
    .get-free-quote-wrapper .get-quote-inner-field.full.button input {
        font-size: 15px;
        padding: 15px;
        width: 160px;
    }
}