.portfolio_work_header .slick-prev:before, 
.portfolio_work_header .slick-next:before {
    color: #e05640;
}
.portfolio_work_header .portfolio_list {
    background-color: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    padding: 10px 20px;
    border-radius: 4px;
    box-sizing: border-box;
    margin: 15px;
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    cursor: pointer;
}
.portfolio_work_header .portfolio_list img {
    border-right: 1px solid #bbb;
    padding-right: 15px;
    margin-right: 15px;
    width: 35px;
    height: 35px;
}
.portfolio_work_header .portfolio_list p {
    font-size: 16px;
    color: #444a60;
    font-weight: 500;
    margin-bottom: 0px;
    line-height: 1.4;
}
.portfolio_work_header .portfolio_list small {
    color: #a3a3a3;
    font-weight: 500;
}
.portfolio_work_header.sec_padding {
    padding: 20px 0;
    border-bottom: 1px solid #ccc;
    background: #fff;
}
.portfolio_work_header .portfolio_list:hover p {
    color: #e05640;
}
.portfolio_work_header .slick-slider {
    width: calc(100% - 0px);
    margin: 0 auto;
}
.portfolio_work_header a.all_link_btn {
    display: inline-block;
    width: 60px;
    height: 60px;
    text-align: center;
    position: absolute;
    left: 50px;
    top: 0px;
    z-index: 1;
}
.portfolio_work_header a.all_link_btn p {
    line-height: 1;
    font-size: 17px;
    color: #e05640;
    font-weight: 500;
    margin-bottom: 6px;
}
.portfolio_work_header a.all_link_btn small {
    color: #a3a3a3;
    font-weight: 500;
}
.portfolio_work_header .slick-slider .slick-list {
    width: calc(100% - 70px);
    margin-left: auto;
}
.portfolio_work_content .website_img_box {
    position: relative;
}
.portfolio_work_content .website_img_box .website_big_img {
    padding-right: 180px;
    overflow: hidden;
    position: relative;
}
.portfolio_work_content .website_img_box .website_laptop_img {
    position: absolute;
    bottom: 20px;
    right: 0;
    max-width: 50%;
    z-index: 2;
}
.portfolio_work_content .website_img_box .website_big_img::before {
    content: "";
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: absolute;
    transition: all 750ms cubic-bezier(0.28, 0.64, 0.64, 0.28);
    left: 0;
    top: 0;
    z-index: 1;
}
.website_big_img.aos-init.aos-animate::before {
    content: "";
    left: 100%;
}
.portfolio_work_content .infinite-scroll-component > div:nth-child(even) .portfolio_list .website_img_box .website_big_img::before {
    content: "";
    left: inherit;
    right: 0;
}
.portfolio_work_content .infinite-scroll-component > div:nth-child(even) .portfolio_list .website_big_img.aos-init.aos-animate::before {
    right: 100%;
}
.portfolio_work_content .website_img_box .website_big_img img.big_img {
    transform: scale(1.1);
    transition: all 750ms cubic-bezier(0.215, 0.610, 0.355, 1.000);
}
.website_big_img.aos-init.aos-animate img.big_img {
    transform: scale(1);
}
.website_portfolio_info .head_paragraph.small {
    color: #a7a7a7 !important;
    line-height: 1.5 !important;
}
.website_portfolio_info .head_title {
    font-size: 38px !important;
    line-height: 1 !important;
    margin-bottom: 10px;
}
.website_portfolio_info .btnWrap {
    justify-content: flex-start;
}
.website_portfolio_info .btnWrap a.view_site {
    margin: 0;
    background-color: #e05640;
    color: #fff;
    text-transform: inherit;
    font-size: 14px;
    padding: 8px 16px;
    font-weight: 500;
    border: 1px solid transparent;
}
.website_portfolio_info .btnWrap a.view_site:hover {
    background-color: transparent;
    color: #e05640;
    border-color: #e05640;
}
.website_portfolio_info {
    max-width: 640px;
    height: 100%;
    padding-left: 50px;
    box-sizing: border-box;
}
.website_portfolio_info .add_technologies .head_paragraph {
    font-weight: 600;
    font-size: 18px !important;
    margin-bottom: 0 !important;
}
.portfolio_work_content {
    max-width: 1920px;
    margin: 0 auto;
}
.portfolio_work_content .portfolio_list {
    display: flex;
    display: -webkit-flex;
    display: -o-flex;
    display: -moz-flex;
    display: -ms-flex;
    align-items: center;
}
.portfolio_work_content .infinite-scroll-component > div:nth-child(even) .portfolio_list .website_img_box .website_big_img {
    padding-right: 0;
    padding-left: 180px;
}
.portfolio_work_content .infinite-scroll-component > div:nth-child(even) .portfolio_list .website_img_box .website_laptop_img {
    right: inherit;
    left: 0;
}
.portfolio_work_content .infinite-scroll-component > div:nth-child(even) .portfolio_list .website_portfolio_info {
    margin-left: auto;
    padding-right: 50px;
}
.portfolio_work_header .portfolio_list.active p {
    color: #e05640;
}
@-webkit-keyframes shake {
    from,
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0);
    }

    20%,
    40%,
    60%,
    80% {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0);
    }
}

@keyframes shake {
    from,
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: translate3d(0, -8px, 0);
        transform: translate3d(0, -8px, 0);
    }

    20%,
    40%,
    60%,
    80% {
        -webkit-transform: translate3d(0, 8px, 0);
        transform: translate3d(0, 8px, 0);
    }
}
.shake {
    -webkit-animation-name: shake;
    animation-name: shake;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-duration: 20s;
    animation-duration: 20s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.portfolio_list {
    overflow: hidden;
}
.website_portfolio_info .website_logo_small {
    margin-bottom: 10px;
    margin-right: 20px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
    border-radius: 24px;
}
.website_portfolio_info aside {
    display: flex;
    display: -ms-flexbox;
    align-items: flex-start;
}
.website_portfolio_info .head_paragraph.small::after {
    content: "";
    clear: both;
    display: block;
}
.website_portfolio_info .add_technologies {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    align-items: center;
    margin-bottom: 20px;
}
.website_portfolio_info .add_technologies .icons {
    padding-left: 10px;
}
.website_portfolio_info .add_technologies .icons img.tech_icons {
    height: 30px;
    margin: 5px;
    margin-right: 15px;
}
.portfolio_work_content .website_img_box .website_mobile_img {
    max-width: 140px;
    right: 80px;
}
.website_portfolio_info .website_logo_small img {
    max-width: 75px;
    vertical-align: middle;
}
.portfolio_work_content .infinite-scroll-component, .portfolio_work_content .infinite-scroll-component > div {
    width: 100%;
    display: flex;
    display: -webkit-flex;
    display: -o-flex;
    display: -moz-flex;
    display: -ms-flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    overflow: hidden !important;
}
.portfolio_work_content .infinite-scroll-component__outerdiv {
    width: 100%;
}
.portfolio_work_content .infinite-scroll-component > img {
    margin: 0 auto;
    max-width: 100%;
    width: 60px;
    height: 60px;
    display: block;
}
.portfolio_work_content img.portfolio_loader {
    width: 60px;
    height: 60px;
    margin: 25px auto 0;
}
.portfolio_work_content .no_more_portfolios h6 {
    margin: 0 auto !important;
    width: auto !important;
    border: 1px dashed #e05640;
    padding: 0px 15px;
    font-size: 30px !important;
    color: #e05640 !important;
    text-transform: capitalize;
}

/* =========13-dec-2019-S-css-start=========== */
.portfolio_work_header a.all_link_btn{
    display: none;
}
/* .portfolio_work_header .portfolio_list > p{
    -webkit-transition: 0.5s ease-in-out 0s all;
    -moz-transition: 0.5s ease-in-out 0s all;
    -ms-transition: 0.5s ease-in-out 0s all;
    transition: 0.5s ease-in-out 0s all;
}
.portfolio_work_header .portfolio_list > p {
    background-color: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    margin: 10px;
    padding: 10px 20px;
    border-radius: 4px;
} */
.portfolio_work_header .slick-slider .slick-list{
    width:100%;
}
/* .portfolio_work_header .portfolio_list.active > p ,
    .portfolio_work_header .portfolio_list:hover > p {
    background-color: #ed402c;
    color: #fff;
    box-shadow: 0 0 5px rgba(237, 64, 44,0.7);
} */
.portfolio_work_header .portfolio_list p{
    position:relative;
    overflow:hidden;
}
.portfolio_work_header .portfolio_list p:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5px;
    height: 5px;
    background: rgba(255, 255, 255, .5);
    opacity: 0;
    border-radius: 100%;
    /* transform:scale(1,1) translate(-50%); */
    transform-origin: 50% 50%;
}
.portfolio_work_header .portfolio_list p:hover::after {
    animation: buttonripple 1s ease-out;
    text-decoration: none;
}
@keyframes buttonripple {
    0% {
        transform: scale(0, 0);
        opacity: .4
    }
    20% {
        transform: scale(30, 30);
        opacity: .5
    }
    100% {
        opacity: 0;
        transform: scale(60, 60)
    }
}
/* =========13-dec-2019-S-css-end=========== */



.portfolio_work_header .sub_cat_wrap ul {
    list-style: none;
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding: 0;
    overflow: hidden;
}

.portfolio_work_header .sub_cat_wrap ul li {
    flex: 1;
    -ms-flex: 1;
    text-align: center;
    position: relative;
}

.portfolio_work_header .sub_cat_wrap ul li .tab_click {
    font-size: 13px;
    font-weight: 400;
    font-family: poppins;
    color: #444a60;
    display: block;
    padding: 15px 15px;
    position: relative;
    transition: all 0.3s ease;
    cursor: pointer;
}

.portfolio_work_header .sub_cat_wrap ul li:not(:last-child)::after {
    content: "";
    border-right: 1px solid #bbb;
    position: absolute;
    right: 0;
    top: 50%;
    height: 70%;
    transform: translateY(-50%);
}
.portfolio_work_header .sub_cat_wrap ul li .tab_click::before,
.portfolio_work_header .sub_cat_wrap ul li .tab_click::after {
    content: "";
    background-color: #ed402d;
    width: 100%;
    height: 1px;
    position: absolute;
    transition: all 0.3s ease;
    opacity: 0;
}
.portfolio_work_header .sub_cat_wrap ul li .tab_click::before {
    top: 50%;
}
.portfolio_work_header .sub_cat_wrap ul li .tab_click::after {
    bottom: 50%;
}
.portfolio_work_header .sub_cat_wrap ul li .tab_click::before {
    left: 0;
    right: 0;
}
.portfolio_work_header .sub_cat_wrap ul li .tab_click::after {
    left: 0;
    right: 0;
}
.portfolio_work_header .sub_cat_wrap ul li .tab_click span {
    position: relative;
    z-index: 1;
}
/* .portfolio_work_header .sub_cat_wrap ul .fillActive a::before, .portfolio_work_header .sub_cat_wrap ul .fillActive a::after,
.portfolio_work_header .sub_cat_wrap ul a:hover::before, .portfolio_work_header .sub_cat_wrap ul a:hover::after {
    height: 50%;
} */
.portfolio_work_header .sub_cat_wrap ul .fillActive .tab_click,
.portfolio_work_header .sub_cat_wrap ul .tab_click:hover {
    color: #ed402d;
}
.portfolio_work_header .sub_cat_wrap ul .fillActive .tab_click::after,
.portfolio_work_header .sub_cat_wrap ul .tab_click:hover::after {
    bottom: 0;
    opacity: 1;
}
.portfolio_work_header .sub_cat_wrap ul .fillActive .tab_click::before,
.portfolio_work_header .sub_cat_wrap ul .tab_click:hover::before {
    top: 0;
    opacity: 1;
}
.portfolio_work_header .sub_cat_wrap ul .fillActive .tab_click::before,
.portfolio_work_header .sub_cat_wrap ul .fillActive .tab_click::after {
    opacity: 1;
}
.portfolio_work_header .portfolio_list:hover img.d-none,
.portfolio_work_header .portfolio_list.active img.d-none {
    display: block !important;
}
.portfolio_work_header .portfolio_list:hover img,
.portfolio_work_header .portfolio_list.active img {
    display: none;
}
.portfolio_work_header .sub_cat_wrap.application_development ul {
    max-width: 768px;
    min-width: 767px;
    margin-left: auto;
    margin-right: auto;
}
.portfolio_work_header.fixed {
    position: fixed;
    top: 65px;
    width: 100%;
    z-index: 5;
    background: #fff;
}




@media (min-width:1280px) {
    .portfolio_work_content .infinite-scroll-component > div:not(:last-child) .portfolio_list {
        margin-bottom: 100px;
    }
    .portfolio_work_content .infinite-scroll-component > div:nth-child(even) .portfolio_list > div:nth-child(2) {
        order: 1;
    }
    .portfolio_work_content .infinite-scroll-component > div:nth-child(even) .portfolio_list > div:nth-child(1) {
        order: 2;
    }
    .website_portfolio_info {
        padding-top: 10px;
    }
    .portfolio_work_content .infinite-scroll-component > div:nth-child(even) .portfolio_list .website_img_box .website_mobile_img {
        right: inherit;
        left: 80px;
    }
    
}

@media (min-width: 600px) {
    .portfolio_work_content .portfolio_list .website_portfolio_info {
        min-height: 450px;
    }
}
@media (min-width: 1025px) {
    .portfolio_work_content .portfolio_list .website_portfolio_info {
        min-height: 400px;
    }
}
@media (min-width: 1280px) {
    .portfolio_work_content .portfolio_list .website_portfolio_info {
        min-height: auto;
    }
}
@media (min-width: 1366px) {
    .portfolio_work_content .infinite-scroll-component > div:nth-child(even) .portfolio_list .website_portfolio_info {
        padding-left: 0px;
    }
}

@media (min-width: 1440px) {    
    .portfolio_work_content .website_img_box .website_laptop_img {
        max-width: 500px;
    }
    .portfolio_work_content .website_img_box .website_mobile_img {
        max-width: 175px;
    }
}

@media (min-width: 1800px) {
    .portfolio_work_content .website_img_box .website_laptop_img {
        max-width: 650px;
    }
}
@media (max-width: 1800px) {
    .portfolio_work_content .website_img_box .website_logo_img{
        max-width: 440px;
    }
}
@media (max-width: 1650px) {
    .portfolio_work_content .website_img_box .website_logo_img{
        max-width: 380px;
    }
}
@media (max-width: 1480px) {
    .portfolio_work_content .website_img_box .website_logo_img{
        max-width: 320px;
    }
}

@media (max-width:1365.98px) {
    .website_portfolio_info {
        padding-left: 2em;
        padding-right: 2em;
    }
    .portfolio_work_content .website_img_box .website_logo_img{
        max-width: 300px;
    }
}

@media (max-width:1279.98px) {
    .portfolio_work_content .website_img_box .website_big_img {
        padding-right: 0;
        padding: 32px;
    }
    .portfolio_work_content .infinite-scroll-component > div:nth-child(even) .portfolio_list .website_img_box .website_big_img {
        padding-left: 0;
        padding: 32px;
    }
    .portfolio_work_content .website_img_box .website_laptop_img {
        left: 0;
        margin: 0 auto;
        bottom: 0;
        right: 0 !important;
    }
    .portfolio_work_content.sec_padding {
        padding-top: 0;
        padding-bottom: 32px;
    }
    .portfolio_work_content .portfolio_list .website_portfolio_info {
        padding-top: 40px;
    }
    .website_portfolio_info .head_title {
        font-size: 28px !important;
    }
    .website_portfolio_info .website_logo_small img {
        max-width: 60px;
    }
    .portfolio_work_header .sub_cat_wrap {
        overflow: scroll;
    }
    .portfolio_work_header .sub_cat_wrap ul {
        min-width: 1300px;
        margin-left: auto;
        margin-right: auto;
    }
    .portfolio_work_header .portfolio_list p {
        font-size: 13px;
    }
    .portfolio_work_header .slick-slider {
        width: calc(100% - 90px);
    }
    .portfolio_work_content .website_img_box .website_logo_img {
        max-width: 260px;
    }
    .portfolio_work_content .infinite-scroll-component {
        align-items: flex-start;
    }
}

@media (max-width: 1024px) {
    .portfolio_work_content .website_img_box .website_big_img::before{
        z-index: -1;
    }
    .portfolio_work_header .portfolio_list p {
        font-size: 11px;
    }
    .portfolio_work_header .sub_cat_wrap ul a:hover::before,
    .portfolio_work_header .sub_cat_wrap ul a:hover::after {
        opacity: 0;
    }
    .portfolio_work_header .sub_cat_wrap ul a:hover {
        color: #444a60;
    }
}

@media (max-width:992px){
    .portfolio_work_content .website_img_box .website_logo_img {
        max-width: 180px;
    }
}

@media (max-width: 767.98px) {
    .website_portfolio_info .head_title {
        font-size: 23px !important;
        margin-bottom: 0 !important;
    }
    .website_portfolio_info .website_logo_small img {
        max-width: 50px;
    }
}

@media (max-width: 767.98px) {
    .portfolio_work_header .portfolio_list p {
        font-size: 13px;
    }
}

@media (max-width:575.98px) {
    /* .portfolio_work_header a.all_link_btn p,
    .portfolio_work_header .portfolio_list p {
        font-size: 15px;
    } */
    .portfolio_work_header .slick-slider {
        width: calc(100% - 40px);
    }
    .portfolio_work_header a.all_link_btn {
        left: 35px;
    }
    .portfolio_work_header .slick-slider .slick-list {
        width: calc(100% - 0px);
    }
    .portfolio_work_content .website_img_box .website_laptop_img {
        left: inherit !important;
        right: 10px !important;
        max-width: 250px;
    }
    .ui_ux_design .portfolio_work_content .website_img_box .website_laptop_img {
        right: 30px !important;
        max-width: 180px;
    }
    .portfolio_work_content .website_img_box .website_laptop_img.website_mobile_img {
        left: inherit !important;
        right: 50px !important;
        max-width: 100px;
    }
    .portfolio_work_content .website_img_box .website_logo_img {
        max-width: 200px;
    }
}
@media (max-width: 575.98px){
    .portfolio_work_content .website_img_box .website_logo_img {
        max-width: 160px;
    }
}

@media (max-width:380.98px){
    .btnDownloadWrap > a img {
        max-height: 35px;
    }
}

@media (min-width: 576px) and (max-width: 1024px) {
    .portfolio_work_content .website_img_box .website_mobile_img {
        max-width: 100px;
    }
}