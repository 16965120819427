
.footer_main .footer_top {
    background-color: #131725;
    padding: 55px 0;
}
.footer_main .footer_top .addresses .title_footer {
    font-size: 17px;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    display: inline-block;
    padding-right: 8px;
    position: relative;
    letter-spacing: 0.4px;
}
.footer_main .footer_top .addresses .title_footer::after {
    content: "";
    width: 52px;
    height: 1px;
    background-color: #fff;
    position: absolute;
    left: 100%;
    top: 50%;
}
.footer_main .footer_top ul li {
    height: auto;
    padding-left: 0;
    white-space: unset;
    overflow: visible;
}
.footer_main .footer_top ul li:hover, .footer_main .footer_top ul li:focus {
    background-color: #00000000;
}
.footer_main .footer_top ul li a, .footer_main .footer_top ul li a:hover, .footer_main .footer_top ul li a:focus {
    text-decoration: none;
    outline: none;
}
.footer_main .footer_top .addresses ul li a, .footer_main .footer_top .addresses ul li p {
    font-size: 14px;
    color: #bfbfbf;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1.2px;
    white-space: normal;
}
.footer_main .footer_top .addresses .menu_wrap li a {
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    align-items: flex-start;
}
.footer_main .footer_top .addresses .menu_wrap li span.material-icons {
    background-color: #ed402c;
    font-size: 16px;
    padding: 5px;
    margin-right: 10px;
    color: #fff;
}
.footer_main .footer_top .addresses {
    margin: 0;
    border-bottom: 1px solid #424551;
    padding-bottom: 40px;
}
.footer_main .footer_top li a {
    cursor: pointer;
}
.footer_main .footer_top .footer_Qucik_links .title_footer {
    font-size: 15px;
    color: #ed402c;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    display: inline-block;
    padding-right: 8px;
    position: relative;
    letter-spacing: 2.6px;
}
.footer_main .footer_top .footer_Qucik_links ul {
    padding-top: 20px;
}
.footer_main .footer_top .footer_Qucik_links ul li a {
    font-size: 12px;
    color: #9a9a9a;
    letter-spacing: 1.5px;
    font-family: 'Poppins', sans-serif;
}
.footer_main .footer_top .footer_Qucik_links ul li {
    padding-bottom: 0;
}
.footer_main .footer_top .footer_Qucik_links aside.quickLinks {
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
}
.footer_main .footer_top .footer_Qucik_links aside.quickLinks > ul {
    width: 50%;
    max-width: 200px;
}
.footer_main .footer_top .footer_Qucik_links {
    margin: 0;
    padding-top: 30px;
}
.footer_main .footer_top a:hover ,
.footer_main .footer_top .menu_links li.active  a ,
.footer_main .footer_top .menu_list_sub_category li.active  a,
.footer_main .footer_top .footer_Qucik_links ul li.active a {
    color: #ed402c !important;
}
.footer_main .footer_top .experianceCount .yearNumber {
    position: relative;
    display: inline-block;
}
.footer_main .footer_top .experianceCount .yearNumber .count_number {
    text-transform: uppercase;
    font-style: italic;
    -webkit-text-stroke: 1px #ffff;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    line-height: 115px !important;
    font-size: 130px;
    color: rgba(0, 0, 0, 1);
    text-shadow: 0px 0px 20px rgba(251,251,251,1);
}
.footer_main .footer_top .experianceCount .yearNumber span.count_number_img {
    display: none;
}
.footer_main .footer_top .experianceCount .yearNumber .plus_year {
    position: absolute;
    right: calc(0px - 75px);
    bottom: 6px;
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    align-items: flex-end;
}
.footer_main .footer_top .experianceCount .yearNumber .plus {
    font-size: 70px;    
    font-family: 'Josefin Sans', sans-serif;
    color: #ed402c;
    font-style: italic;
    line-height: 40px !important;
}
.footer_main .footer_top .experianceCount .yearNumber .year {
    font-size: 18px;
    color: #ed402c;
    font-family: 'Poppins', sans-serif;
    font-style: italic;
    text-transform: uppercase;
    font-weight: 600;
}
.footer_main .footer_top .experianceCount .exp_word {
    text-transform: uppercase;
    font-style: italic;
    color: #d2d2d2;
    font-size: 30px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    letter-spacing: 6px;
    margin-bottom: 20px;
}
.footer_main .footer_top .experianceCount {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;
    height: 100%;
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    align-items: center;
    height:262px;
    max-width: 510px;
}
.footer_main .footer_top .experianceCount .wrap_exp_count {
    max-width: 400px;
    margin: 0 auto;
}
.footer_main .footer_top .experianceCount .wrap_exp_count aside.btn_Discuss_project a {
    background-color: #ed402c;
    color: #fff;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.4px;
    padding: 14px 30px;
    display: inline-block;
    border-radius: 4px;
    cursor: pointer;
}
.footer_main .footer_top .experianceCount .wrap_exp_count aside.btn_Discuss_project a:hover {
    color: #ed402c !important;
    background-color: #fff;
}
.footer_main .footer_bottom .copyright {
    background-color: #000;
    padding: 16px 0;
}
.footer_main .footer_bottom .copyright p {
    color: #999a9e;
    font-size: 13px;
    text-align: center;
}
.footer_main .footer_top .addresses ul li a:hover p {
    color: #e05640;
}
.footer_main .footer_top .addresses .menu_wrap li a.for_person {
    pointer-events: none;
}
.footer_bottom_mobile {
    display: none;
}
.menu_list_sub_category .list_style_inner_category.active a{
    padding-left:5px;
}



@media (max-width: 991.98px) {
    .footer_main .footer_top .addresses .title_footer::after {
        opacity: 0;
    }
    .footer_main .footer_top .footer_Qucik_links .title_footer {
        font-size: 14px;
        padding-right: 0px;
    }
    .footer_main .footer_top .footer_Qucik_links ul li {
        padding-right: 5px;
    }
    .footer_main .footer_top .footer_Qucik_links ul li a img {
        max-width: 140px;
    }
    .footer_main .footer_top .experianceCount .yearNumber .count_number {
        line-height: 1 !important;
        font-size: 90px;
    }
    .footer_main .footer_top .experianceCount .exp_word {
        font-size: 22px;
    } 
    .footer_main .footer_top .experianceCount .wrap_exp_count aside.btn_Discuss_project a {
        font-size: 13px;
        padding: 10px;
    }
    .footer_main .footer_top {
        padding: 30px 0;
    }   
    .footer_main .footer_top .experianceCount {
        height: 220px;
    }
    .footer_main .footer_top .addresses ul li p {
        font-size: 12px;
    }
    .footer_main .footer_top .addresses .menu_wrap li span.material-icons {
        font-size: 14px;
        padding: 3px;
    }
    .footer_main .footer_top .addresses .title_footer {
        font-size: 14px;
    }
    .footer_main .footer_top .addresses {
        padding-bottom: 30px;
    }
    .footer_main .footer_top .footer_Qucik_links aside.quickLinks > ul {
        width: auto;
        padding-right: 20px;
    }

}

@media (max-width:767.98px) {
    .footer_main .footer_top {
        padding: 40px 0px;
    }
    .footer_main .footer_top .addresses {
        padding-bottom: 20px;
    }
    .footer_bottom_mobile {
        display: block;
    }
    .footer_bottom_mobile {
        background-color: #ed402c;
        color: #fff;
        font-size: 16px;
        font-family: 'Poppins', sans-serif;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.4px;
        padding: 14px 30px;
        display: inline-block;
        border-radius: 0;
        cursor: pointer;
        text-align: center;
    }
    
    .footer_bottom_mobile a {
        color: #fff;
    }
}

@media (max-width:575.98px) {
    .footer_main .footer_top .footer_Qucik_links aside.quickLinks {
        flex-direction: column;
    }
    .footer_main .footer_top .footer_Qucik_links aside.quickLinks .menu_links:nth-child(2) , .footer_main .footer_top .footer_Qucik_links aside.quickLinks .menu_links:nth-child(2) li:nth-child(1) {
        padding-top: 0;
    }
    .footer_main .footer_top .footer_Qucik_links aside.quickLinks .menu_links {
        width: 100%;
        max-width: 100%;
    }
    .footer_main .footer_top .experianceCount {
        height: auto;
    }
    .footer_main .footer_top .footer_Qucik_links ul li a {
        margin: 0 auto;
    }
    .footer_main .footer_top .footer_Qucik_links {
        text-align: center;
    }
    .footer_main .footer_top {
        display: none;
    }
}

/* =======Visions-new-style-start-2-Dec ========*/
.footer_main .top_content_style .title_main_footer {
    font-size: 32px;
    color: #fff;
    font-family: 'Saira Extra Condensed';
    font-weight: bold;
    letter-spacing: 1.5px;
}
.footer_main .top_content_style {
    align-items: center;
    padding-bottom: 18px;
    border-bottom: 1px solid #424551;
}
.footer_main .top_content_style .content_style_inner {
    margin-right: 20px;
}
.footer_main .top_content_style aside.btn_Discuss_project a {
    background-color: #ed402c;
    color: #fff;
    font-size: 15px;
    position: relative;
    font-family: 'Poppins', sans-serif;
    text-transform: capitalize;
    font-weight: 600;
    letter-spacing: 0.4px;
    padding: 14px 30px;
    display: inline-block;
    border-radius: 4px;
    cursor: pointer;
    -webkit-transition: 0.3s ease-in-out 0s all;
    -ms-transition: 0.3s ease-in-out 0s all;
    transition: 0.3s ease-in-out 0s all;
}
.footer_main .top_content_style  aside.btn_Discuss_project a:hover {
    border-color: #fff;
    color: #ed402c;
    background-color: transparent;
}
.footer_main .top_content_style  aside.btn_Discuss_project a::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: -1;
    transition: transform 0.4s;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    border-radius: 4px;
    -webkit-transition: 0.3s ease-in-out 0s all;
    -ms-transition: 0.3s ease-in-out 0s all;
    transition: 0.3s ease-in-out 0s all;
}
.footer_main .top_content_style  aside.btn_Discuss_project a:hover::before {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}
.footer_main .footer_top .experianceCount.col_style_left_add .yearNumber .count_number {
    text-transform: uppercase;
    font-style: normal;
    -webkit-text-stroke: 2px #d2d2d2;
    line-height: 96px !important;
    font-size: 110px;
    color: #2e313c;
    text-shadow: 0px 0px 8px rgba(251,251,251,1);
    font-family: 'Josefin Sans';
    font-weight: 600;
}
.footer_main .footer_top .experianceCount.col_style_left_add .yearNumber .plus_year {
    right: calc(0px - 61px);
    bottom: 15px;
}
.main_col_style {
    display: flex;
    display: -ms-flexbox;
    flex-wrap: wrap;
}
.col_style_right_add {
    width: auto;
    max-width: calc(100% - 320px);
}
.footer_main .footer_top .experianceCount.col_style_left_add {
    height: auto;
    align-items: flex-start;
    justify-content: flex-start;
}
.footer_main .footer_top .experianceCount.col_style_left_add .wrap_exp_count {
    max-width: 100%;
    margin: 0;
}
.footer_main .footer_top .experianceCount.col_style_left_add .yearNumber .year {
    font-size: 14px;
    color: #ed402c;
    font-family: 'Josefin Sans';
    font-style: italic;
    text-transform: uppercase;
    font-weight: 600;
}
.footer_main .footer_top .experianceCount.col_style_left_add .yearNumber .plus {
    font-size: 54px;
    font-family: 'Josefin Sans';
    color: #ed402c;
    font-style: italic;
    line-height: 25px !important;
    font-weight: normal;
}
.footer_main .footer_top .experianceCount .exp_word {
    font-style: normal;
    font-size: 23px;
    font-family: 'Josefin Sans'!important;
    margin-bottom: 10px;
    letter-spacing: 4px;
    font-weight: 800;
    margin-top: -5px;
}
.col_padding_left_zero{
    padding-left:0!important;
}
.col_padding_right_zero{
    padding-right:0!important;
}
.footer_main .footer_top .addresses.addresses_new_style .title_footer {
    font-size: 15px;
    line-height: 1.4;
    color: #fff;
    font-family: 'Josefin Sans';
    text-transform: capitalize;
    font-weight: 800;
}
.footer_main .footer_top .addresses.addresses_new_style .title_footer::after{
    display: none;
}
.footer_main .footer_top .addresses.addresses_new_style ul li a, 
.footer_main .footer_top .addresses.addresses_new_style ul li p {
    font-size: 12px;
    color:#949494;
}
.footer_main .footer_top .addresses.addresses_new_style .menu_wrap li span.material-icons {
    background-color: transparent;
    font-size: 16px;
    padding: 0;
    margin-right: 10px;
    color: #949494;
}
.d_flex{
    display: flex;
    display: -ms-flexbox;
}
.menu_list_wrap_main {
    align-items: flex-start;
    flex-wrap: wrap;
    border-bottom: 1px solid #424450;
}
.main_link_side_content {
    padding-left:35px;
}
.content_inner_side.wdth_auto{
    width: auto;
}
.addresses_new_style .menu_wrap li {
    padding-top: 2px;
    padding-bottom: 2px;
}
.addresses_new_style .col_style_right_add .menu_wrap li:first-child{
    padding-bottom: 12px;
}
.footer_main .footer_top .addresses.addresses_new_style .menu_wrap li span.material-icons{
    font-size: 14px;
}
.footer_main .footer_top .addresses.addresses_new_style {
    padding: 0;
}
.footer_main .footer_top .addresses.addresses_new_style > div {
    padding: 20px 12px;
}
.menu_list_sub_category .title_menu_main a {
    color: #fff;
    font-size: 15px;
    font-family: 'Josefin Sans';
    font-weight: 600;
}
.menu_list_sub_category .title_menu_main{
    margin-bottom: 5px;
}
.list_style_inner_category a{
    color:#949494;
    font-size: 13px;
}
.menu_list_sub_category .list_style_inner_category {
    padding: 0 0 8px;
    line-height: 1;
    cursor: text;
}
.menu_list_sub_category .title_menu_main:focus {
    box-shadow: none;
    outline: 0;
}
.main_wrap_style_link {
    width: 100%;
}
.menu_list_wrap_main .list_style_inner_wrap {
    width: calc(16% - 16px);
}
.menu_list_wrap_main .list_style_inner_wrap:last-child {
    padding-right: 0;
    width: 20%;
}
.d_none{
    display: none;
}
.footer_menu_bottom .d_flex{
    justify-content: center;
}
.footer_menu_bottom {
    width: 100%;
}
.footer_main .footer_top .footer_menu_bottom ul li a {
    font-size: 12px;
    color: #9a9a9a;
    letter-spacing: 1.5px;
    font-family: 'Poppins', sans-serif;
    padding: 5px 0;
    margin: 0 10px;
}
.footer_main .footer_top {
    padding: 20px 0 0;
}
.footer_menu_bottom li {
    position: relative;
}
.footer_menu_bottom li:not(:last-child)::after {
    content: "";
    width: 1px;
    height: 10px;
    background: #898a8b;
}

.addresses_new_style .main_link_side_content {
    padding-left: 50px!important;
}
.menu_list_sub_category .list_style_inner_category:not(.title_menu_main) a {
    display: flex;
    display: -ms-flexbox;
    transition: all 0.3s ease;
    position: relative;
    font-size: 12px;
}
.menu_list_sub_category .list_style_inner_category:not(.title_menu_main) a:hover {
    padding-left: 3px;
}
.menu_list_sub_category .list_style_inner_category:not(.title_menu_main) a i {
    font-size: 15px;    
    transition: all 0.3s ease;
}
.menu_list_sub_category .list_style_inner_category:not(.title_menu_main) a:hover i {
    left: -14px;
}
.footer_main .footer_top ul li {
    overflow: visible;
}
.footer_menu_bottom li {
    padding: 0;
}
.menu_list_sub_category .list_style_inner_category:last-child {
    padding-bottom: 0;
}
.social_links {
    cursor: default;
}
.social_links img {
    width: 14px;
    height: 14px;
    vertical-align: middle;
}
.social_links a {
    background: #ed402d;
    padding: 5px;
    margin: 5px 10px 0px 0px;
    border: 1px solid transparent;
}
.social_links a:hover {
    border-color: #fff;
}

@media (min-width: 1280px) {
    .footer_main .footer_top .addresses.addresses_new_style .follow_us {
        max-width: 15.666667%;
        flex-basis: 15.666667%;
    }
}

@media (max-width: 1279.98px) {
    .footer_main .footer_top .experianceCount.col_style_left_add .yearNumber .count_number {
        line-height: 80px !important;
        font-size: 70px;
    }
    .footer_main .footer_top .experianceCount .exp_word {
        font-size: 17px;
    }
}

@media(max-width:1024px){
    .menu_list_wrap_main .list_style_inner_wrap {
        width: calc(25% - 16px);
    }
    .menu_list_wrap_main .list_style_inner_wrap:last-child {
        width: 30%;
    }
    .col_style_right_add {
        max-width: calc(100% - 250px);
    }
    .addresses_new_style .main_link_side_content {
        padding-left: 10px!important;
    }
}
@media(max-width:959px){
    .footer_main .footer_top .experianceCount.col_style_left_add{
        max-width: 100%;
    }
    .addresses_new_style .main_link_side_content {
        padding-left: 0px!important;
    }
    .addresses_new_style .experianceCount.col_style_left_add {
        display: none;
    }
    .content_style_inner, .button_group_link {
        display: block;
        text-align: center;
        width: 100%;
    }
    .button_group_link {
        margin: 15px 0 0;
    }
    .footer_main .footer_top .footer_menu_bottom ul li a {
        margin: 0 6px;
    }
}
@media(max-width:830px){
    .menu_list_wrap_main .list_style_inner_wrap {
        width: calc(33.3% - 16px);
    }
    .menu_list_wrap_main .list_style_inner_wrap:last-child {
        width: 33.3%;
    }
    .footer_main .footer_top .footer_menu_bottom ul li a {
        font-size: 11px;
    }
}