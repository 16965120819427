.career-advantage {
    background-color: #151c37;
    width: 100%;
    padding: 80px 20px;
}
.jobs-with-us {
    width: 100%;
}
.exp-list-wrapper{
    background-image: url(../images/careers/career-iphone.png);
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: #f2f3f7;
    width: 100%;
    padding: 35px 0;
    margin-bottom: 40px;
}
/* .listing-experience-wrapper:nth-child(odd) .exp-list-wrapper
{
    background-image: url(../images/careers/career-php.png);
} */
.main_career .two_coloumn_Below_Banner.layout_2 .right_info{
    background-image: url(../images/careers/outstanding-professionals.png);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 90px 50px;
}
.main_career .two_coloumn_Below_Banner.layout_2 .left_info ul {
    padding: 0 0 20px;
    margin: 0;
    width:100%;
}
.main_career .two_coloumn_Below_Banner.layout_2 .left_info ul li {
    list-style: none;
    position: relative;
    font-size: 16px;
    color: #444a60;
    padding: 0 0 20px 20px;
    line-height: 1.5;
    font-family: "Poppins", sans-serif;
}

.main_career .two_coloumn_Below_Banner.layout_2 .left_info ul li:before {
    position: absolute;
    content: "";
    width: 8px;
    height: 8px;
    background: #f24934;
    border-radius: 50%;
    left: 0;
    top: 7px;
}
.main_career .two_coloumn_Below_Banner.layout_2 .left_info p {
    font-size: 16px;
    color: #444a60;
    padding-bottom: 20px;
    line-height: 1.5;
    width: 100%;
}
.main_career .two_coloumn_Below_Banner.layout_2.two_coloumn_Below_Banner.layout_2 .right_info h4 {
    font-size: 25px;
    font-weight: 600;
}
.main_career .career-advantage h2 {
    font-family: 'Saira Extra Condensed', sans-serif;
    font-size: 50px;
    color: #fff;
    padding-bottom: 30px;
}
.main_career .career-advantage p {
    font-size: 16px;
    color: #fff; 
    margin: 25px 0;  
}

.main_career .btnWrap.email a.view_work {
    background: #fff;
    color: #333333;
}

.main_career .btnWrap.email a.view_work:hover {
    background: transparent;
    color: #fff;
    border: 1px solid #fff;
}
.jobs-with-us h2 {
    padding-bottom: 60px;
}

.listing-experience-wrapper .experience-left {
    position: absolute;
    left: 0;
    top: 0px;
    width: 90px;
    padding: 10px 0px;
    background: #f24934;
}

.listing-experience-wrapper {
    position: relative;
}
.listing-experience-wrapper .experience-left h5 {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 35px;
    padding: 0 5px;
}
.listing-experience-wrapper .experience-left p {
    color: #fff;
    font-weight: 600;
    font-size: 13px;
    border-top: 1px solid #f7958a;
    padding: 5px 5px 0;
}
.listing-experience-wrapper .experience-right {
    padding-left: 120px;
}
.listing-experience-wrapper .experience-right h4 {
    font-family: 'Saira Extra Condensed', sans-serif;
    font-size: 26px;
    color: #444a60;
    padding-bottom: 10px;
    font-weight: bold;
    letter-spacing: 1px;
}
.exp-list-wrapper-inner {
    padding-left: 120px;
}

.exp-list-wrapper .exp-list-wrapper-inner ul {
    padding: 0;
    margin: 0;
}

.exp-list-wrapper .exp-list-wrapper-inner .descriptionWrap {
    margin-top: 20px;
}

.exp-list-wrapper .exp-list-wrapper-inner .descriptionWrap:first-child {
    margin-top: 0;
}

.exp-list-wrapper .exp-list-wrapper-inner .descriptionWrap h4 {
    font-family: 'Saira Extra Condensed', sans-serif;
    font-size: 22px;
    color: #444a60;
    font-weight: bold;
    letter-spacing: 1px;
    margin: 0;
    margin-bottom: 20px;
}

.exp-list-wrapper .exp-list-wrapper-inner .descriptionWrap p {
    font-size: 15px;
    color: #444a60;
    padding: 0 20px 10px 0;
    line-height: 1.5;
    font-family: "Poppins", sans-serif;
}

.exp-list-wrapper .exp-list-wrapper-inner ul li {
    list-style: none;
    position: relative;
    font-size: 15px;
    color: #444a60;
    padding: 0 20px 20px;
    line-height: 1.5;
    font-family: "Poppins", sans-serif;
}

.exp-list-wrapper-inner .apply-exp {
    padding: 14px 40px;
    background: #444a60;
    color: #fff;
    display: inline-block;
    margin: 10px 0 0 0;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    line-height: 22px;
    font-size: 18px;
    transition: all 0.5s ease;
    border-radius: 4px;
    border: 1px solid #444a60;
}
.exp-list-wrapper-inner .apply-exp:hover {
    background: transparent;
    color: #444a60;
}
.exp-list-wrapper .exp-list-wrapper-inner ul li:before {
    position: absolute;
    content: '';
    background-image: url(../images/careers/career-tick.svg);
    left: -2px;
    top: 4px;
    background-size: 16px;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
}
.listing-experience-wrapper:last-child .exp-list-wrapper {
    margin: 0;
}
.search_job_withUs {
    background: #f7f7f7;
}
.career_tabs ul {
    padding: 0;
    list-style: none;
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    flex-wrap: wrap;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}
.career_tabs ul li {
    padding: 14px 20px;
    box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.09);
    border-radius: 4px;
    width: 100%;
    max-width: 250px;
    margin: 5px;
    position: relative;
    cursor: pointer;
}
.career_tabs ul li ,
.career_tabs ul li p{
    -webkit-transition: 0.45s ease-in-out 0s all;
    -ms-transition: 0.45s ease-in-out 0s all;
    transition: 0.45s ease-in-out 0s all;
}
.career_tabs ul li:before{
    position: absolute;
    content: '';
    width: 4px;
    height: 25px;
    border-radius: 0 3px 3px 0;
    background-color: #ed402d;
    top:50%;
    left:0;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);

}

.career_tabs ul li p {
    margin: 0 !important;
    font-weight:500;
}
.career_tabs ul li.active ,
.career_tabs ul li:hover {
    background: #fff !important;
    box-shadow: 0px 0px 26px 4px rgba(0,0,0,0.11);
}
.career_tabs ul li.active p,
.career_tabs ul li:hover p{
    color: #ed402c !important;
}




@media only screen and (max-width:1279px)
{
    .main_career .two_coloumn_Below_Banner.layout_2 .right_info {
        height: 500px !important;
        text-align: center;
    }
}


@media only screen and (max-width:767px)
{
    .listing-experience-wrapper:last-child .exp-list-wrapper {
        margin: 0;
    }
    .main_career .two_coloumn_Below_Banner.layout_2 .left_info ul li,
    .main_career .two_coloumn_Below_Banner.layout_2 .left_info p,
    .main_career .career-advantage p,
    .exp-list-wrapper .exp-list-wrapper-inner ul li {
        font-size: 14px;
        padding-bottom: 15px;
    }
    .main_career .two_coloumn_Below_Banner.layout_2 .left_info ul {
        padding: 10px;
    }
    .main_career .two_coloumn_Below_Banner.layout_2.two_coloumn_Below_Banner.layout_2 .right_info h4, .listing-experience-wrapper .experience-right h4 {
        font-size: 22px;
    }
    .main_career .two_coloumn_Below_Banner.layout_2 .right_info {
        height: 340px !important;
    }
    .main_career .career-advantage h2, .jobs-with-us h2 {
        font-size: 40px;
        line-height: 1.2;
        padding-bottom: 25px;
    }
    .career-advantage {
        padding: 40px 20px;
    }
    .main_career .btnWrap.email a.view_work {
        margin: 0;
    }
    .main_career .btnWrap.email {
        margin: 20px 0 0 0;
    }
    .listing-experience-wrapper .experience-left h5 {
        font-size: 25px;
        padding-bottom: 5px;
    }
    .exp-list-wrapper-inner {
        padding: 30px 0 0  20px;
    }
    .career_tabs ul li{
        max-width:240px;
    }
}

@media only screen and (max-width:599px){
    .career_tabs ul {
        padding: 20px 10px;
        flex-wrap: nowrap;
        overflow-y: auto;
        max-width: 100%;
        margin: 0;
    }
    .career_tabs ul li {
        max-width: 240px;
        min-width: 240px;
    }

}