.main_hireMagento_dev .explore_projects .text_wrap {
    max-width: 100%;
}
.main_hireMagento_dev .explore_projects .text_wrap aside {
    margin-bottom: 50px;
}
.explore_projects .boxes_wrapper .list_key_boxes .icon_wrapper img {
    max-width: 40px;
}
.explore_projects .boxes_wrapper .boxes_key {
    padding: 0 20px 20px;
}
.explore_projects .boxes_wrapper .list_key_boxes .head_title {
    font-family: 'Poppins', sans-serif !important;
    font-size: 18px !important;
    line-height: 24px !important;
    letter-spacing: 2px !important;
    text-transform: uppercase;
    font-weight: 500 !important;
    margin-bottom: 10px;
}
.explore_projects .boxes_wrapper .list_key_boxes .head_paragraph {
    font-size: 13px !important;
    line-height: 22px !important;
    margin-bottom: 0 !important;
}
.explore_projects .boxes_wrapper .list_key_boxes {
    text-align: center;
}
.explore_projects .boxes_wrapper .list_key_boxes .icon_wrapper {
    background-color: #fff;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: inline-flex;
    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -o-inline-flex;
    display: -ms-inline-flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
}


@media (min-width:1280px) {
    .explore_projects .boxes_wrapper .boxes_key:nth-child(1) {
        padding-left: 0;
    }
    .explore_projects .boxes_wrapper .boxes_key:nth-child(4) {
        padding-right: 0;
    }
}
@media (min-width:600px) and (max-width:1279.98px) {
    .explore_projects .boxes_wrapper .boxes_key:nth-child(1) {
        padding-left: 0;
    }
    .explore_projects .boxes_wrapper .boxes_key:nth-child(2) {
        padding-right: 0;
    }
}
@media (max-width:767.98px) {
    .explore_projects .boxes_wrapper .list_key_boxes .icon_wrapper {
        width: 70px;
        height: 70px;
    }
    .explore_projects .boxes_wrapper .list_key_boxes .icon_wrapper img {
        max-width: 30px;
    }
}
@media (max-width:579.98px) {
    .explore_projects .boxes_wrapper .boxes_key {
        padding-left: 0;
        padding-right: 0;
    }
}