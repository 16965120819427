.main_hireEcommerce .two_coloumn_Below_Banner .container_custom
{
    align-items: center;
}

.main_hireEcommerce.shopify .FourColumnLayout3 {
    margin-bottom: 270px;
}



@media only screen and (max-width:767px)
{
    .main_hireEcommerce.shopify .FourColumnLayout3 {
        margin-bottom: 100px;
    }

}