.contact-bg {
    background-image: url(../images/contact-bg.png);
    background-repeat: no-repeat;
    background-position: right bottom;
}

.contact-info-wrap {
    display: flex;
    padding-bottom: 60px;
}

.contact-info-wrap .contact-left-info, .contact-info-wrap .contact-right-info {
    width: 50%;
    box-sizing: border-box;
}
.w-100 {
    width: 100%;
}
.srv-validation-message {
    color: red;
}
form span.text-danger.captcha {
    margin-top: 2px;
}
.contact-left-info .container_custom {
    margin: 0 !important;
    margin-left: auto !important;
}

.contact-info-wrap .contact-left-info {
    background-color: #ec402c;
}

.contact-info-wrap .contact-right-info {
    background-color: #333333;
}

.contact-info-wrap h3 {
    color: #fff;
    font-size: 24px;
    text-transform: capitalize;
    letter-spacing: 1px;
    font-weight: 600;
}

.contact-info-wrap ul {
    margin: 0;
    padding: 15px 0;
    width: 100%;
}

.contact-info-wrap ul li {
    white-space: normal;
    position: relative;
    color: #fff;
    flex-wrap: wrap;
    padding: 0 0 15px 30px;
    clear: both;
    height: auto;
}

.contact-info-wrap ul li span {
    position: absolute;
    left: 0;
    color: #fff;
    top: 0;
}

.contact-info-wrap ul li h4 {
    color: #fff;
    font-size: 17px;
    width: 100%;
    padding-bottom: 10px;
    font-family: poppins;
    font-weight: 600;
}
.contact-info-wrap ul li p {
    display: inline-block;
    color: #fff;
    font-size: 14px;
    padding: 0 0 0 0;
    line-height: 1.9;
}

.contact-info-wrap ul li:hover, .contact-info-wrap ul li:focus {
    background: transparent;
    cursor: initial;
}

.contact-info-wrap ul li:last-child {
    padding-bottom: 0;
}

.contact-info-wrap .contact-right-info h4 {
    font-size: 17px;
    color: #fff;
    margin: 0;
    padding: 5px 0 10px;
    font-weight: 600;
    font-family: poppins;
}

.contact-info-wrap .contact-right-info ul {
    padding: 10px 0 0;
}

.contact-info-wrap .contact-right-info ul {
    padding: 10px 0 0;
}

.contact-info-wrap .contact-right-info ul li {
    padding: 0 30px 10px;
}

.contact-info-wrap .contact-right-info ul li span {
    font-size: 20px;
    top: 3px;
}
.contact-info-wrap ul li span img {
    max-width: 20px;
}

.contact-pg .inner-wrapper {
    max-width: 900px;
    margin: 0 auto;
    width: 100%;
}

.contact-pg .inner-wrapper .inner-get-free-quote {
    padding-bottom: 40px;
}

.contact-pg .inner-wrapper  img {
    vertical-align: bottom;
}

.contact-map iframe {
    width: 100%;
}
.contact_page_wrapper .contact-map iframe {
    border: 0;
    vertical-align: middle;
}
.contact-info-wrap .container_custom {
    max-width: 655px;
    padding: 50px;
}
.contact-info-wrap .contact-right-info .container_custom {
    margin-right: auto !important;
    margin-left: unset !important;
}


@media only screen and (max-width:767px) {
    .contact-info-wrap .contact-left-info, .contact-info-wrap .contact-right-info {
        width: 100%;
    }
    .contact-info-wrap {
        flex-wrap: wrap;
    }
    .contact-left-info .container_custom {
        max-width: 100%;
    }
    .contact-info-wrap h3 {
        font-size: 20px;
    }
    .contact-info-wrap .container_custom {
        padding: 25px;
        margin: auto !important;
    }
}
