@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.growth_strategy > .container_custom > div {
    background-color: #151c37;
}
.growth_strategy ul.planByStep li.steps .media {
    display: inline-flex;
    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -o-inline-flex;
    display: -ms-inline-flex;
    align-items: center;
}
.growth_strategy ul.planByStep li.steps .media .icon_wrap {
    background-color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    justify-content: center;
    align-items: center;
    animation: shadow-pulse 1s infinite;
}
@keyframes shadow-pulse
{
  0% {
    box-shadow: 0 0 0 0px rgba(158, 158, 158, 0.2);
  }
  100% {
    box-shadow: 0 0 0 8px rgba(158, 158, 158, 0.2);
  }
}
.growth_strategy ul.planByStep li.steps .media .text_wrap {
    padding-left: 20px;
}
.growth_strategy ul.planByStep li.steps .media .text_wrap h4 {
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    line-height: 27px;
}
.growth_strategy ul.planByStep li.steps .media .text_wrap p {
    color: #848a9c;
    font-size: 12px;
    line-height: 20px;
}
.growth_strategy .right_text .big_title {
    font-size: 126px !important;
    line-height: 137px !important;
    color: #fff !important;
    letter-spacing: 0px !important;
    text-align: left !important;
    margin-bottom: 30px;
}
.growth_strategy .right_text .head_paragraph {
    color: #a4a7b2 !important;
    font-size: 18px !important;
    line-height: 34px !important;
    text-align: left !important;
}
.growth_strategy .right_text {
    padding: 90px 175px 90px 10px;
}
.growth_strategy .left_img {
    text-align: right;
    padding-top: 300px;
    padding-bottom: 350px;
}
.growth_strategy .left_img img {
    width: 90%;
}
.growth_strategy .top_part {
    position: relative;
}
.growth_strategy ul.planByStep {
    list-style: none;
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    padding: 0 50px;
    margin: 0 auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0 100%;
    height: 625px;
}
.growth_strategy ul.planByStep li.steps {
    padding-top: 20px;
    margin-bottom: 50px;
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    align-items: center;
}
.growth_strategy ul.planByStep li.step5 {
    justify-content: flex-end;
}
.growth_strategy ul.planByStep li.step4 {
    justify-content: flex-end;
}
.growth_strategy ul.planByStep li.step3 {
    justify-content: center;
}
.growth_strategy ul.planByStep li.step4 .media {
    width: 35%;
}
.growth_strategy ul.planByStep li.step2 {
    width: 35%;
    justify-content: flex-end;
}
.company_philosopgy.sec_padding {
    padding-bottom: 0;
    background-color: #f7f7f7;
}
.company_philosopgy .head_paragraph {
    margin: 15px auto;
}
/* .company_philosopgy .middleImageWrap img {
    max-width: 80%;
     vertical-align: bottom; 
    margin: 50px auto;
    display: block;
} */
.our_products {
    background-color: #f4f5f7;
}
.our_products .head_title, .our_products .head_paragraph {
    text-align: left !important;
}
.our_products .head_title {
    font-size: 50px !important;
    line-height: 67px !important;
    letter-spacing: 0.6px !important;
    color: #444a60 !important;
}
.our_products .head_paragraph {
    color: #444a60 !important;
}
.our_products .slick-slider .slick-slide > div > div img {
    margin: 0 auto;
    max-width: 150px;
    max-height: 50px;
}
.our_products .slick-slider .slick-slide > div > div img:focus {
    outline: none;
}
.our_products .slick-slider button.slick-arrow {
    display: none !important;
}
.our_products .slick-slider .slick-dots li button:before {
    display: none;
}
.our_products .slick-slider .slick-dots li.slick-active button {
    background-color: #ed402c;
    border-color: #ed402c;
}
.our_products .slick-slider .slick-dots li button {
    border: 1px solid #a3a3a3;
    border-radius: 50%;
    padding: 0;
    width: 10px;
    height: 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    background: #fff;
}
.our_products .slick-slider {
    padding-top: 50px;
}
.our_products .slick-slider {
    padding-top: 50px;
    padding-bottom: 50px;
}
.our_products.sec_padding {
    padding: 80px 0;
}
.slick-dots li {
    width: 10px;
    height: 10px;
}
/* Company Philosophy Section Style start*/
.listStyleWrapMain,
.listing_Style_inner{
    list-style-type: none;
    padding-left: 0;
}
.topBarSideList{
    align-items: flex-end;
    justify-content: space-between;
}
.mainSpaceAdd{
    padding-top: 10px;
    padding-bottom: 60px;
}
.topBarSideList li,
.bottomBarSideList li{
    position: relative;
}
.bottomBarSideList{
    justify-content: space-between;
    align-items: flex-start;
    max-width: 840px;
    margin: 0 auto;
}
.listStyleWrapMain  .mainCardWrapper {
    display: flex;
    display: -ms-flexbox;
    align-items: flex-start;
    border-radius: 4px;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    -webkit-transition: 0.45s ease-in-out 0s all;
    -ms-transition: 0.45s ease-in-out 0s all;
    transition: 0.45s ease-in-out 0s all;
}
.mainCardWrapper .listing_Style_inner  > li{
    font-family: 'Poppins', sans-serif !important;
    line-height: 1.4;
    color: #454545;
    font-size: 15px;
}
.icon_list_style_card{
    min-width: 90px;
}
.content_list_style_card {
    width: calc(100% - 90px);
}
.yellowContent .company_title_main{
    color: #ffa301
}
.blueContent .company_title_main{
    color: #690093;
}
.redContent .company_title_main{
    color: #da251c;
}
.greenContent .company_title_main{
    color: #489f00;
}
.blackContent .company_title_main{
    color: #292929;
}
.icon_list_style_card img {
    max-width: 58px;
}
.listStyleWrapMain .company_title_main {
    font-family: 'Saira Extra Condensed', sans-serif;
    font-size: 42px;
    font-weight: bold;
    text-transform: capitalize;
    margin: 0 0 10px;
}
.topBarSideList > li:last-child{
    max-width: 340px;
}
.company_philosopgy .middleImageWrap img {
    max-width: 90%;
    margin: 0px 20px 0px auto;
    display: block;
}
.listStyleWrapMain > li .mainCardWrapper{
    align-items: flex-start;
    padding: 20px 30px;
}
.bottomBarSideList > li.blackContent {
     margin-right: 75px;
}
.hoverOverlayLayout {
    position: absolute;
    width: 160px;
    height: 160px;
    overflow: hidden;
    border-radius: 50%;
}
.yellowContent .hoverOverlayLayout {
    bottom: -195px;
    left: 126px;
}
.blueContent .hoverOverlayLayout {
    bottom: -194px;
    left: 114px;
}
.redContent .hoverOverlayLayout {
    bottom: -196px;
    right: 143px;
}
.greenContent .hoverOverlayLayout {
    top: -184px;
    left: 116px;
}
.blackContent .hoverOverlayLayout {
    top: -184px;
    right: 62px;
}
.yellowContent .hoverOverlayLayout:hover + .mainCardWrapper,
.blueContent .hoverOverlayLayout:hover + .mainCardWrapper,
.redContent .hoverOverlayLayout:hover + .mainCardWrapper,
.yellowContent .mainCardWrapper:hover,
.blueContent .mainCardWrapper:hover,
.redContent .mainCardWrapper:hover{
    -webkit-transform: translateY(-20px);
        -ms-transform: translateY(-20px);
        transform: translateY(-20px);
}
.blackContent .hoverOverlayLayout:hover + .mainCardWrapper,
.greenContent .hoverOverlayLayout:hover + .mainCardWrapper,
.blackContent .mainCardWrapper:hover,
.greenContent .mainCardWrapper:hover{
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
}
.yellowContent .hoverOverlayLayout:hover + .mainCardWrapper ,
.yellowContent .mainCardWrapper:hover {
    box-shadow: 0 0 8px rgba(255, 163, 1, 0.5);
}
.blueContent .hoverOverlayLayout:hover + .mainCardWrapper,
.blueContent .mainCardWrapper:hover{
    box-shadow: 0 0 8px rgba(105, 0, 147, 0.5);
}
.redContent .hoverOverlayLayout:hover + .mainCardWrapper,
.redContent .mainCardWrapper:hover{
    box-shadow: 0 0 8px rgba(218, 37, 28, 0.5);
}
.greenContent .hoverOverlayLayout:hover + .mainCardWrapper,
.greenContent  .mainCardWrapper:hover{
    box-shadow: 0 0 8px rgba(71, 158, 0, 0.5);
}
.blackContent .hoverOverlayLayout:hover + .mainCardWrapper,
.blackContent .mainCardWrapper:hover{
    box-shadow: 0 0 8px rgba(41, 41, 41, 0.3);
}
.mobileVeiwHideSec{
    display: block;
}
.desktopVeiwHideSec{
    display: none;
}


@media (max-width:1199px){
    .icon_list_style_card img {
        max-width: 42px;
    }
    .icon_list_style_card {
        min-width: 65px;
    }
    .listStyleWrapMain .company_title_main {
        font-size: 36px;
    }
    .content_list_style_card {
        width: calc(100% - 65px);
    }
    .mainCardWrapper .listing_Style_inner  > li{
        font-size: 15px;
    }
    .topBarSideList > li:last-child {
        max-width: 310px;
    }
    .bottomBarSideList {
        max-width: 740px;
    }
    .bottomBarSideList > li.blackContent {
        margin-right: 25px;
    }
    .greenContent .hoverOverlayLayout {
        top: -174px;
        left: 86px;
    }
    .blackContent .hoverOverlayLayout {
        top: -174px;
        right: 81px;
    }
    .yellowContent .hoverOverlayLayout {
        bottom: -185px;
        left: 106px;
    }
    .blueContent .hoverOverlayLayout {
        bottom: -184px;
        left: 100px;
    }
    .redContent .hoverOverlayLayout {
        bottom: -184px;
        right: 125px;
    }
}
@media (max-width:1024px){
    .yellowContent .hoverOverlayLayout {
        bottom: -151px;
        left: 94px;
    }
    .hoverOverlayLayout {
        width: 120px;
        height: 120px;
    }
    .blueContent .hoverOverlayLayout {
        bottom: -151px;
        left: 111px;
    }
    .redContent .hoverOverlayLayout {
        bottom: -153px;
        right: 118px;
    }
    .greenContent .hoverOverlayLayout {
        top: -141px;
        left: 130px;
    }
    .blackContent .hoverOverlayLayout {
        top: -140px;
        right: 132px;
    }
    .listStyleWrapMain > li .mainCardWrapper {
        padding: 15px 19px;
    }
}

@media (min-width:1024px) and (max-width:1150px) {
    .growth_strategy ul.planByStep {
        height: 500px;
    }
    .growth_strategy .left_img {
        padding-bottom: 275px;
    }
    .growth_strategy ul.planByStep li.steps {
        margin-bottom: 24px;
    }
    .growth_strategy ul.planByStep li.step2 {
        width: 44%;
    }
    .growth_strategy ul.planByStep li.step4 .media {
        width: 44%;
    }
    .growth_strategy ul.planByStep li.step3 .media {
        padding-left: 80px;
    }
}
@media (max-width:959px) {
    .growth_strategy .left_img {
        display: none;
    }
    .growth_strategy .right_text {
        padding: 75px;
    }
    .growth_strategy ul.planByStep {
        position: static;
        width: 100%;
        height: 480px;
    }
    .growth_strategy ul.planByStep li.steps {
        margin-bottom: 12px;
    }
    .growth_strategy ul.planByStep li.step2 {
        width: 45%;
    }
    .growth_strategy ul.planByStep li.step4 .media {
        width: 45%;
    }
    .mobileVeiwHideSec{
        display: none;
    }
    .desktopVeiwHideSec{
        display: block;
    }
    .mobileVeiwListWrap{
        flex-wrap: wrap;
    }
    .mobileVeiwListWrap  .contentStyleWrap{
        /* max-width: 50%; */
        box-sizing: border-box;
        width: 100%;
        position: relative;
    }
    .mobileVeiwListWrap  .slick-slide{
        padding-top: 70px;
        position: relative;
    }
    .mobileVeiwListWrap .mainCardWrapper:hover{
        box-shadow: none;
        transform: none;
    }
    .mobileVeiwListWrap  .icon_list_style_card {
        width: 120px;
        height: 120px;
        background-color: #fff;
        border-radius: 50%;
        overflow: hidden;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        margin:0 auto 20px;
    }
    .content_list_style_card {
        width: 100%;
    }
    .icon_list_style_card img {
        max-width: 60px;
        flex-shrink: 0;;
    }
    .mobileVeiwListWrap .yellowContent  .icon_list_style_card {
        box-shadow: 0 0 0 rgba(255,163,1, 0.3);
        animation: pulse_yellow 1.5s ease-out infinite;
    }
    .pulse_yellow:hover {
        animation: none;
    }
      
      @-webkit-keyframes pulse_yellow {
        from {
          -webkit-box-shadow: 0 0 0 0 rgba(255,163,1, 0.3);
        }
        to {
            -webkit-box-shadow: 0 0 0 20px rgba(255,163,1, 0);
        }
      }
      @keyframes pulse_yellow {
        from {
          -moz-box-shadow: 0 0 0 0 rgba(255,163,1, 0.3);
          box-shadow: 0 0 0 0 rgba(255,163,1, 0.4);
        }
        to {
            -moz-box-shadow: 0 0 0 20px rgba(255,163,1, 0);
            box-shadow: 0 0 0 20px rgba(255,163,1, 0);
        }
      }
    .mobileVeiwListWrap .greenContent  .icon_list_style_card {
        box-shadow: 0 0 0 rgba(71, 158, 0, 0.3);
        animation: pulse_green 1.5s ease-out infinite;
    }
    .pulse_green:hover {
        animation: none;
    }
        
    @-webkit-keyframes pulse_green {
    form {
        -webkit-box-shadow: 0 0 0 0 rgba(71, 158, 0, 0.3);
    }
    to {
        -webkit-box-shadow: 0 0 0 20px rgba(71, 158, 0, 0);
    }
    }
    @keyframes pulse_green {
    form {
        -moz-box-shadow: 0 0 0 0 rgba(71, 158, 0, 0.3);
        box-shadow: 0 0 0 0 rgba(71, 158, 0, 0.3);
    }
    to {
        -moz-box-shadow: 0 0 0 20px rgba(71, 158, 0, 0);
        box-shadow: 0 0 0 20px rgba(71, 158, 0, 0);
    }
    }
    .mobileVeiwListWrap .blueContent  .icon_list_style_card {
        box-shadow: 0 0 0 rgba(105, 0, 147, 0.3);
        animation: pulse_blue 1.5s ease-out infinite;
    }
    .pulse_blue:hover {
        animation: none;
    }
    
    @-webkit-keyframes pulse_blue {
        form {
        -webkit-box-shadow: 0 0 0 0 rgba(105, 0, 147, 0.3);
        }
        to {
            -webkit-box-shadow: 0 0 0 20px rgba(105, 0, 147, 0);
        }
    }
    @keyframes pulse_blue {
        form {
        -moz-box-shadow: 0 0 0 0 rgba(83, 34, 102, 0.3);
        box-shadow: 0 0 0 0 rgba(105, 0, 147, 0.3);
        }
        to {
            -moz-box-shadow: 0 0 0 20px rgba(105, 0, 147, 0);
            box-shadow: 0 0 0 20px rgba(105, 0, 147, 0);
        }
    }
    .mobileVeiwListWrap .redContent  .icon_list_style_card {
        box-shadow: 0 0 0 rgba(218, 37, 28, 0.3);
        animation: pulse_red 1.5s ease-out infinite;
    }
    .pulse_red:hover {
        animation: none;
    }
    
    @-webkit-keyframes pulse_red {
        from {
        -webkit-box-shadow: 0 0 0 0 rgba(218, 37, 28, 0.3);
        }
        to {
            -webkit-box-shadow: 0 0 0 20px rgba(218, 37, 28, 0);
        }
    }
    @keyframes pulse_red {
        form {
        -moz-box-shadow: 0 0 0 0 rgba(218, 37, 28, 0.3);
        box-shadow: 0 0 0 0 rgba(218, 37, 28, 0.3);
        }
        to{
            -moz-box-shadow: 0 0 0 20px rgba(218, 37, 28, 0);
            box-shadow: 0 0 0 20px rgba(218, 37, 28, 0);
        }
    }
    .mobileVeiwListWrap .blackContent  .icon_list_style_card {
        box-shadow: 0 0 0 rgba(41, 41, 41, 0.3);
        animation: pulse_black 1.5s ease-out infinite;
    }
    .pulse_black:hover {
        animation: none;
    }
    
    @-webkit-keyframes pulse_black {
        from {
        -webkit-box-shadow: 0 0 0 0 rgba(41, 41, 41, 0.3);
        }
        to{
            -webkit-box-shadow: 0 0 0 20px rgba(41, 41, 41, 0);
        }
    }
    @keyframes pulse_black {
        from{
        -moz-box-shadow: 0 0 0 0 rgba(41, 41, 41, 0.3);
        box-shadow: 0 0 0 0 rgba(41, 41, 41, 0.4);
        }
        to {
            -moz-box-shadow: 0 0 0 20px rgba(41, 41, 41, 0);
            box-shadow: 0 0 0 20px rgba(41, 41, 41, 0);
        }
    }
    .mobileVeiwListWrap .mainCardWrapper {
        display: block;
        text-align: center;
    }
    .contentStyleWrap {
        padding: 0 10px;
    }
}

@media (max-width:991.98px){
    .aboutus_main .twenty-five-team .twenty-five-left-wrap .slick-arrow,
    .get-free-quote-wrapper .twenty-five-team .twenty-five-left-wrap .slick-arrow{
        width: 47px !important;
        height: 30px !important;
    }
    .aboutus_main .twenty-five-inner .twenty-five-left-wrap .slick-slider .slick-prev::before,
    .get-free-quote-wrapper .twenty-five-inner .twenty-five-left-wrap .slick-slider .slick-prev::before{
        background-position: -56px;
    }
}

@media (max-width:767.98px) {
    .growth_strategy .right_text {
        padding: 25px;
    }
    .growth_strategy ul.planByStep li.step4 .media {
        width: auto;
    }
    .growth_strategy ul.planByStep li.step2 {
        width: auto;
    }
    .growth_strategy ul.planByStep li.step3 .media {
        padding-left: 0;
    }
    .growth_strategy ul.planByStep li.steps {
        justify-content: flex-start;
    }
    .growth_strategy ul.planByStep {
        padding: 0 25px;
    }
    .our_products.sec_padding {
        padding: 40px 0;
    }
    .company_philosopgy .head_paragraph {
        max-width: 100%;
    }
    .growth_strategy .right_text .big_title {
        font-size: 70px !important;
        line-height: 76px !important;
    }
    .growth_strategy .right_text .head_paragraph {
        font-size: 14px !important;
        line-height: 24px !important;
    }
    .our_products .head_paragraph {
        margin-bottom: 0 !important;
        text-align: center !important;
    }
    .growth_strategy .container_custom {
        max-width: 100%;
        padding: 0;
    }
    .our_products .head_title {
        font-size: 28px !important;
        line-height: 36px !important;
        text-align: center !important;
    }
    .listStyleWrapMain .company_title_main {
        font-size: 32px;
    }
    .mobileVeiwListWrap .icon_list_style_card {
        width: 100px;
        height: 100px;
    }
    .icon_list_style_card img {
        max-width: 48px;
    }
}

@media (max-width:575.98px) {
    .growth_strategy ul.planByStep li.steps {
        margin-bottom: 9px;
    }
}