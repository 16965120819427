.conjuction_team_works .head_title {
    margin-bottom: 30px;
}

.main_ourTeam .FourColumnLayout1.sec_padding {
    padding-top: 20%;
    margin-top: -20%;
}

.conjuction_team_works .img_wrapper img {
    width: 100%;
}

.main_ourTeam .head_paragraph {
    margin: 0 0 2px 0!important;
}
.main_ourTeam .conjuction_team_works .head_paragraph:last-child {
    padding: 25px 0 30px 0;
}


.main_ourTeam .FourColumnLayout1.sec_padding:after {
    content: "";
    background-image: url('../images/WebsiteDesignDevelopment/webdesign_curve_bottom_bg.png');
    background-repeat: no-repeat;
    background-size: 100%;
    width: 100%;
    height: 300px;
    position: absolute;
    bottom: -290px;
}

.main_ourTeam .FourColumnLayout1.sec_padding {
    position: relative;
    overflow: visible;
    margin-bottom: 300px;
}

.twenty-five-left-wrap {
    width: 55%;
    position: relative;
    padding: 0 15px;
    box-sizing: border-box;
    overflow: hidden;
}

.twenty-five-left-wrap img.play_button {
    position: absolute;
    left: 64%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 100px;
    cursor: pointer;
}

.twenty-five-inner {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}

.twenty-five-right-wrap {
    width: 45%;
    padding: 0 15px;
    box-sizing: border-box;
}

.twenty-five-right-wrap h3 {
    color: #e3463a;
    font-size: 30px;
    font-weight: 600;
    padding-bottom: 10px;
}

.twenty-five-right-wrap h2 {
    color: #444a60;
    font-size: 53px;
    max-width: 430px;
    line-height: 1.1;
    font-family: 'Saira Extra Condensed', sans-serif;
    font-weight: bold;
}

.twenty-five-right-wrap ul {
    margin: 0;
    padding: 10px 0 0 0;
}

.twenty-five-right-wrap ul li {
    position: relative;
    list-style: none;
    padding: 10px 0 10px 15px;
    color: #333333;
    font-size: 15px;
    line-height: 1.7;
}

.twenty-five-right-wrap ul li:before {
    position: absolute;
    width: 7px;
    height: 7px;
    background: #5e5e5e;
    left: 0;
    content: "";
    border-radius: 2px;
    top: 19px;
}

.twenty-five-left-wrap img {
    transition: all 1s;
}
/* 
.twenty-five-inner:hover .twenty-five-left-wrap img:first-child {
    transform: translate3d(-10px, 5px, 5px);
} */

.quality-services {
    background-image: url(../images/AboutusImages/about_team.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 1s;
    position: relative;
}
.quality-services::before {
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.84);
    position: absolute;
    top: 0;
    left: 0;
}

/* .quality-services:hover {
    transform: scale(1.01);
    transition: all 1s;
} */

.quality-services-inner {
    display: flex;
    justify-content: space-between;
}

.quality-services-inner h2 {
    color: #fff;
    font-size: 53px;
    font-family: 'Saira Extra Condensed', sans-serif;
    /* width: 50%; */
    margin: 0;
    font-weight: bold;
}

.quality-services-inner p {
    color: #fff;
    font-size: 16px;
    width: 50%;
    text-align: right;
    margin: 0;
    padding: 5px 0 0 0;
}

.process-inner-wrapper {
    width: 100%;
}

.process-flex-wrapper {
    display: flex;
    display: -ms-flexbox;
    flex-wrap: wrap;
    width: 100%;
}
.process-wrapper .process-inner-wrapper .process-flex-wrapper img {
    width: 60px;
    height: 60px;
}

.process-flex-wrapper .process-first-wrapper {
    width: 33.33%;
    box-sizing: border-box;
    background-color: #f1f3fa;
    padding: 50px 40px;
    transition: all 0.5s;
}

.process-flex-wrapper .process-first-wrapper:nth-child(2) {
    background: #e5e7f0;
}

.process-flex-wrapper .process-first-wrapper h3 {
    color: #000;
    font-size: 25px;
    font-weight: 600;
    padding: 10px 0;
}

.process-flex-wrapper .process-first-wrapper p {
    font-size: 14px;
    color: #333333;
    line-height: 1.6;
}

.process-wrapper.sec_padding {
    padding: 0;
    margin: -200px 0 40px 0;
}

/* .process-flex-wrapper .process-first-wrapper:hover {
    box-shadow: 0 0 2px 2px #f2f2f2;
    transform: scale(1.01);
    transition: all 0.5s;
} */

.help-our-clients-flex-left {
    position: relative;
}

.help-our-clients-flex-left:before {
    position: absolute;
    content: "";
    background-image: url(../images/ourTeam/helpclients-bg.png);
    width: 100%;
    height: 100%;
    background-size: 280px;
    z-index: -1;
    left: -60px;
    top: -40px;
    background-repeat: no-repeat;
}

.help-our-clients-flex-left img {
    padding: 0 0 0 60px;
    max-width: 80%;
}

.help-our-clients .help-our-clients-inner {
    display: flex;
}

.help-our-clients-flex-right {
    width: 50%;
    margin-left: auto;
}

.help-our-clients-flex-right h2 {
    font-size: 53px;
    color: #33384a;
    font-family: 'Saira Extra Condensed', sans-serif !important;
    font-weight: bold !important;
    line-height: 1;
    max-width: 590px;
}

.help-our-clients-flex-right p {
    font-size: 15px;
    line-height: 1.6;
    color: #333333;
    padding: 20px 0;
    max-width: 500px;
}

.help-our-clients {
    margin: 0 0 -150px 0;
}
.why-you-choose-inner h2 {
    text-align: center;
    display: block;
    font-size: 53px;
    color: #33384a;
    font-family: 'Saira Extra Condensed', sans-serif !important;
    font-weight: bold !important;
    line-height: 1.2;
}

.why-you-choose-inner {
    width: 100%;
}

.help-our-clients-flex-right .btnWrap {
    justify-content: flex-start;
}

.help-our-clients-flex-right .btnWrap a {
    margin: 10px 0 0 0;
}
.help-our-clients-flex-right .btnWrap a:hover {
    background: transparent;
    border: 1px solid #e05640;
    color: #e05640;
}

.why-you-choose-above-inner {
    display: flex;
    align-items: center;
}

.why-you-choose-above-inner .why-you-choose-above-left {
    width: 50%;
    position: relative;
    padding-right: 60px;
}

.why-you-choose-above-inner .why-you-choose-above-right {
    width: 50%;
}

.why-you-choose-above-right h5 {
    color: #000000;
    font-size: 17px;
    font-weight: 500;
    line-height: 1.5;
    padding-bottom: 30px;
}

.why-you-choose-above-right p {
    color: #919191;
    font-size: 15px;
    line-height: 1.7;
}

.why-you-choose-above-inner .why-you-choose-above-left:before {
    position: absolute;
    content: "";
    background-image: url(../images/ourTeam/images-dots.jpg);
    width: 340px;
    height: 350px;
    background-size: contain;
    z-index: -1;
    left: -120px;
    background-repeat: no-repeat;
    top: -50px;
}


.why-you-choose-four-grid {
    display: flex;
    flex-wrap: wrap;
    margin: 40px -20px 0;
}

.why-you-choose-four-grid .why-you-choose-first-grid {
    width: 25%;
    padding: 0 20px;
    box-sizing: border-box;
    position: relative;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.why-you-choose-four-grid .why-you-choose-first-grid:hover .why-choose-number {
     -webkit-animation: mymove 0.5s infinite;
     animation: mymove 1s infinite;
}

@-webkit-keyframes mymove {
  0% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(2.25);
            transform: translate(-50%, -50%) scale(2.25);
  }
}

@keyframes mymove {
  0% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(2.25);
            transform: translate(-50%, -50%) scale(2.25);
  }
}
@-webkit-keyframes mymove {
  0% {
    box-shadow: 0px 0px 0px 0px rgba(231, 85, 59,0.35);
  }
  100% {
    box-shadow: 0px 0px 3px 30px rgba(231, 85, 59, 0);
  }
}
@keyframes mymove {
  0% {
    box-shadow: 0px 0px 0px 0px rgba(231, 85, 59, 0.35);
  }
  100% {
    box-shadow: 0px 0px 3px 30px rgba(231, 85, 59, 0);
  }
}
.why-you-choose-first-grid:hover .why-choose-info, 
.why-you-choose-first-grid:hover .why-choose-number {
    box-shadow: 0 0 25px rgba(245, 63, 35, 0.2);
    background: #e7553b;
    color: #fff;
}
.why-you-choose-first-grid:hover .why-choose-info *, 
.why-you-choose-first-grid:hover .why-choose-number * {
    color: #fff;
}
.why-you-choose-first-grid .why-choose-number {
    width: 60px;
    height: 60px;
    background: #f1f3fa;
    border-radius: 50%;
    text-align: center;
    line-height: 4.3;
    z-index: 1;
    position: relative;
    margin: 0 auto;
    border: 1px solid #cbe6fe;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}
.why-you-choose-first-grid .why-choose-number:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transform-origin: center;
    transform-origin: center;
    border-radius: 100%;
    height: 100%;
    width: 100%;
    z-index: -1;
    -webkit-animation: pulse_shadow 2s ease-in-out infinite;
    animation: pulse_shadow 2s ease-in-out infinite;
}
.why-you-choose-four-grid .why-you-choose-first-grid:before {
    content: "";
    position: absolute;
    background: #ef5436;
    height: 2px;
    top: 30px;
    z-index: 1;
    left: 50%;
    right: 50%;
    -webkit-transition: all 2s;
    transition: all 2s;
}
.why-you-choose-four-grid .why-you-choose-first-grid:last-child:before {
    display: none;
}
.why-you-choose-four-grid .why-you-choose-first-grid:hover:before {
    right: -50%;
}

.why-you-choose-first-grid .why-choose-number span {
    color: #444a60;
    font-size: 30px;
    font-family: 'Saira Extra Condensed', sans-serif !important;
    font-weight: bold;
}

.why-you-choose-first-grid .why-choose-info {
    background: #f1f3fa;
    margin: 35px 0;
    border: 1px solid #cbe6fe;
    padding: 40px 20px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.why-you-choose-first-grid .why-choose-info h3 {
    color: #333333;
    text-align: center;
    display: block;
    font-size: 29px;
    font-family: 'Saira Extra Condensed', sans-serif !important;
    font-weight: bold !important;
    line-height: 1.2;
    letter-spacing: 2px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

.why-you-choose-first-grid .why-choose-info p {
    color: #333333;
    text-align: center;
    display: block;
    font-size: 14px;
    line-height: 1.7;
    letter-spacing: 1px;
    padding: 20px 0 0 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.why-you-choose-four-grid:before {
    position: absolute;
}

/*.why-you-choose-four-grid .why-you-choose-first-grid:before {
    position: absolute;
    content: "";
    background: #cbe5fd;
    width: 50%;
    height: 2px;
    top: 30px;
    z-index: -1;
    left: 0;
}*/

.why-you-choose-four-grid .why-you-choose-first-grid:after {
    position: absolute;
    content: "";
    background: #cbe5fd;
    width: 100%;
    height: 2px;
    top: 30px;
    z-index: -1;
    right: 0;
    left: 50%;
}

/*.why-you-choose-four-grid .why-you-choose-first-grid:first-child:before {
    display: none;
}*/
.why-you-choose-four-grid .why-you-choose-first-grid:last-child:after {
    display: none;
}
.main_ourTeam .FourColumnLayout1 .title_row {
    margin-bottom: 35px;
}
.twenty-five-team.sec_padding {
    padding-bottom: 110px;
}
.FourColumnLayout1 .list_key_boxes {
    padding: 15px 15px 20px;
}
.twenty-five-inner .slick-slider .slick-prev::before {
    background-position: -70px;
}
.twenty-five-inner .slick-slider .slick-next::before {
    background-position: -100px;
}
.AlignCenterContent p{
    text-align: center;
}
.contentStyleInnerWrap{
    width:100%;
    margin-top:20px;
}
.contentStyleInnerWrap p {
    font-style: italic;
    color: #e3463a;
    line-height: inherit;
    font-size:18px;
}

@media (max-width:1367px) {
    .main_ourTeam .FourColumnLayout1.sec_padding {
        margin-bottom: 200px;
    }
    .why-you-choose-above-inner .why-you-choose-above-left:before {
        width: 100%;
        height: 100%;    
    }
}
@media (max-width:1279px) {
    .twenty-five-team.sec_padding {
        padding-bottom: 65px;
    }
}
@media (max-width:1199px) {
    .help-our-clients {
        margin: 0 0 -130px 0;
    }
    .quality-services {
        height: 300px; 
        padding-bottom: 0;   
    }
    .quality-services-inner h2 {
        font-size: 36px;
    }
    .process-wrapper.sec_padding {
        margin: -110px 0 40px 0;
    }
    .main_ourTeam .FourColumnLayout1.sec_padding {
        margin-bottom: 150px;
    }
    .why-you-choose-four-grid .why-you-choose-first-grid {
        width: 50%;
    }
    .why-you-choose-four-grid .why-you-choose-first-grid:after {
        width: 2px;
        height: 66px;
        left: 0;
        margin: 0 auto;
    }
    .why-you-choose-four-grid .why-you-choose-first-grid:before {
        height: auto;
        width: 2px;
        right: 100%;
        bottom: 100%;
        -webkit-transition: all 1s;
        transition: all 1s;
    }
    .why-you-choose-four-grid .why-you-choose-first-grid:hover:before {
        bottom: 68%;
    }
    .why-you-choose-four-grid .why-you-choose-first-grid:last-child:before {
        content: "";
        position: absolute;
        background: #ef5436;
        display: inherit;
        height: auto;
        width: 2px;
        top: 30px;
        z-index: 1;
        left: 50%;
        right: 100%;
        -webkit-transition: all 1s;
        transition: all 1s;
    }
    .why-you-choose-four-grid .why-you-choose-first-grid:last-child:hover:before {
        bottom: 68%;
    }
    .why-you-choose-four-grid .why-you-choose-first-grid:last-child:after {
        position: absolute;
        content: "";
        background: #cbe5fd;
        width: 2px;
        height: 66px;
        top: 30px;
        z-index: -1;
        right: 0;
        left: 0;
        margin: 0 auto;
        display: inherit;
    }
    .help-our-clients {
        margin: 0;
    }
    .help-our-clients .help-our-clients-inner {
        align-items: center;
    }
    .twenty-five-left-wrap {
        width: 100%;
        max-width: 740px;
    }
    .twenty-five-right-wrap {
        width: 100%;
        margin-top: 30px;
    }
    .why-you-choose-above-inner {
        flex-wrap: wrap;
    }
    .why-you-choose-above-inner .why-you-choose-above-left {
        width: 100%;
        padding-right: 0;
        padding-bottom: 20px;
    }
    .why-you-choose-above-inner .why-you-choose-above-right {
        width: 100%;
    }
    .main_ourTeam .FourColumnLayout1.sec_padding {
        margin-bottom: 170px;
    }
    .why-you-choose.sec_padding {
        padding-top: 20px;
    }
}

@media (max-width:991px) {
    .help-our-clients .help-our-clients-inner {
        flex-wrap: wrap;
    }
    .help-our-clients-flex-right p,
    .help-our-clients-flex-right h2 {
        max-width: 100%;
    }
    .help-our-clients-flex-right {
        width: 100%;
    }
    .help-our-clients-flex-left {
        max-width: 560px;
        margin: 0 auto 40px;
    }
    .process-flex-wrapper .process-first-wrapper {
        width: 100%;
    }
    .help-our-clients-flex-left:after {
        position: absolute;
        content: "";
        background-image: url(/static/media/helpclients-bg.0cfb570d.png);
        width: 100%;
        height: 100%;
        background-size: 280px;
        z-index: -1;
        right: -70px;
        bottom: -20px;
        background-repeat: no-repeat;
        -webkit-transform: rotateZ(180deg);
        -moz-transform: rotateZ(180deg);
        -ms-transform: rotateZ(180deg);
        -o-transform: rotateZ(180deg);
        transform: rotateZ(180deg);
    }
}
@media (max-width:767px) {
    .help-our-clients-flex-left:before {
        background-size: 200px;
        left: -25px;
        top: -20px;
    }
    .help-our-clients-flex-left:after {
        background-size: 200px;
        right: -25px;
        bottom: -20px;
    }
    .quality-services-inner {
        flex-wrap: wrap;
    }
    .quality-services-inner h2,
    .quality-services-inner p {
        width: 100%;
        text-align: left;
    }
    .main_ourTeam .FourColumnLayout1.sec_padding {
        margin-bottom: 130px;
    }
    .why-you-choose-inner h2,
    .help-our-clients-flex-right h2,
    .twenty-five-right-wrap h2 {
        font-size: 40px;
    }
    .twenty-five-right-wrap h3 {
        font-size: 20px;
    }
    .why-you-choose-first-grid .why-choose-info h3 {
        font-size: 20px;
    }
    .quality-services {
        height: 400px;
    }
    .process-wrapper.sec_padding {
        margin-top: -160px;
    }
    .process-flex-wrapper .process-first-wrapper h3 {
        font-size: 20px;
    }
    .twenty-five-team.sec_padding {
        padding-bottom: 55px;
    }
    .contentStyleInnerWrap p {
        font-size: 16px;
    }
}

@media (max-width:575px) {
    .why-you-choose-four-grid .why-you-choose-first-grid {
        width: 100%;
    }
    .twenty-five-left-wrap img.play_button {
        max-width: 60px;
        left: 50%;
    }
}
@media (max-width:425px) {
    .help-our-clients-flex-left:before {
        background-size: 120px;
        left: -15px;
        top: -15px;
    }
    .help-our-clients-flex-left:after {
        background-size: 120px;
        right: -20px;
        bottom: -14px;
    }
    .help-our-clients-flex-left img {
        padding: 0 0 0 40px;    
    }
    .contentStyleInnerWrap p {
        font-size: 14px;
    }
}