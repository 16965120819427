
/*****Top Banner Style Start****/
.bannerImage_Text {
    padding: 260px;
}
.bannerTextWrap {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 80%;
    padding: 30px;
    z-index: 3;
}
.bannerTextWrap .title {
    text-transform: uppercase;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 60px;
    line-height: 67px;
}
.bannerTextWrap .title span {
    display: block;
}
.bannerTextWrap .title span:first-child {
    font-weight: 300;
}
.bannerTextWrap .title span {
    line-height: 1.2 !important;
}
.bannerTextWrap .title span:nth-child(2) {
    font-weight: 600;
}
.bannerTextWrap p {
    width: 65%;
    margin: 12px auto 30px;
    font-size: 16px;
    line-height: 29px;
    letter-spacing: 0.4px;
}
.btnWrap a.view_work:hover {
    border-color: #e05640;
}
.btnWrap a span.material-icons {
    line-height: 28px !important;
    font-size: 28px;
}
.home_banner {
    overflow: hidden;
}
.home_banner::before {
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.47);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
}
video.videoTag {
    position: absolute;
    z-index: 1;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.bannerTextWrap .btnWrap a.free_quote {
    background-color: #e05640;
}
.bannerTextWrap .btnWrap a.free_quote {
    background-color: #e05640;
}
.bannerTextWrap .btnWrap a.free_quote::before {
    background: #fff;
}
.bannerTextWrap .btnWrap a.free_quote:hover {
    color: #e05640;
}
/*****Top Banner Style End****/


/*****Design_Dev Section Style Start****/
.Design_Dev {
    background-color: #fff;
    background-size: 425px;
    background-position: 20px calc(100% + 80px);
}
.Design_Dev .big_title {
    position: relative;
    width: 100%;
}
.Design_Dev .big_title h2 {
    color: #ed3f2c;
    font-family: 'Saira Extra Condensed', sans-serif;
    font-weight: bold;
    text-transform: uppercase;
}
.Design_Dev .banner_text h2 {
    font-family: 'Saira Extra Condensed', sans-serif;
    font-weight: bold;
    color: #636363;
    font-size: 50px;
    line-height: 56px;
    margin-bottom: 20px;
    letter-spacing: 0.3px;  
}
.Design_Dev .banner_text p {
    font-size: 16px;
    color: #636363;
    line-height: 29px;
    margin-bottom: 30px;
}
.Design_Dev .banner_text .fab_btn{
    background-color: #636363;
    color: #fff;
    animation: shadow-pulse 1s infinite;
}
@keyframes shadow-pulse
{
  0% {
    box-shadow: 0 0 0 0px rgba(187, 120, 120, 1);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(158, 158, 158, 0.2);
  }
}
.Design_Dev .banner_text .fab_btn span.material-icons {
    font-size: 36px;
}
.Design_Dev .img_wrap {
    align-items: center;
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
}
.Design_Dev .img_wrap img {
    max-width: 400px;
    margin: 0 auto;
}
.Design_Dev .big_title .rotate_title {
    position: relative;
    height: 100%;
}
.Design_Dev .big_title .rotate_title .rotate_med_title {
    color: #c6c6c6;
}
.Design_Dev .banner_text a {
    text-decoration: none;
}
ul.dots_circle {
    display: inline-block;
    padding-left: 30px;
}
ul.dots_circle {
    display: none;
}
ul.dots_circle li {
    width: 30px;
    list-style: none;
    display: inline-block;
}
ul.dots_circle li span {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: #636363;
    border-radius: 50%;
}

/*****Design_Dev Section Style End****/



/*****Home Slider One Style Start****/
.serviceListHomeSliderOne .slick-prev {
    left: -300px;
    right: 0;
    margin: auto;
    top: unset;
    z-index: 99;
}
.serviceListHomeSliderOne .slick-next {
    left: 0;
    right: 150px;
    margin: auto;
    top: unset;
    z-index: 99;
    border-left: 2px solid #b2b2b2;
}
.serviceListHomeSliderOne .slick-arrow {
    bottom: 30px;
}
.serviceListHomeSliderOne .slide_item {
    height: 100vh;
    position: relative;
    overflow: hidden;
    display: flex !important;
}
.serviceListHomeSliderOne ul.slick-dots {
    display: none !important;
}
.serviceListHomeSliderOne img.bg_img_home_slide {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    min-width: 1100px;
}
.serviceListHomeSliderOne .slide_item .slide_left_text {
    width: 55%;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
}
.serviceListHomeSliderOne .slide_item .slide_right_text {
    width: 45%;
    position: relative;
    z-index: 2;
}
.serviceListHomeSliderOne img.bg_home_slide_angle_cut {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
}
.serviceListHomeSliderOne .slick-arrow {
    background-color: #00000000 !important;
    color: #fff !important;
    transform: inherit;
    width: 71px;
    height: 42px;
    cursor: pointer;
}
.serviceListHomeSliderOne .slick-arrow:hover {
    transform: inherit;
}
.serviceListHomeSliderOne .slick-arrow::before {
    color: #f87b2d;
    background-color: #00000000 !important;
}
.serviceListHomeSliderOne .slick-next::before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    background-image: url('../images/slick_next_arrow.svg');
    background-repeat: no-repeat;
    background-size: 18px;
    background-position: 20px;
}
.serviceListHomeSliderOne .slick-prev::before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    background-image: url('../images/slick_prev_arrow.svg');
    background-repeat: no-repeat;
    background-size: 18px;
    background-position: 36px;
}
.serviceListHomeSliderOne .slide_item .slide_left_text .content_box {
    position: relative;
    width: 440px;
    margin-left: 100px;
    z-index: 2;
}
.serviceListHomeSliderOne .slide_item .slide_left_text .content_box h3.head_title {
    font-size: 46px !important;
    text-align: left !important;
    margin-bottom: 30px;
    text-transform: uppercase;
}
.serviceListHomeSliderOne .slide_item .slide_left_text .content_box h3.head_title span {
    border-bottom: 3px solid #f87b2d;
    padding-right: 20px;
}
.serviceListHomeSliderOne .slide_item .slide_left_text .content_box h4.head_paragraph {
    font-size: 35px !important;
    line-height: 43px !important;
    text-align: left !important;
    font-weight: 500;
    margin-bottom: 30px !important;
    font-family: 'Poppins', sans-serif !important;
    color: #444a60 !important;
}
.serviceListHomeSliderOne .slide_item .slide_left_text .content_box p.head_paragraph {
    text-align: left !important;
    margin-bottom: 50px !important;
}
.serviceListHomeSliderOne .slide_item .slide_left_text .content_box a.read_more_btn {
    background-color: #444a60;
    color: #fff;
    font-family: 'Poppins', sans-serif !important;
    font-size: 15px;
    padding: 14px 40px;
    border-radius: 4px;
    font-weight: 600;
    text-transform: uppercase;
    display: inline-block;
    cursor: pointer;
}
.serviceListHomeSliderOne .slide_item .slide_left_text .content_box a.read_more_btn:hover {
    background-color: #e05640;
}
.serviceListHomeSliderOne .slide_item .slide_right_text .wrap_coming_slide_content {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    width: 600px;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.serviceListHomeSliderOne .slide_item .slide_right_text .wrap_coming_slide_content aside {
    max-width: 300px;
    margin-right: 100px;
}
.serviceListHomeSliderOne .slide_item .slide_right_text .wrap_coming_slide_content h3.head_title {
    color: #fff !important;
    text-transform: uppercase;
    text-align: left !important;
    margin-bottom: 10px;
    line-height: normal !important;
    font-size: 38px !important;
}
.serviceListHomeSliderOne .slide_item .slide_right_text .wrap_coming_slide_content p.head_paragraph {
    color: #fff !important;
    font-size: 20px !important;
    line-height: normal !important;
    text-align: left !important;
    margin-bottom: 20px !important;
}
.serviceListHomeSliderOne .slide_item .slide_right_text .wrap_coming_slide_content a.hire_us_btn {
    color: #fff;
    display: flex;
    align-items: center;
    font-family: 'Poppins', sans-serif !important;
    font-size: 21px;
    cursor: pointer;
}
.serviceListHomeSliderOne img.bg_img_home_slide.flip_horizontal {
    transform: scaleX(-1);
}
.serviceListHomeSliderOne img.bg_img_home_slide.flip_horizonta-translate-x-100 {
    transform: scaleX(-1) translateX(-100px);
}
.serviceListHomeSliderOne .slide_item .slide_right_text .wrap_coming_slide_content a.hire_us_btn span.material-icons {
    transition: 0.5s;
}
.serviceListHomeSliderOne .slide_item .slide_right_text .wrap_coming_slide_content a.hire_us_btn:hover span.material-icons {
    transform: translateX(10px);
}
/*****Home Slider One Style End****/

/*****Lets_Help Section Style Start****/
.lets_Help .btnWrap {
    width: 100%;
    margin-bottom: 80px;
}
.lets_Help .head_title {
    margin-bottom: 30px;
}
.lets_Help .btnWrap a {
    margin: 0 15px;
}
.lets_Help .btnWrap a {
    margin: 0 15px;
    background-color: #444a60;
    color: #fff;
    font-size: 16px;
    transition: all 0.5s ease; 
}
.lets_Help .btnWrap a:hover {
    background-color: #e05640;
}
.lets_Help .services_icons_wrap {
    position: relative;
    width: 100%;
}
.lets_Help .services_icons_wrap .icons_wrap {
    border-radius: 50%;
    box-shadow: 0px 0px 15px rgba(133, 167, 241, 0.4);
    width: 70px;
    height: 70px;
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.lets_Help .services_icons_wrap .icons_wrap > .avatar {
    width: auto;
    height: auto;
    border-radius: 0;
}
.lets_Help .services_icons_wrap img.big_service_image {
    width: 100%;
    height: auto;
}
.lets_Help.sec_padding {
    padding-bottom: 30px;
}
.lets_Help .services_icons_wrap .icons_wrap {
    position: absolute;
}
.lets_Help .services_icons_wrap .icons_wrap.angular {
    top: 0;
    left: -40px;
}
.lets_Help .services_icons_wrap .icons_wrap.node_js {
    top: -15px;
    left: 125px;
}
.lets_Help .services_icons_wrap .icons_wrap.apple {
    top: 0;
    bottom: 80px;
    left: 50px;
    margin: auto;
}
.lets_Help .services_icons_wrap .icons_wrap.html_5 {
    bottom: 90px;
    left: 20px;
}
.lets_Help .services_icons_wrap .icons_wrap.android {
    bottom: 130px;
    left: 150px;
}
.lets_Help .services_icons_wrap .icons_wrap.bootstrap {
    bottom: 250px;
    left: 250px;
}
.lets_Help .services_icons_wrap .icons_wrap.react {
    top: -15px;
    left: 380px;
}
.lets_Help .services_icons_wrap .icons_wrap.php {
    bottom: 250px;
    left: 150px;
    right: 0;
    margin: auto;
}
.lets_Help .services_icons_wrap .icons_wrap.icon_serv_1 {
    top: -15px;
    right: 240px;
}
.lets_Help .services_icons_wrap .icons_wrap.wordpress {
    top: 45px;
    right: 100px;
}
.lets_Help .services_icons_wrap .icons_wrap.drupal {
    top: 0;
    bottom: 80px;
    right: 170px;
    margin: auto;
}
.lets_Help .services_icons_wrap .icons_wrap.magento {
    top: -15px;
    right: 0;
}
.lets_Help .services_icons_wrap .icons_wrap.icon_serv_2 {
    top: 100px;
    right: -40px;
}
.lets_Help .services_icons_wrap .icons_wrap.icon_serv_3 {
    bottom: 120px;
    right: 0;
}
.lets_Help .services_icons_wrap .icons_wrap:hover {
    -webkit-transform: scale(1.15) !important;
    transform: scale(1.15) !important;
    transition-delay: 0s !important;
}
/*****Lets_Help Section Style End****/


/*****Digital_Service Section Style Start****/
.digital_service {
    background-color: #f1f1f1;
}
.digital_service h2 {
    color: #444a60;
    font-family: 'Saira Extra Condensed', sans-serif;
    font-size: 126px;
    font-weight: bold;
    line-height: 120px;
    margin-bottom: 65px;
    letter-spacing: 0.2px;
}
.digital_service .media {
    position: relative;
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    box-sizing: border-box;
    margin-bottom: 30px;
}
.digital_service .media .icon_wrap {
    width: 80px;
    padding: 5px;
    box-sizing: border-box;
}
.digital_service .media .icon_wrap img {
    max-width: 60px;
    -webkit-filter: invert(40%) grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(400%) contrast(2);
    filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(600%) contrast(0.8);
}
.digital_service .media .content_wrap {
    width: calc(100% - 80px);
    padding: 5px;
    box-sizing: border-box;
}
.digital_service .media .content_wrap h4 {
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    line-height: 29px;
    color: #444a60;
    margin-bottom: 12px;
}
.digital_service .media .content_wrap p {
    color: #858585;
    font-size: 14px;
    line-height: 26px;
}
.digital_service.sec_padding {
    padding: 80px 0 300px;
}
.digital_service img.digital_curve_bottom {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -5px;
    right: 0;
    margin: 0 auto;
}
/*****Digital_Service Section Style End****/

/*****Our_Clients Section Style Start****/
.our_clients .clients_map_wrap {
    text-align: center;
    padding-top: 50px;
}
.our_clients {
    position: relative;
    padding-bottom: 30px;
}
.our_clients .our_clients_right_shape_orange {
    position: absolute;
    right: 0;
    top: calc(0px - 300px);
    text-align: right;
}
.our_clients .clients_map_wrap img {
    max-width: 100%;
}
.our_clients .clients_logo {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.our_clients .slick-slider .slick-track {
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    justify-content: center;
}
.our_clients .clients_logo .brand_wrap {
    text-align: center;
}
.our_clients .clients_logo a {
    display: inline-block;
    margin: 10px;
    padding: 20px 40px;
    border-radius: 100px;
}
.our_clients .clients_logo a:hover {
    box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
}
.our_clients .clients_logo .brand_wrap img {
    margin: 0 auto;
}
.our_clients .clients_logo .brand_wrap:hover {
    transition: all 0.5s;
}
.our_clients.sec_padding {
    overflow: visible;
}
.our_clients ul.slick-dots li button::before {
    font-size: 8px;    
    width: 100%;
    height: 100%;
}
.our_clients ul.slick-dots li.slick-active button::before {
    color: #ed402c;
}
.our_clients ul.slick-dots li {
    height: auto !important;
    width: auto !important;
}
.our_clients ul.slick-dots {
    bottom: 10px;
}
.our_clients .clients_logo .brand_wrap:focus{
    outline: none !important;
}
.slick-list * {
    outline: 0 !important;
}
/*****Our_Clients Section Style End****/


/*****Home Clients_Testimonials Slider Three Style Start****/
.clients_testimonials h6.head_title_small {
    color: #808596;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    font-size: 16px;
    margin-bottom: 15px;
}
.clients_testimonials .head_title {
    text-align: left !important;
    color: #fff !important;
    font-size: 46px !important;
    line-height: 50px !important;
    margin-bottom: 20px;
}
.clients_testimonials p.head_paragraph {
    text-align: left !important;
    color: #e3e3e3 !important;
    font-size: 14px !important;
    line-height: 25px !important;
    margin-bottom: 40px !important;
}
.clients_testimonials .btnWrap {
    justify-content: flex-start;
}
.clients_testimonials .our_clients_left {
    display: flex;
    align-items: center;
}
.clients_testimonials .btnWrap a.read_all_btn {
    background-color: #e05640;
    color: #fff;
    margin-left: 0;
    padding: 15px 60px;
    cursor: pointer;
    border: 1px solid #e05640;
}
.clients_testimonials .btnWrap a.read_all_btn:hover {
    border-color: #fff;
    background-color: #00000000;
}
.clients_testimonials .testimonial_left_side {
    padding: 40px 140px 40px 0;
}
.clients_testimonials .testimonial_slider_vertical button.slick-arrow {
    display: none !important;
}
.clients_testimonials .testimonial_slider_vertical .slide_item_testimonial aside.area_box {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 15px;
    margin: 5px;
    position: relative;
}
.clients_testimonials .testimonial_slider_vertical .slide_item_testimonial aside.area_box img.rightBlockQuote {
    position: absolute;
    right: 15px;
    top: 15px;
}
.clients_testimonials .testimonial_slider_vertical .slide_item_testimonial aside.area_box .media {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.clients_testimonials .testimonial_slider_vertical .slide_item_testimonial aside.area_box .media .name_profession_wrap h1.user_name {
    color: #fff !important;
    text-align: left !important;
    margin: 0 !important;
    font-size: 17px !important;
    font-weight: 500;
    font-family: 'Poppins', sans-serif !important;
    line-height: 22px !important;
}
.clients_testimonials .testimonial_slider_vertical .slide_item_testimonial aside.area_box .media .name_profession_wrap h3.user_profession {
    font-size: 12px !important;
    color: #6e717f;
    font-weight: 500;
    font-family: 'Poppins', sans-serif !important;
}
.clients_testimonials .testimonial_slider_vertical .slide_item_testimonial aside.area_box p.user_comments {
    color: #858892;
    font-size: 14px !important;
    line-height: 27px !important;
    font-style: italic;
    margin-bottom: 20px;
}
.clients_testimonials .testimonial_slider_vertical .slide_item_testimonial aside.area_box p.meta_date {
    font-size: 14px !important;
    color: #a3aebf;
    font-weight: 500;
}
.clients_testimonials .testimonial_slider_vertical  .slide_item_testimonial:focus {
    outline: none;
}
.clients_testimonials .testimonial_slider_vertical .slick-list .slick-track > .slick-slide {
    border: 0;
}
.clients_testimonials .testimonial_slider_vertical .slide_item_testimonial aside.area_box .media .img_wrap {
    margin-right: 10px;
    width: 50px;
}
.clients_testimonials .testimonial_slider_vertical .slide_item_testimonial aside.area_box .media .img_wrap .avatar_img {
    width: 100%;
    height: auto;
}
/*****Home Clients_Testimonials Slider Three Style End****/


/*****Discuss_Project Section Style Start****/
.discuss_project .media {
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
}
.discuss_project .media .count_text_box .small_title {
    color: #ed402c;
    font-size: 17px;
    line-height: 32px;
    font-weight: 600;
}
.discuss_project .btnWrap {
    width: 100%;
}
.discuss_project .btnWrap a.get_quote {
    background-color: #444a60;
    color: #fff;
}
.discuss_project .btnWrap a.get_quote:hover {
    background-color: #e05640;
}
.discuss_project .btnWrap a.our_work {
    background-color: #e05640;
    color: #fff;
}
.discuss_project .btnWrap a.our_work:hover {
    background-color: #444a60;
}
.discuss_project .btnWrap a {
    margin: 0 15px;
    font-size: 16px;
}
.discuss_project .project_image_center .middle_img {
    text-align: center;
}
.discuss_project .three_column_layout {
    margin: 45px 0 75px;
}
.discuss_project .project_from, .discuss_project .project_to {
    align-items: center;
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
}
.discuss_project .project_from .icon_box {
    max-width: 130px;
}
.discuss_project .project_from .count_text_box {
    max-width: calc(100% - 130px);
    padding-left: 20px;
}
.discuss_project .project_to .count_text_box {
    max-width: calc(100% - 130px);
    padding-right: 20px;
}
.discuss_project .project_to .icon_box {
    max-width: 130px;
}
.discuss_project .icon_wrap img {
    width: 100%;
}
.discuss_project .media .count_text_box h3 {
    font-size: 42px;
    line-height: 32px;
    color: #33384a;
    font-family: 'Poppins', sans-serif;
    font-weight: 800;
    margin-bottom: 16px;
    margin-top: 8px;
}
.discuss_project .media .count_text_box p {
    font-size: 13px;
    line-height: 25px;
    color: #33384a;
}
.discuss_project .project_image_center .middle_img .img_wrap {
    position: relative;
}
.discuss_project .project_image_center .middle_img .img_wrap img.center_img {
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 280px;
}
.discuss_project .project_image_center .middle_img.aos-animate .img_wrap img.center_img {
    -webkit-animation-name: spin;
   -webkit-animation-duration: 40000ms;
   -webkit-animation-timing-function: linear;
   -moz-animation-name: spin;
   -moz-animation-duration: 40000ms;
   -moz-animation-timing-function: linear;
   -ms-animation-name: spin;
   -ms-animation-duration: 40000ms;
   -ms-animation-timing-function: linear;
   
   animation-name: spin;
   animation-duration: 40000ms;
   animation-timing-function: linear;
}
@-ms-keyframes spin {
   from { -ms-transform: rotate(0deg); }
   to { -ms-transform: rotate(360deg); }
}
@-moz-keyframes spin {
   from { -moz-transform: rotate(0deg); }
   to { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
   from { -webkit-transform: rotate(0deg); }
   to { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
   from {
       transform:rotate(0deg);
   }
   to {
       transform:rotate(360deg);
   }
}
.discuss_project .project_image_center .middle_img .img_wrap img.arrow {
    position: absolute;
}
.discuss_project .project_image_center .middle_img .img_wrap img.arrow.left {
    bottom: 35px;
    left: calc(0px - 85px);
}
.discuss_project .project_image_center .middle_img .img_wrap img.arrow.right {
    top: 20px;
    right: calc(0px - 5px);
}
/*****Discuss_Project Section Style End****/


/**Finest Project Slider Start**/
.finest-project {
    margin-bottom: 0;
    position: relative;
}
.homePortfolioSlider {
    background: #fafafa;
}
.finest-project a.all-category {
    cursor: pointer;
    text-align: center;
    display: table;
    margin: 0 auto;
    padding: 30px 0px 3px;
    color: #33384a;
    margin-bottom: 40px;
    border-bottom: 1px solid #33384a;
}
.finest-project a.all-category:hover {
    color: #ec6841;
    border-color: #ec6841;
}
.finest-project ul.slick-dots li button:before {
    font-size: 8px;
    opacity: 1;
    color: #ddd;
}
.finest-project ul.slick-dots li.slick-active button::before {
    color: #000;
}

.finest-project ul.slick-dots li {
    margin: 0 12px;
}
.finest-project .slick-slide img
{
    max-width: 100%;
}
.finest-project .slick-slide .finest-image-wrapper img {
    margin: 0 auto;
    width: 100%;
    height: auto;
    transition: all 0.8s ease-in-out;
}
.finest-project .slider-position-wrapper .desktop_big_wrapper .slick-slide .finest-image-wrapper img {
    transform: scale(0.92);
    transition: all 0.8s ease-in-out;
    border-radius: 5px;
}
.mobile-small-outer-wrapper .mobile-small-wrapper .finest-image-wrapper img{
    border-radius: 20px;
}
.finest-project .slider-position-wrapper .desktop_big_wrapper .slick-slide.slick-center .finest-image-wrapper img {
     transform: scale(1);
}
.finest-project .mobile-small-outer-wrapper:before {
    position: absolute;
    content: "";
    background-image: url(../images/phone.png);
    width: 400px;
    height: 450px;
    z-index: 99;
    background-repeat: no-repeat;
    background-position: center;
    pointer-events: none;
    overflow: hidden;
    top: -5px;
}
.finest-project .mobile-small-wrapper {
    max-width: 208px;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 40px;
    background-color: #fff;
}
.finest-project .mobile-small-outer-wrapper {
    max-width: 400px;
    margin: 0 auto;
    position: absolute;
    bottom: 0px;
    right: 15%;
    width: 100%;
    padding: 0;
}
.finest-project .slick-slide {
    outline: 0;
}
.finest-project .mobile-large-outer-wrapper .slick-slide
{
    margin: 0px;
    
}
.finest-project .slick-slider {
    outline: 0;
    position: static;
}
.finest-project .slider-position-wrapper .desktop_big_wrapper .slick-slider {
    padding-bottom: 60px;
}
.finest-project .slider-position-wrapper {
    position: static;
}
.finest-project .slider-position-wrapper .desktop_big_wrapper {
    max-width: 1100px;
    margin: 0 auto;
}
.finest-project .slider-position-wrapper .desktop_big_wrapper .slick-list {
    overflow: visible;
}
/* .finest-project .slider-position-wrapper .desktop_big_wrapper .slick-slide {
    opacity: 0.5;
    transition: all 0.8s ease-in-out;
}
.finest-project .slider-position-wrapper .desktop_big_wrapper .slick-slide.slick-center {
    opacity: 1;
} */
.finest-project .slider-position-wrapper .desktop_big_wrapper .slick-slide:not(.slick-center) .finest-image-wrapper {
    opacity: 0.2;
}
.finest-project .slider-position-wrapper .desktop_big_wrapper ul.slick-dots {
    bottom: -100px;
}
.finest-project .slider-position-wrapper .desktop_big_wrapper .slick-slide .finest-image-wrapper {
    padding: 20px 40px;
    box-sizing: border-box;
    transition: all 0.8s ease-in-out;
}
.finest-project .slider-position-wrapper ul.slick-dots li.slick-active button::before {
    color: #e05640;
}
/**Finest Project Slider End**/

/**Testimonial Section Layout 2**/
.testimonials_2_layout {
    background-color: #f7f7f7;
}
.testimonials_2_layout .small_head_title {
    color: #a2a2a2;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    font-family: poppins;
}
.testimonials_2_layout .testimonials_wrapping {
    padding-top: 50px;
}
.testimonials_2_layout .testimonials_wrapping aside{
    box-sizing: border-box;
    margin: 0 20px;
}
.testimonials_2_layout .testimonial_list aside {
    background-color: #fff;
    padding: 0 40px 20px;
    margin-top: 50px;
    border-radius: 40px 0px 40px 0px;
    text-align: center;
}
.testimonials_2_layout .testimonial_list aside .user_img {
    position: relative;
    width: 100px;
    height: 100px;    
    margin: -50px auto 25px;
    display: inline-block;
}
.testimonials_2_layout .testimonial_list aside .user_img .img_wrap {
    overflow: hidden;
    background-color: #ddd;
    border-radius: 50%;
}
.testimonials_2_layout .testimonial_list aside .user_img .img_wrap img {
    height: auto;
}
.testimonials_2_layout .testimonial_list aside .user_img::after {
    content: "";
    background-image: url(../images/HomePage/quote_below_icon.png);
    width: 26px;
    height: 26px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: absolute;
    top: calc(100% - 9px);
    left: 0;
    right: 0;
    margin: 0 auto;
}
.testimonials_2_layout .testimonial_list aside h3 {
    font-size: 19px;
    color: #414b6f;
    font-weight: 600;
    line-height: 1.4;
    font-family: poppins;
}
.testimonials_2_layout .testimonial_list aside h5 {
    font-size: 12px;
    color: #6e717f;
    font-weight: 600;
    line-height: 1.5;
    font-family: poppins;
}
.testimonials_2_layout .testimonial_list aside h5.company_name {
    color: #4a4a4a;
    font-size: 14px;
}
.testimonials_2_layout .testimonial_list aside p {
    font-style: italic;
    line-height: 1.8;
    padding-top: 10px;
    color: #858892;
    font-size: 15px;
}

/**Testimonial Section Layout 2**/

/*new-build-design*/
.new-build-wrapper {
    width: 100%;
}
.new-build-wrapper .new-build-three-flex-wrapper {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 70px 0 0;
}

.new-build-three-flex-wrapper .new-build-first-wrapper {
    width: 33.33%;
}

.new-build-three-flex-wrapper .new-build-first-wrapper ul {
    padding: 0;
    margin: 0;
}

.new-build-three-flex-wrapper .new-build-first-wrapper > ul > li {
    display: flex;
    padding-bottom: 70px;
    position: relative;
}

.new-build-three-flex-wrapper .new-build-first-wrapper ul li .left {
    width: 110px;
    background: #fff;
    border-radius: 50%;
    height: 110px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 2px solid #eeeeee;}

.new-build-three-flex-wrapper .new-build-first-wrapper ul li .left img {
    max-width: 70px;
    width: 100%;
}

.new-build-three-flex-wrapper .new-build-first-wrapper ul li .right {
    width: auto;
    padding-left: 30px;
    box-sizing: border-box;
    flex: 1;
}

.new-build-three-flex-wrapper .new-build-first-wrapper ul li .right h4 {
    font-size: 20px;
    padding-bottom: 15px;
    margin: 0;
    text-transform: capitalize;
}

.new-build-three-flex-wrapper .new-build-first-wrapper ul li .right h4 a {
    color: #f24934;
    font-weight: 600;

}

.new-build-three-flex-wrapper .new-build-first-wrapper ul li .right h4 a:hover {
    color: #e05640;
}

.new-build-three-flex-wrapper .new-build-first-wrapper ul li .right p {
    margin: 0;
    font-size: 15px;
    color: #33384a;
    line-height: 1.7;
}

/* .new-build-three-flex-wrapper .new-build-first-wrapper ul li .left:before {
    position: absolute;
    content: "";
    width: 2px;
    height: calc(100% + 0px);
    background: #dbdbdb;
    bottom: 100%;
    z-index: -1;
} */

.new-build-three-flex-wrapper .new-build-first-wrapper ul li:first-child .left:before {
    display: none;
}

.new-build-three-flex-wrapper .new-build-first-wrapper.middle {
    display: flex;
    align-items: center;
    justify-content: center;
}

.new-build-three-flex-wrapper .new-build-first-wrapper.middle img {
    max-width: 100%;
    margin: 0 auto;
    display: block;
    width: 300px;
}
.new-build-three-flex-wrapper .new-build-first-wrapper ul li:last-child {
    padding-bottom: 0;
}
.sp-image.left {
    opacity: 0;
    transition: all 1s;
}
.sp-image.left.active {
    opacity: 1;
}
.sp-image.right {
    opacity: 0;
    transition: all 1s;
}

.sp-image.right.active {
    opacity: 1;
}
/* .new-build-three-flex-wrapper .new-build-first-wrapper.middle .sp-image.left {
    transform: translate(0, 0) scale(0.1);
    transition: all 5s;
    opacity: 0;
} */
/* .new-build-three-flex-wrapper .new-build-first-wrapper.middle .sp-image.left:nth-child(2) {
    transform: translate(-40%,0%) scale(0.2);
}
.new-build-three-flex-wrapper .new-build-first-wrapper.middle .sp-image.left:nth-child(3) {
    transform: translate(-40%, 50%) scale(0.2);
}
.new-build-three-flex-wrapper .new-build-first-wrapper.middle .sp-image.right {
    transform: translate(42%, -50%) scale(0.2);
}
.new-build-three-flex-wrapper .new-build-first-wrapper.middle .sp-image.right:nth-child(5) {
    transform: translate(40%,0%) scale(0.2);
}
.new-build-three-flex-wrapper .new-build-first-wrapper.middle .sp-image.right:nth-child(6) {
    transform: translate(50%, 50%) scale(0.2);
} */
/* .new-build-three-flex-wrapper .new-build-first-wrapper.middle .sp-image.active{
    -webkit-transform: scale(1) translate(0) !important;
    transform: translate(0,0) scale(1) !important;
    opacity: 1;
    transition: all 2s;
} */
/* .new-build-three-flex-wrapper .new-build-first-wrapper.middle .active.UI_UX_ICON,
.new-build-three-flex-wrapper .new-build-first-wrapper.middle .active.WEBSITE_ICON,
.new-build-three-flex-wrapper .new-build-first-wrapper.middle .active.FRONTEND_ICON{
    -webkit-transform: scale(1) translate(0);
    transform: translate(0,0) scale(1);
    opacity: 1;
    transition: all 3s;
} */

.new-build-three-flex-wrapper .new-build-first-wrapper ul li:hover .left,
.new-build-three-flex-wrapper .new-build-first-wrapper ul li.liactive .left {
    border: 2px solid #f24934;
    transition: all 0.5s;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

/* .new-build-three-flex-wrapper .new-build-first-wrapper ul li .left:after,
.new-build-three-flex-wrapper .new-build-first-wrapper ul li.liactive .left:after {
    position: absolute;
    content: "";
    width: 2px;
    height: 0;
    background: #f24934;
    transition: all 0.5s;
    bottom: 0;
    
} */

.new-build-three-flex-wrapper .new-build-first-wrapper li:not(:last-child)::after {
    content: "";
    width: 2px;
    height: 100%;
    background: #eee;
    position: absolute;
    right: 57px;
    z-index: -1;
}
.new-build-three-flex-wrapper .new-build-first-wrapper.right li:not(:last-child)::after {
    right: inherit;
    left: 57px;
}
.new-build-three-flex-wrapper .new-build-first-wrapper li:not(:last-child)::before {
    content: "";
    width: 2px;
    height: 0px;
    background: #f24934;
    position: absolute;
    right: 57px;
    z-index: 0;
    transition: all 0.5s ease-in-out;
}
.new-build-three-flex-wrapper .new-build-first-wrapper.right li:not(:last-child)::before {
    right: inherit;
    left: 57px;
}
.new-build-three-flex-wrapper .new-build-first-wrapper li.active:not(:last-child)::before {
    height: 100%;
}
.new-build-three-flex-wrapper .new-build-first-wrapper li.active > a {
    border-color: #f24934;
}

.new-build-three-flex-wrapper .new-build-first-wrapper ul li:hover .left:after,
.new-build-three-flex-wrapper .new-build-first-wrapper ul li.liactive .left:after {
    transition: all 0.5s;
    bottom: -100%;
    height: 100%;
}
.new-build-three-flex-wrapper .new-build-first-wrapper ul li:last-child:hover .left:after,
.new-build-three-flex-wrapper .new-build-first-wrapper ul li:last-child.liactive .left:after {
   display: none;
}
.new-build-three-flex-wrapper .new-build-first-wrapper ul ul.listings {
    list-style: none;
}
.new-build-three-flex-wrapper .new-build-first-wrapper ul ul.listings li {
    font-size: 14px;
    color: #858585;
    font-family: poppins;
    line-height: 1.6;
}
.new-build-three-flex-wrapper .new-build-first-wrapper ul ul.listings li img.tick_done {
    vertical-align: middle;
    width: 14px;
    filter: grayscale(1);
}
.new-build-three-flex-wrapper .new-build-first-wrapper > ul > li .btnWrap {
    justify-content: flex-start;
}
.new-build-three-flex-wrapper .new-build-first-wrapper > ul > li .btnWrap .readMore {
    padding: 0;
    font-size: 14px;
    color: #e05640;
    background-color: transparent;
    margin: 15px 0 0;
    border-radius: 2px;
    text-transform: capitalize;
}
.new-build-three-flex-wrapper .new-build-first-wrapper > ul > li .btnWrap .readMore:hover {
    text-decoration: underline !important;
}
.new-build-three-flex-wrapper .new-build-first-wrapper.left ul li .right {
    padding-left: 0;
    padding-right: 30px;
    text-align: right;
}
.new-build-three-flex-wrapper .new-build-first-wrapper ul li .right .tech_icons_wrapper {
    padding-top: 15px;
}
.new-build-three-flex-wrapper .new-build-first-wrapper ul li .right .tech_icons_wrapper img {
    width: 30px;
    height: 30px;
    vertical-align: middle;
    transition: 0.8s;
}
.new-build-three-flex-wrapper .new-build-first-wrapper ul li .right .tech_icons_wrapper a {
    margin: 0 8px 8px;
    display: inline-block;
}
.new-build-three-flex-wrapper .new-build-first-wrapper li .tech_icons_wrapper a:hover img {
    transform: rotate(360deg);
}

/* web-dev-passion */
.maingray {
   background-color: #fafafa;
   margin-top: 90px;
   padding: 80px 0;
}
.hexagon {
 position: relative;
 width: 130px;
 height: 75.06px;
 background-color: #ffffff;
 margin: 37.53px 0 65.53px;
 border-left: solid 3px #b5b5b5;
 border-right: solid 3px #b5b5b5;
     transform: rotate(90deg);
      -ms-transform: rotate(90deg);
       -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        transition: all 0.5s;
     -ms-transition: all 0.5s;
     -moz-transition: all 0.5s;
     -webkit-transition: all 0.5s;
}
.hexagon:before,
.hexagon:after {
 content: "";
 position: absolute;
 z-index: 1;
 width: 91.92px;
 height: 91.92px;
 -webkit-transform: scaleY(0.5774) rotate(-45deg);
 -ms-transform: scaleY(0.5774) rotate(-45deg);
 transform: scaleY(0.5774) rotate(-45deg);
 background-color: inherit;
 left: 16.0381px;
}
.hexagon:before {
top: -48.9619px;
 border-top: solid 4.2426px #b5b5b5;
 border-right: solid 4.2426px #b5b5b5;
}
.hexagon:after {
 bottom: -48.9619px;
 border-bottom: solid 4.2426px #b5b5b5;
 border-left: solid 4.2426px #b5b5b5;
}
.hexagon img {
   position: absolute;
   z-index: 99;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%) rotate(-90deg);
   -ms-transform: translate(-50%, -50%) rotate(-90deg);
   -webkit-transform: translate(-50%, -50%) rotate(-90deg);
   -moz-transform: translate(-50%, -50%) rotate(-90deg);
}
.hexagon:hover {
background: rgb(244,103,60);
background: -moz-linear-gradient(90deg, rgba(244,103,60,1) 0%, rgba(236,69,53,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(244,103,60,1) 0%, rgba(236,69,53,1) 100%);
background: linear-gradient(90deg, rgba(244,103,60,1) 0%, rgba(236,69,53,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f4673c",endColorstr="#ec4535",GradientType=1);
   border-color: #fff;
   transform: scale(1.5) rotate(90deg);
   -ms-transform: scale(1.5) rotate(90deg);
   -webkit-transform: scale(1.5) rotate(90deg);
   -moz-transform: scale(1.5) rotate(90deg);
   z-index: 9;
}
.hexagon:hover:before, .hexagon:hover:after {
   border-color: #fff;
    background: rgb(244,103,60);
background: -moz-linear-gradient(90deg, rgba(244,103,60,1) 0%, rgba(236,69,53,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(244,103,60,1) 0%, rgba(236,69,53,1) 100%);
background: linear-gradient(90deg, rgba(244,103,60,1) 0%, rgba(236,69,53,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f4673c",endColorstr="#ec4535",GradientType=1);
}
.hexagon span {
 display: block;
 position: absolute;
 top:1.7320508075688772px;
 left: 0;
 width:124px;
 height:71.5914px;
 z-index: 2;
 background: inherit;
 background-color: #fff;
     transition: all 0s;
     -ms-transition: all 0s;
     -moz-transition: all 0s;
     -webkit-transition: all 0s;
}


.web-dev-passion-main {
    display: flex;
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -o-flex;
    flex-wrap: wrap;
    margin: 0 -20px;
}
.web-dev-passion-main .web-dev-passion-left .btnWrap {
    justify-content: flex-start;
}
.web-dev-passion-main .web-dev-passion-right ul {
    padding: 0;
    margin: 0;
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    list-style: none;
}
.web-dev-passion-right ul li {
    width: 20%;
}

.web-dev-passion-main .web-dev-passion-left h2 {
    text-align: left !important;
    padding: 0 !important;
    margin: 0 0 15px !important;
}

.web-dev-passion-main .web-dev-passion-left p {
    padding-right: 30px;
}
.web-dev-passion-main .web-dev-passion-left a {
    padding: 13px 40px;
    display: inline-block;
    margin: 10px;
    margin-left: 0;
    color: #fff;
    font-size: 14px;
    border-radius: 3px;
    border: 1px solid transparent;
}
.web-dev-passion-main .web-dev-passion-left a:hover {
    color: #444a60;
    border-color: #444a60;
}
.web-dev-passion-main .web-dev-passion-left a::before {
    background: #444a60;
}
.web-dev-passion-right {
    width: 100%;
    padding-top: 50px;
    max-width: 550px;
}
.hexagonal_shape {
    max-width: 155px;
    margin: 0 auto;
    padding: 4px;
    transition: all 0.5s ease-out;
    display: block;
}

.hexagonal_shape .hexa-box {
    position: relative;
    background-color: #fff;
    width: 60px;
    height: 120px;
    border-top: 3px solid #ccc;
    border-bottom: 3px solid #ccc;
    margin: 0 auto;
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    align-items: center;
    justify-content: center;
}
.hexagonal_shape .hexa-box img {
    position: relative;
    z-index: 1;
    width: 100%;
}
.hexagonal_shape .hexa-box::before {
    content: "";
    background-color: #fff;
    position: absolute;
    top: 16px;
    right: calc(100% - 44px);
    width: 86px;
    height: 85px;
    border-top: 3px solid #ccc;
    border-left: 3px solid #ccc;
    transform: scaleX(0.6774) rotate(-45deg);
}
.hexagonal_shape .hexa-box::after {
    content: "";
    background-color: #fff;
    position: absolute;
    top: 16px;
    left: calc(100% - 44px);
    width: 86px;
    height: 85px;
    border-bottom: 3px solid #ccc;
    border-right: 3px solid #ccc;
    transform: scaleX(0.6774) rotate(-45deg);
}
.web-dev-passion-right ul li:nth-child(even) aside:first-child, .web-dev-passion-right ul li:nth-child(even) > a:first-child {
    margin-top: -68px;
    display: inherit;
}
.hexagonal_shape.blank {
    height: 126px;
}
.web-dev-passion-right ul li:nth-child(even) aside:hover {
    z-index: 1;
}
.hexagonal_shape .hexa-box img.withHoverImg {
    display: none;
}

.web-dev-passion-right ul li aside.aos-init.aos-animate:hover {
    transform: inherit !important;
    position: relative;
    z-index: 1;
}
.our_passion_home.owl_curve_before_img::before {
    content: "";
    right: inherit;
    left: 0;
    top: inherit;
    bottom: -100px;
    transform: rotate(180deg);
    width: 250px
}
.web-dev-passion-main .web-dev-passion-left .hire_us_btn {
    background-color: #e05640;
}
.web-dev-passion-main .web-dev-passion-left .hire_us_btn:hover {
    background-color: transparent;
    color: #e05640;
    border-color: #e05640;
}
.web-dev-passion-main .web-dev-passion-left .hire_us_btn::before {
    background: #fff;
}
/* web-dev-passopn */

/*lets-discuss-project*/
.new-lets-discuss-our-project {
    width: 100%;
    background: #151c37;
    padding: 40px 0 0;
}
.new-lets-discuss-our-project h2 {
    color: #fff !important;
}

.new-lets-discuss-our-project p {
    color: #fff !important;
}
.lets-disucss-inner-project {
    padding-top: 50px;
}
.lets-disucss-inner-project ul {
    margin: 0;
    padding: 0;
    display: flex;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
}

.lets-disucss-inner-project ul li {
    list-style: none;
    position: relative;
    transition: all 0.5s;
    overflow: hidden;
    padding-bottom: 50px;
    display: flex;
    display: -ms-flexbox;
    align-items: flex-end;
    width: 200px;
}
.lets-disucss-inner-project ul li.project-image.active {
    transition: all 0.5s;
    width: 700px;
}
.lets-disucss-inner-project ul li h3 {
    color: #353950;
    font-size: 144px;
    font-family: 'Saira Extra Condensed', sans-serif !important;
    writing-mode: vertical-lr;
    transition: all 0.5s;
    text-transform: uppercase;
    letter-spacing: 7px;
    margin: 0;
    display: inline-block;
    transform: rotate(180deg);
    min-width: 226px;
    width: 226px;
}
.lets-disucss-inner-project ul li h3:before
{
    position: absolute;
    content: "";
    width: 3px;
    height: 60px;
    background: #353950;
    top: -50px;
    transition: all 0.5s;
    left: 0;
    right: 0;
    margin: auto;
    opacity: 0;
} 
.lets-disucss-inner-project ul li:hover h3::before, .lets-disucss-inner-project ul li.project-image.active h3::before{
    background: #e05640;
    opacity: 1;
}
.lets-disucss-inner-project ul li:hover h3 {
    transition: all 0.5s;
    color: #e05640;
}
.lets-disucss-inner-content img {
    width: 120px;
    height: 120px;
    transition: all 0.5s;
    margin: 0 0 20px;
    flex-shrink: 0;
}
.lets-disucss-inner-content h2 {
    font-size: 30px !important;
    padding: 0 !important;
    text-align: left !important;
}

.lets-disucss-inner-content p {
    font-size: 15px;
    line-height: 1.9;
    text-align: left;
}
.lets-disucss-inner-project ul li .lets-disucss-inner-content {
    transition: all 0.5s;
    min-width: 400px;
    width: 400px;
}
.lets-disucss-inner-project ul li.project-image.active h3 {
    transition: all 0.5s;
    color: #e05640;
}


/*lets-discuss-project*/



.new-build-wrapper .head_paragraph strong {
    font-weight: 600;
}

/* =========13-dec-2019-S-css-start=========== */

.dark-btn-effect{
    position: relative;
    overflow: hidden;
    z-index: 1;
    border:1px solid #ed402c;
}
.dark-btn-effect:hover{
    background-color:transparent;
    border:1px solid #444a60;
}
.dark-btn-effect::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #444a60;
    z-index: -1;
    transition: transform 0.4s;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
}
.dark-btn-effect:hover::before {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}
/* =========13-dec-2019-S-css-end=========== */


/****HomeBannerSlider Start****/
.slider * {
    box-sizing: border-box;
}
.slider {
    overflow: hidden;
    position: relative;
    height: calc(100vh - 75px);
    color: #fff;
    min-height: 380px;
}

.slider__top-heading {
    z-index: 12;
    position: absolute;
    left: 0;
    top: 100px;
    width: 100%;
    text-align: center;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    transition: all 0.5s 1s;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
    opacity: 0;
}

.slider.s--ready .slider__top-heading {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
}

.slider__slides {
    position: relative;
    height: 100%;
}

.slider__slide {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.slider__slide.s--active {
    pointer-events: auto;
}

.slider__slide-content {
    z-index: 6;
    position: relative;
    display: flex;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -o-flex;
    display: -ms-flex;
    display: -moz-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    line-height: 1;
    max-width: 1024px;
    margin: 0 auto;
    text-align: center;
}

.slider__slide-description {
    font-size: 15px;
    line-height: 1.4 !important;
    margin-top: 0;
    transform: scale(0);
    opacity: 0;
    transition: 0.5s;
    width: 100%;
}
.slider__slide.s--active .slider__slide-description {
    transition-delay: 0.65s;
    opacity: 1;
    transform: scale(1);
}

.slider__slide-subheading {
    font-size: 24px;
    line-height: 1;
    font-weight: 400;
    letter-spacing: 1px;
    transform: translateY(20px);
    opacity: 0;
    transition: 0.5s;
    margin: 0;
    font-family: poppins;
    position: relative;
    padding: 0 10px;
}
.slider__slide-subheading::before, .slider__slide-subheading::after {
    content: "";
    width: 100px;
    height: 2px;
    background: #fff;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.slider__slide-subheading::before {
    right: 100%;
}
.slider__slide-subheading::after {
    left: 100%;
}
.slider__slide-heading span {
    display: inline-block;
    min-width: 15px;
}
.slider__slide.s--active .slider__slide-subheading {
    transition-delay: 0.65s;
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

.slider__slide-heading {
    display: flex;
    display: -ms-flexbox;
    font-size: 60px;
    line-height: 1;
    letter-spacing: 2px;
    font-family: 'Saira Extra Condensed', sans-serif !important;
    margin: 0px;
}

.slider__slide-heading span {
    display: block;
    opacity: 0;
    -webkit-transform: translateY(-60px);
    transform: translateY(-60px);
    transition: all 0.3333333333s;
}
.slider__slide-content .title-wrapper {
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    margin-bottom: 10px;
    transform: translateY(20px);
    opacity: 0;
    transition: 0.5s;
}

.slider__slide.s--active .slider__slide-content .title-wrapper {
    transition-delay: 0.65s;
    opacity: 1;
    transform: translateY(0);
}

.slider__slide-content .title-wrapper i {
    font-size: 60px;
    line-height: 1;
    letter-spacing: 2px;
    font-family: 'Saira Extra Condensed', sans-serif !important;
    font-style: normal;
    font-weight: 700;
    margin-left: 15px;
    background-color: #fff;
    color: #000;
    padding: 7px 16px;
    display: inline-block;
    text-transform: uppercase;
}

.slider__slide.s--prev .slider__slide-heading span {
    -webkit-transform: translateY(60px);
    transform: translateY(60px);
}

.slider__slide.s--active .slider__slide-heading span {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

.slider__slide-heading span:nth-child(1) {
    transition-delay: 0s;
}

.slider__slide.s--active .slider__slide-heading span:nth-child(1) {
    transition-delay: 0.3333333333s;
}

.slider__slide-heading span:nth-child(2) {
    transition-delay: 0.1s;
}

.slider__slide.s--active .slider__slide-heading span:nth-child(2) {
    transition-delay: 0.4333333333s;
}

.slider__slide-heading span:nth-child(3) {
    transition-delay: 0.2s;
}

.slider__slide.s--active .slider__slide-heading span:nth-child(3) {
    transition-delay: 0.5333333333s;
}

.slider__slide-heading span:nth-child(4) {
    transition-delay: 0.3s;
}

.slider__slide.s--active .slider__slide-heading span:nth-child(4) {
    transition-delay: 0.6333333333s;
}

.slider__slide-heading span:nth-child(5) {
    transition-delay: 0.4s;
}

.slider__slide.s--active .slider__slide-heading span:nth-child(5) {
    transition-delay: 0.7333333333s;
}

.slider__slide-heading span:nth-child(6) {
    transition-delay: 0.5s;
}

.slider__slide.s--active .slider__slide-heading span:nth-child(6) {
    transition-delay: 0.8333333333s;
}

.slider__slide-heading span:nth-child(n+7) {
    transition-delay: 0.6s;
}

.slider__slide.s--active .slider__slide-heading span:nth-child(n+7) {
    transition-delay: 0.9333333333s;
}

.slider__slide-readmore {
    position: relative;
    font-size: 14px;
    text-transform: lowercase;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    cursor: pointer;
    opacity: 0;
    transition: 0.5s;
}
.slider__slide-readmore a {
    display: inline-block !important;
    color: #fff;
    padding: 14px 20px;
    border-radius: 2px;
    text-transform: capitalize;
    font-family: poppins;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1.5px;
    position: relative;
    border: 1px solid transparent;
    margin: 0 !important;
}
.slider__slide-readmore a:hover {
    border-color: #fff;
    color: #ed402c;
}
.slider__slide-readmore a::before {
    background: #fff;
}
.slider__slide.s--active .slider__slide-readmore {
    transition-delay: 0.65s;
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

.slider__slide-readmore:before {
    content: '';
    position: absolute;
    left: -2px;
    top: -3px;
    width: calc(100% + 4px);
    height: calc(100% + 6px);
    background: rgba(255, 255, 255, 0.4);
    -webkit-transform: scaleX(0.3);
    transform: scaleX(0.3);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
    opacity: 0;
}

.slider__slide-readmore:hover:before {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}

.slider__slide-parts {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    width: 100%;
    height: 100%;
}

.slider__slide-parts:after {
    content: '';
    z-index: 5;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
}

.slider__slide-part {
    position: relative;
    width: 25%;
    height: 100%;
    margin-left: -0.1px;
}

.slider__slide-part-inner {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    background-size: 0 0;
    background-repeat: no-repeat;
    transition: -webkit-transform 1s ease-in-out;
    transition: transform 1s ease-in-out;
    transition: transform 1s ease-in-out, -webkit-transform 1s ease-in-out;
}

.slider__slide-part-inner:before {
    content: '';
    position: absolute;
    width: 100vw;
    height: 100%;
    background-image: inherit;
    background-size: cover;
    background-position: center center;
    transition: opacity 0.5s;
    opacity: 0;
}

.slider__slide-part:nth-child(1) .slider__slide-part-inner {
    z-index: 3;
    transition-delay: 0.24s;
    -webkit-transform: translateX(-32.5%);
    transform: translateX(32.5%);
}

.slider__slide.s--active .slider__slide-part:nth-child(1) .slider__slide-part-inner {
    transition-delay: 0.28s;
}

.slider__slide-part:nth-child(1) .slider__slide-part-inner:before {
    left: 0vw;
    transition-delay: 0.365s;
}

.slider__slide.s--active .slider__slide-part:nth-child(1) .slider__slide-part-inner:before {
    transition-delay: 0.28s;
}

.slider__slide-part:nth-child(2) .slider__slide-part-inner {
    z-index: 2;
    transition-delay: 0.16s;
    -webkit-transform: translateX(-65%);
    transform: translateX(-65%);
}

.slider__slide.s--active .slider__slide-part:nth-child(2) .slider__slide-part-inner {
    transition-delay: 0.36s;
}

.slider__slide-part:nth-child(2) .slider__slide-part-inner:before {
    left: -25vw;
    transition-delay: 0.285s;
}

.slider__slide.s--active .slider__slide-part:nth-child(2) .slider__slide-part-inner:before {
    transition-delay: 0.36s;
}

.slider__slide-part:nth-child(3) .slider__slide-part-inner {
    z-index: 1;
    transition-delay: 0.08s;
    -webkit-transform: translateX(-97.5%);
    transform: translateX(-97.5%);
}

.slider__slide.s--active .slider__slide-part:nth-child(3) .slider__slide-part-inner {
    transition-delay: 0.44s;
}

.slider__slide-part:nth-child(3) .slider__slide-part-inner:before {
    left: -50vw;
    transition-delay: 0.205s;
}

.slider__slide.s--active .slider__slide-part:nth-child(3) .slider__slide-part-inner:before {
    transition-delay: 0.44s;
}

.slider__slide-part:nth-child(4) .slider__slide-part-inner {
    z-index: 0;
    transition-delay: 0s;
    -webkit-transform: translateX(-130%);
    transform: translateX(-130%);
}

.slider__slide.s--active .slider__slide-part:nth-child(4) .slider__slide-part-inner {
    transition-delay: 0.52s;
}

.slider__slide-part:nth-child(4) .slider__slide-part-inner:before {
    left: -75vw;
    transition-delay: 0.125s;
}

.slider__slide.s--active .slider__slide-part:nth-child(4) .slider__slide-part-inner:before {
    transition-delay: 0.52s;
}

.slider__slide.s--active .slider__slide-part-inner {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    transition-timing-function: ease;
}

.slider__slide.s--active .slider__slide-part-inner:before {
    opacity: 1;
}

.slider__control {
    z-index: 100;
    position: absolute;
    left: 50px;
    top: 50%;
    width: 50px;
    height: 50px;
    margin-top: -25px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.4);
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
    /* transition: all 0.5s 1s; */
    cursor: pointer;
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    justify-content: center;
}
/* .slider__control:hover {
    animation: arrow-pulse 1s ease-out infinite;
} */
.slider__control i {
    font-size: 36px;
}
.slider__control:hover {
    background: rgba(0, 0, 0, 0.4);
}

@-webkit-keyframes arrow-pulse {
    from {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
    }
    to {
      box-shadow: 0 0 0 20px rgba(79, 153,53, 0);
    }
  }
@keyframes arrow-pulse {
    from {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
    }
    to {
        box-shadow: 0 0 0 20px rgba(79, 153,53, 0);
    }
}
/* .slider__control:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 12px;
    height: 12px;
    border: 2px solid #fff;
    -webkit-transform: translate(-50%, -50%) rotate(-45deg);
    transform: translate(-50%, -50%) rotate(-45deg);
}
.slider__control.slider__control--left::before {
    border-bottom: 0;
    border-right: 0;
}
.slider__control.slider__control--right::before {
    border-top: 0;
    border-left: 0;
} */
.slider__control--right {
    left: auto;
    right: 50px;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
}


.slider.s--ready .slider__control {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
}

.icon-link {
    z-index: 100;
    position: absolute;
    left: 5px;
    bottom: 5px;
    width: 32px;
}

.icon-link img {
    width: 100%;
    vertical-align: top;
}

.icon-link--twitter {
    left: auto;
    right: 5px;
}
/****HomeBannerSlider End****/


.new-build-mobile-section {
    display: none;
}
.web-mobile-icons-wrap {
    display: none;
}
.head_paragraph.mobile
{
    display: none;
}
img.mobile-year-image {
    display: none;
}
.web-dev-passion-left, .web-dev-passion-right {
    width: 50%;
}
.all-category-mobile {
    background-color: #ed402c;
    color: #fff;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    text-transform: capitalize;
    font-weight: 600;
    letter-spacing: 0.4px;
    padding: 12px 20px;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    display: inline-block;
}
.finest-project .btnWrap .all-category-mobile {
    margin: 0;
}
.homePortfolioSlider .btnWrap .hire_us_btn {
    background: #444a60;
    color: #fff;
    border: 1px solid transparent;
    padding: 12px 20px;
    font-size: 14px;
}
.homePortfolioSlider .btnWrap .hire_us_btn:hover {
    background: #fff;
    border-color: #ed402c;
    color: #ed402c;
}

.discuss_your_project {
    background-color: #fff;
}
.discuss_your_project .hexagonal_shape_wrap .box_hexagonal {
    background: #e05640;
    width: 130px;
    height: 222px;
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0 auto;
}
.discuss_your_project .hexagonal_shape_wrap .box_hexagonal::before, .discuss_your_project .hexagonal_shape_wrap .box_hexagonal::after {
    content: "";
    background: #e05640;
    width: 158px;
    transform: scaleX(0.6) rotate(45deg);
    height: 158px;
    position: absolute;
    top: 0px;
    bottom: 0px;
    margin: auto;
}
.discuss_your_project .hexagonal_shape_wrap .box_hexagonal::before {
    right: 39%;
}
.discuss_your_project .hexagonal_shape_wrap .box_hexagonal::after {
    left: 39%;
}
.discuss_your_project .hexagonal_shape_wrap .box_hexagonal img {
    width: 80px;
    height: 80px;
    position: relative;
    z-index: 1;
}
.discuss_your_project .listing_wrap ul {
    padding: 0;
    list-style: none;
    display: flex;
    display: -ms-flexbox;
    flex-wrap: wrap;
    position: relative;
}
.discuss_your_project .listing_wrap ul li {
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    width: 50%;
}
.discuss_your_project .listing_wrap ul li .content_wrap {
    width: 60%;
}
.discuss_your_project .listing_wrap ul li .hexagonal_shape_wrap {
    width: 40%;
    padding: 5px 30px;
}
.discuss_your_project .listing_wrap ul li:nth-child(odd) .content_wrap .head_title, .discuss_your_project .listing_wrap ul li:nth-child(odd) .content_wrap .head_paragraph {
    text-align: right !important;
}
.discuss_your_project .listing_wrap ul li:nth-child(even) .content_wrap .head_title, .discuss_your_project .listing_wrap ul li:nth-child(even) .content_wrap .head_paragraph {
    text-align: left !important;
}
.discuss_your_project .listing_wrap ul li:nth-child(even) .content_wrap {
    order: 2;
}
.discuss_your_project .listing_wrap ul li .content_wrap h4.head_title {
    color: #b8b8b8 !important;
}
.discuss_your_project .listing_wrap ul .middle-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 190px;
    height: 190px;
}
.discuss_your_project .listing_wrap ul .middle-circle::before {
    content: "";
    width: calc(100% + 45px);
    height: calc(100% + 45px);
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    right: 0;
    left: 50%;
    top: 50%;
    bottom: 0;
    transform: translate(-50%, -50%);
}
.discuss_your_project .listing_wrap ul .middle-circle::after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: linear-gradient(0deg, #c0c0c0, #f5f5f5);
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
    animation: pulse-grey 2s infinite;
}
.discuss_your_project .yellow .hexagonal_shape_wrap .box_hexagonal, .discuss_your_project .yellow .hexagonal_shape_wrap .box_hexagonal::before, .discuss_your_project .yellow .hexagonal_shape_wrap .box_hexagonal::after {
    background: #f2a139;
}
.discuss_your_project .blue .hexagonal_shape_wrap .box_hexagonal, .discuss_your_project .blue .hexagonal_shape_wrap .box_hexagonal::before, .discuss_your_project .blue .hexagonal_shape_wrap .box_hexagonal::after {
    background: #3177d2;
}
.discuss_your_project .green .hexagonal_shape_wrap .box_hexagonal, .discuss_your_project .green .hexagonal_shape_wrap .box_hexagonal::before, .discuss_your_project .green .hexagonal_shape_wrap .box_hexagonal::after {
    background: #5fcfb2;
}
.discuss_your_project .red .hexagonal_shape_wrap .box_hexagonal, .discuss_your_project .red .hexagonal_shape_wrap .box_hexagonal::before, .discuss_your_project .red .hexagonal_shape_wrap .box_hexagonal::after {
    background: #db2e29;
}
.discuss_your_project li.yellow:hover .content_wrap h4.head_title {
    color: #f2a139 !important;
}
.discuss_your_project li.blue:hover .content_wrap h4.head_title {
    color: #3177d2 !important;
}
.discuss_your_project li.green:hover .content_wrap h4.head_title {
    color: #5fcfb2 !important;
}
.discuss_your_project li.red:hover .content_wrap h4.head_title {
    color: #db2e29 !important;
}
@-webkit-keyframes pulse-grey
{
  0% {
    box-shadow: 0 0 0 0px rgba(97, 95, 95, 0.5);
  }
  100% {
    box-shadow: 0 0 0 45px rgba(158, 158, 158, 0.2);
  }
}
@keyframes pulse-grey
{
  0% {
    box-shadow: 0 0 0 0px rgba(97, 95, 95, 0.5);
  }
  100% {
    box-shadow: 0 0 0 45px rgba(158, 158, 158, 0.2);
  }
}
.shortLetterStyle{
    text-transform: lowercase;
    display: inline-block;
}

/*****Responsive Start****/
@media (min-width: 576px) {
    .discuss_your_project .listing_wrap ul li .content_wrap .img-wrapper-mobile {
        display: none;
    }
}
@media (min-width:992px) {
    .lets_Help .container_custom {
        max-width: 1022px;
    }
    .our_clients .slick-slider {
        padding: 0 70px;
    }
    .testimonials_2_layout .slick-slider {
        padding: 0 70px;
    }
}

@media (min-width:1025px) {
    .serviceListHomeSliderOne .slide_item .slide_right_text .wrap_coming_slide_content {
        width: 75%;
    }
    .serviceListHomeSliderOne img.bg_img_home_slide {
        width: 75%;
    }
    .hexagonal_shape:hover {
        transform: scale(1.3) rotate(360deg);
        position: relative;
        z-index: 1;
    }
    .hexagonal_shape:hover .hexa-box, .hexagonal_shape:hover .hexa-box::before, .hexagonal_shape:hover .hexa-box::after {
        background-color: #e05640;
        border-color: #fff;
    }
    .hexagonal_shape:hover .hexa-box img.withoutHoverImg {
        display: none;
    }    
    .hexagonal_shape:hover .hexa-box img.withHoverImg {
        display: block;
    }    
}


@media (min-width:2000px) {
    .serviceListHomeSliderOne .slide_item .slide_right_text .wrap_coming_slide_content {
        width: 75%;
    }
    .serviceListHomeSliderOne img.bg_img_home_slide {
        width: 75%;
    }
}

@media (min-width:1600px) {
    .our_clients .container_custom {
        max-width: 1156px;
    }
}

@media (min-width:1400px) and (max-width:1650px) {
    .serviceListHomeSliderOne .slide_item .slide_left_text .content_box {
        width: 380px;
        margin-left: 50px;
    }
    .serviceListHomeSliderOne .slide_item .slide_right_text .wrap_coming_slide_content {
        width: 550px;
    }
    .serviceListHomeSliderOne .slide_item .slide_right_text .wrap_coming_slide_content aside {
        margin-right: 50px;
    }
    .serviceListHomeSliderOne .slide_item .slide_right_text .wrap_coming_slide_content p.head_paragraph {
        margin-bottom: 20px !important;
    }
}

@media (min-width:1024px) and (max-width:1400px){
    .serviceListHomeSliderOne .slide_item .slide_left_text {
        width: 60%;
    }
    .serviceListHomeSliderOne .slide_item .slide_right_text {
        width: 40%;
    }
    .serviceListHomeSliderOne .slide_item .slide_left_text .content_box {
        width: 300px;
        margin-left: 50px;
    }
    .serviceListHomeSliderOne .slide_item .slide_left_text .content_box h3.head_title {
        font-size: 32px !important;
        margin-bottom: 20px;
    }
    .serviceListHomeSliderOne .slide_item .slide_left_text .content_box h4.head_paragraph {
        font-size: 24px !important;
        line-height: 32px !important;
        margin-bottom: 20px !important;
    }
    .serviceListHomeSliderOne .slide_item .slide_left_text .content_box p.head_paragraph {
        margin-bottom: 30px !important;
        font-size: 15px !important;
        line-height: 28px !important;
    }
    .serviceListHomeSliderOne .slide_item .slide_left_text .content_box a.read_more_btn {
        font-size: 15px;
        padding: 10px 30px;
    }
    .serviceListHomeSliderOne .slide_item .slide_right_text .wrap_coming_slide_content {
        width: 100%;
    }
    .serviceListHomeSliderOne .slide_item .slide_right_text .wrap_coming_slide_content aside {
        max-width: 55%;
        margin-right: 50px;
    }
    .serviceListHomeSliderOne .slide_item .slide_right_text .wrap_coming_slide_content h3.head_title {
        font-size: 32px !important;
        line-height: 40px !important;
        margin: 0;
    }
    .serviceListHomeSliderOne .slide_item .slide_right_text .wrap_coming_slide_content p.head_paragraph {
        font-size: 15px !important;
        line-height: 26px !important;
        margin-bottom: 20px !important;
    }
    .serviceListHomeSliderOne .slide_item .slide_right_text .wrap_coming_slide_content a.hire_us_btn {
        font-size: 13px;
    }   
}

@media (min-width:1367px) and (max-width:1866px) {
    .our_clients .our_clients_right_shape_orange {
        top: calc(0px - 280px);
    }
    .our_clients .our_clients_right_shape_orange img {
        width: 70%;
    }
}

@media (min-width:1024px) and (max-width:1366px) {
    .our_clients .our_clients_right_shape_orange {
        top: calc(0px - 200px);
    }
    .our_clients .our_clients_right_shape_orange img {
        width: 65%;
    }
}

@media (min-width:1280px) {
    .bannerImage_Text {
        padding: 0%;
        height:calc(100vh - 75px);
    }
    .Design_Dev .big_title h2 {
        position: absolute;
        transform: rotate(-90deg);
    }
    .Design_Dev .big_title .rotate_title .rotate_big_title {
        font-size: 200px;
        line-height: 30px;
        right: -60px;
        bottom: 155px;
    }
    .Design_Dev .big_title .rotate_title .rotate_med_title {
        font-size: 90px;
        top: 125px;
        line-height: 30px;
        left: -20px;
    }
    .Design_Dev .banner_text {
        padding: 60px 0;
        padding-left: 30px;
    }
    .Design_Dev {
        min-height: 100vh;
        display: flex;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        align-items: center;
    }
    .discuss_project {
        height: 100vh;
        display: flex;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        align-items: center;
    }
    .new-build-three-flex-wrapper .new-build-first-wrapper ul li .right p {
        margin: 0;
        font-size: 15px;
        color: #33384a;
        line-height: 1.7;
        max-width: 282px;
    }
    .new-build-three-flex-wrapper .new-build-first-wrapper ul li .right .tech_icons_wrapper img {
        width: 26px;
        height: 26px;
    } 
}

@media (min-width:1280px) and (max-width:1599.98px) {
    .Design_Dev .container_custom {
        max-width: 1080px;
    }
    .Design_Dev .big_title .rotate_title .rotate_big_title {
        font-size: 160px;
        line-height: 30px;
        right: -45px;
        bottom: 240px;
    }
    .Design_Dev .big_title .rotate_title .rotate_med_title {
        font-size: 70px;
        top: 143px;
        line-height: 30px;
        left: -10px;
    }
}


@media (min-width:1280px) and (max-width:1400px) {
    .discuss_project .project_image_center .middle_img .img_wrap {
        max-width: 200px;
        margin: auto;
    }
    .discuss_project .project_image_center .middle_img .img_wrap img.arrow.left {
        bottom: 0;
        left: calc(0px - 86px);
        width: 142px;
    }
    .discuss_project .project_image_center .middle_img .img_wrap img.arrow.right {
        top: 12px;
        right: calc(0px - 80px);
        width: 142px;
    }
}

@media (min-width:1200px) and (max-width:1399.98px) {
    .serviceListHomeSliderOne .slide_item {
        height: 614px;
    }
}

@media (min-width:1200px) and (max-width:1366px) {
    .bannerTextWrap .title {
        font-size: 46px;
    }
    .bannerTextWrap p {
        width: 80%;
    }
    .lets-disucss-inner-project {
        max-width: 992px;
        margin: 0 auto;
        width: 100%;
    }
    .lets-disucss-inner-project ul li.project-image.active {
        width: 80%;
    }
    .lets-disucss-inner-project ul li {
        padding-bottom: 30px;
        width: 20%;
    }
    .lets-disucss-inner-project ul li h3 {
        font-size: 100px;
        min-width: 150px;
        width: 150px;
    }
    .lets-disucss-inner-project ul li .lets-disucss-inner-content {
        min-width: 350px;
        width: 350px;
    }
    .lets-disucss-inner-project ul li.project-image.active h3::before {
        height: 35px;
        top: -30px;
    }
    .lets-disucss-inner-content img {
        width: 70px;
        height: 70px;
        margin: 0 0 10px;
    }
    .lets-disucss-inner-content p {
        font-size: 16px;
        line-height: 1.6;
    }
}

@media (min-width:1024px) and (max-width:1199.98px) {
    .bannerTextWrap .title {
        font-size: 42px;
    }
    .bannerTextWrap p {
        width: 85%;
        font-size: 15px;
        line-height: 24px;
    }
    .btnWrap a {
        font-size: 16px;
    }
    .serviceListHomeSliderOne .slide_item {
        height: 580px;
    }
    .clients_testimonials .testimonial_left_side {
        padding: 40px 40px 40px 0;
    }
}
@media (max-width: 1599.98px) {
    .finest-project .mobile-small-outer-wrapper {
        right: 10%;
    }
}
@media (max-width: 1440.98px) {
    .finest-project .mobile-small-outer-wrapper {
        right: 5%;
    }
}
@media (max-width:1279px) {
    .Design_Dev .design_dev_wrap .big_title {
        order: 2;
    }
    .Design_Dev .design_dev_wrap .banner_text {
        order: 3;
    }
    .Design_Dev .design_dev_wrap .img_wrap {
        order: 1;
        max-width: 100%;
        display: block;
        width: 100%;
        margin-bottom: 30px;
    }
    .discuss_project .project_from .count_text_box, .discuss_project .project_to .count_text_box {
        max-width: 100%;
        padding: 0;
    }
    .design_dev_wrap {
        text-align: center;
    }
    /* =========13-dec-2019-S-css-start=========== */
    
    .new-build-mobile-section .tech_icons_wrapper a {
        display: inline-block;
        margin: 0 7px;
    }
    .new-build-mobile-section li .left {
        width: 90px;
        background: #fff;
        border-radius: 50%;
        height: 90px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        border: 2px solid #ed3f2c;
        margin: 0 auto 20px;
    }
    
    .new-build-mobile-section li .left img {
        max-width: 60px;
        width: 100%;
    }
    
    .new-build-mobile-section li .right {
        width: auto;
        margin: 0 auto 15px;
    }
    
    .new-build-mobile-section li .right h4 {
        font-size: 20px;
        padding-bottom: 10px;
        margin: 0;
        text-transform: capitalize;
        text-align: center;
    }
    
    .new-build-mobile-section li .right h4 a {
        color: #f24934;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
    }
    
    .new-build-mobile-section li .right h4 a:hover {
        color: #e05640;
    }
    
    .new-build-mobile-section li .right p {
        margin-bottom: 15px;
        font-size: 13px;
        color: #858585;
        line-height: 1.5;
        text-align: center;
    }
    
    .new-build-mobile-section ul.slick-dots li button:before {
        font-size: 10px;
    }
    
    .new-build-mobile-section li {
        list-style: none;
        padding: 0 15px;
        max-width: 380px;
        margin: 0 auto;
    }
    .new-build-mobile-section .slick-slider {
        padding: 0 60px;
    }    
    
    .new-build-mobile-section .tech_icons_wrapper {
        display: flex;
        justify-content: center;
    }
    
    .new-build-mobile-section .tech_icons_wrapper img {
        max-width: 30px;
        padding: 0;
        height:30px;
    }
    .new-build-mobile-section ul.slick-dots {bottom: 5px;}
    
    .new-build-mobile-section ul.slick-dots li {
        padding: 0 1px 20px;
    }
    /* =========13-dec-2019-S-css-end=========== */
    .slider__slide-content {
        max-width: 767px;
    }
    .slider__slide-heading  {
        font-size: 40px;
    }
    .slider__slide-content .title-wrapper i {
        font-size: 40px;
        padding: 4px 12px;
    }
    .slider__slide-subheading {
        font-size: 20px;
    }
    .slider__control {
        width: 35px;
        height: 35px;
    }
    .slider__control i {
        font-size: 28px;
    }
    .slider__slide-readmore a {
        padding: 14px;
        font-size: 14px;
    }
    .slider {
        height: 65vh !important;
    }
    .new-build-mobile-section {
        margin-bottom: 50px;
    }
    .finest-project .mobile-small-outer-wrapper {
        max-width: 340px;
    }
    .finest-project .mobile-small-outer-wrapper:before {
        width: 340px;
    }  
    .discuss_your_project .listing_wrap ul li .content_wrap {
        width: 65%;
    }
    .discuss_your_project .listing_wrap ul li .hexagonal_shape_wrap {
        width: 35%;
        padding: 5px 20px;
    }
    .discuss_your_project .hexagonal_shape_wrap .box_hexagonal {
        width: 88px;
        height: 152px;
    }
    .discuss_your_project .hexagonal_shape_wrap .box_hexagonal::before, .discuss_your_project .hexagonal_shape_wrap .box_hexagonal::after {
        width: 107px;
        height: 108px;
    }
    .discuss_your_project .hexagonal_shape_wrap .box_hexagonal img {
        width: 50px;
        height: 50px;
    }
    .discuss_your_project .listing_wrap ul li .content_wrap .head_paragraph {
        font-size: 14px !important;
        line-height: 1.8 !important;
    }
    .discuss_your_project .listing_wrap ul li .content_wrap .head_title {
        font-size: 28px !important;
        margin-bottom: 5px !important;
        display: inline-block;
        width: auto !important;
    }
    .discuss_your_project .listing_wrap ul li .content_wrap h4.head_title {
        margin-right: 8px;
    }
    .discuss_your_project .listing_wrap ul li:nth-child(odd) .content_wrap {
        text-align: right;
    }
    .discuss_your_project .listing_wrap ul .middle-circle {
        width: 136px;
        height: 136px;
    }
    .discuss_your_project .listing_wrap ul .middle-circle::before {
        width: calc(100% + 32px);
        height: calc(100% + 32px);
    }  
    .new-build-three-flex-wrapper .new-build-first-wrapper ul li .right h4 {
        font-size: 18px;
    }
    .new-build-three-flex-wrapper .new-build-first-wrapper ul li .right p {
        font-size: 14px;
    }
    .new-build-three-flex-wrapper .new-build-first-wrapper ul li .right {
        padding-left: 20px;
    }
    .new-build-three-flex-wrapper .new-build-first-wrapper.left ul li .right {
        padding-right: 20px;
    }
}
@media (max-width:1260.98px) {
    .new-build-wrapper .new-build-three-flex-wrapper {
        display: none;
    }
    .new-build-mobile-section {
        display: block!important;
    }
}
@media (max-width:1199.98px) {
    .finest-project {
        max-width: 100%;
        margin: 0 auto;
    }    
    .finest-project .slider-position-wrapper .desktop_big_wrapper ul.slick-dots {
        bottom: -30px;
    }
    .lets-disucss-inner-project {
        max-width: 730px;
        margin: 0 auto;
        width: 100%;
    }
    .lets-disucss-inner-project ul li.project-image.active {
        width: 80%;
    }
    .lets-disucss-inner-project ul li {
        padding-bottom: 30px;
        width: 20%;
    }
    .lets-disucss-inner-project ul li h3 {
        font-size: 80px;
        min-width: 125px;
        width: 125px;
    }
    .lets-disucss-inner-project ul li .lets-disucss-inner-content {
        min-width: 300px;
        width: 300px;
    }
    .lets-disucss-inner-project ul li.project-image.active h3::before {
        height: 35px;
        top: -30px;
    }
    .lets-disucss-inner-content img {
        width: 70px;
        height: 70px;
        margin: 0 0 10px;
    }
    .lets-disucss-inner-content p {
        font-size: 14px;
        line-height: 1.6;
    }
}

@media (max-width:1024px) {
    .serviceListHomeSliderOne .slide_item {
        height: 80vh;
        background-color: #f7f7f7;
    }
    .serviceListHomeSliderOne img.bg_img_home_slide {
        display: none;
    }
    .serviceListHomeSliderOne .slide_item .slide_left_text {
        width: 100%;
    }
    .serviceListHomeSliderOne img.bg_home_slide_angle_cut {
        display: none;
    }
    .serviceListHomeSliderOne .slide_item .slide_left_text .content_box {
        width: 100%;
        margin: auto;
        padding: 0 15px;
    }
    .serviceListHomeSliderOne .slide_item .slide_right_text {
        display: none;
    }
    .serviceListHomeSliderOne .slick-prev {
        left: 405px;
    }
    .serviceListHomeSliderOne .slick-next {
        right: -550px;
    }
    .serviceListHomeSliderOne {
        background-color: #f7f7f7;
    }
    .home_banner video.videoTag.desktop_video {
        display: none;
    }
    .home_banner::before {
        background-color: transparent;
    }
    .bannerTextWrap .title {
        font-size: 32px;
        line-height: 40px;
    }
    .bannerTextWrap p {
        width: 100%;
        font-size: 14px;
        line-height: 20px;
    }
    .web-mobile-icons-wrap .slick-center aside .hexagonal_shape {
        transform: scale(1.2);
    }
    .web-mobile-icons-wrap .slick-center aside .hexagonal_shape .hexa-box {
        background: #e05640;
        border-color: #fff;
    }
    .web-mobile-icons-wrap .slick-center aside .hexagonal_shape .hexa-box::before, .web-mobile-icons-wrap .slick-center aside .hexagonal_shape .hexa-box::after {
        background: #e05640;
        border-color: #fff;
    }
    .web-mobile-icons-wrap .slick-center aside .hexagonal_shape .hexa-box img.withoutHoverImg {
        display: none;
    }    
    .web-mobile-icons-wrap .slick-center aside .hexagonal_shape .hexa-box img.withHoverImg {
        display: block;
    }
    .web-mobile-icons-wrap {
        margin-bottom: 50px;
    }
    .our_clients .clients_logo a {
        padding: 15px 20px;
    }
    .web-mobile-icons-wrap .slick-slider .hexagonal_shape {
        margin-bottom: 30px;
    }
    .web-mobile-icons-wrap .slick-slider .slick-arrow {
        top: 100%;
        transform: inherit;
    }
    .web-mobile-icons-wrap .slick-slider .slick-prev {
        left: calc(50% - 50px) !important;
    }
    .web-mobile-icons-wrap .slick-slider .slick-next {
        right: calc(50% - 50px) !important;
    }
}
@media (min-width:801px) and (max-width:1150px) {
    .lets_Help .services_icons_wrap {
        max-width: 770px;
        margin: auto;
    }
    .lets_Help .services_icons_wrap .icons_wrap {
        width: 60px;
        height: 60px;
    }
    .lets_Help .services_icons_wrap .icons_wrap > .avatar {
        max-width: 30px;
    }
    .lets_Help .services_icons_wrap .icons_wrap.angular {
        left: 0;
    }
    .lets_Help .services_icons_wrap .icons_wrap.apple {
        bottom: 100px;
        left: 60px;
    }
    .lets_Help .services_icons_wrap .icons_wrap.php {
        bottom: 200px;
    }
    .lets_Help .services_icons_wrap .icons_wrap.icon_serv_1 {
        right: 200px;
    }
    .lets_Help .services_icons_wrap .icons_wrap.icon_serv_2 {
        top: 80px;
        right: 10px;
    }
    .lets_Help .services_icons_wrap .icons_wrap.icon_serv_3 {
        bottom: 100px;
        right: 30px;
    }
    .lets_Help .services_icons_wrap .icons_wrap.magento {
        right: 15px;
    }
    .lets_Help .services_icons_wrap .icons_wrap.drupal {
        bottom: 50px;
    }
    .lets_Help .services_icons_wrap .icons_wrap.android {
        bottom: 150px;
        left: 180px;
    }    
}
@media (min-width:768px) and (max-width:1279px){
    .discuss_project .media {
        flex-direction: column;
    }
    .discuss_project .project_to .media .count_text_box {
        order: 2;
    }
    .discuss_project .project_to .media .icon_box {
        order: 1;
    }
    .discuss_project .three_column_layout .project_image_center {
        display: flex;
        display: -webkit-flex;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
        align-items: center;
        justify-content: center;
    }
    .discuss_project .project_image_center .middle_img .img_wrap {
        max-width: 180px;
        margin: auto;
    }
    .discuss_project .project_image_center .middle_img .img_wrap img.arrow.left {
        bottom: 0;
        left: calc(0px - 65px);
        width: 120px;
    }
    .discuss_project .project_image_center .middle_img .img_wrap img.arrow.right {
        top: 6px;
        right: calc(0px - 50px);
        width: 120px;
    }
}

@media (min-width:768px) and (max-width:991.98px){
    .serviceListHomeSliderOne .slide_item {
        height: auto;
        padding: 50px 0;
    }
    .serviceListHomeSliderOne .slick-arrow {
        bottom: 55px;
    }
}

@media (min-width:768px) and (max-width:959px){
    .discuss_project .media {
        flex-direction: column;
    }
    .discuss_project .project_to .media .count_text_box {
        order: 2;
    }
    .discuss_project .project_to .media .icon_box {
        order: 1;
    }
    .discuss_project .three_column_layout .project_image_center {
        display: flex;
        display: -webkit-flex;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
        align-items: center;
        justify-content: center;
    }
    .discuss_project .project_image_center .middle_img .img_wrap {
        max-width: 140px;
        margin: auto;
    }
    .discuss_project .project_image_center .middle_img .img_wrap img.arrow.left {
        bottom: 0;
        left: calc(0px - 65px);
        width: 120px;
    }
    .discuss_project .project_image_center .middle_img .img_wrap img.arrow.right {
        top: 6px;
        right: calc(0px - 50px);
        width: 120px;
    }
    .discuss_project .media .icon_box {
        max-width: 70px;
        width: 100%;
    }
    .discuss_project .media .icon_box img {
        width: 100%;
    }
}


@media (min-width:768px) and (max-width:840px){
    .discuss_project .project_image_center .middle_img .img_wrap img.arrow.left {
        bottom: -13px;
        left: calc(0px - 35px);
        width: 90px;
        transform: rotate(-15deg);
    }
    .discuss_project .project_image_center .middle_img .img_wrap img.arrow.right {
        top: -18px;
        right: calc(0px - 36px);
        width: 90px;
        transform: rotate(-30deg);
    }
}

@media (min-width:768px) and (max-width:991.98px) {
    .bannerTextWrap .title {
        font-size: 32px;
        line-height: 40px;
    }
    .bannerTextWrap p {
        width: 100%;
        font-size: 14px;
        line-height: 20px;
    }
    .btnWrap a {
        padding: 10px 30px !important;
        line-height: 22px !important;
        font-size: 14px !important;
    }
}

@media (min-width:801px) and (max-width:991.98px) {
    .our_clients .our_clients_right_shape_orange {
        top: calc(0px - 150px);
    }
    .our_clients .our_clients_right_shape_orange img {
        width: 45%;
    }
}

@media (max-width: 991.98px) {
    .digital_service.sec_padding {
        padding-bottom: 180px;
    }
    .slider__control {
        top: inherit;
        bottom: 25px;
    }
    .slider__control.slider__control--left {
        left: calc(50% - 50px);
    }
    .slider__control.slider__control--right {
        right: calc(50% - 50px);
    }
    .slider__slide-content {
        padding: 0 65px;
    }
    .finest-project .mobile-small-outer-wrapper {
        display: none;
    }
    .finest-project .btnWrap .hire_us_btn {
        padding: 10px 20px;
    }
    .new-build-mobile-section .slick-slider {
        padding: 0px; 
    } 
    .new-build-mobile-section .slick-slider {
        margin-bottom: 40px;
    }
    .new-build-mobile-section .slick-slider .slick-arrow {
        top: 100%;
        transform: inherit;
    }
    .new-build-mobile-section .slick-slider .slick-prev {
        left: calc(50% - 50px) !important;
    }
    .new-build-mobile-section .slick-slider .slick-next {
        right: calc(50% - 50px) !important;
    }
    .our_clients .slick-slider {
        margin-bottom: 40px;
    }
    .our_clients .slick-slider .slick-arrow {
        top: 100%;
        transform: inherit;
    }
    .our_clients .slick-slider .slick-prev { 
        left: calc(50% - 50px) !important;
    }
    .our_clients .slick-slider .slick-next { 
        right: calc(50% - 50px) !important;
    }
    .finest-project .desktop_big_wrapper .slick-slider .slick-arrow {
        top: 100%;
        transform: inherit;
    }
    .finest-project .desktop_big_wrapper .slick-slider {
        position: relative;
    }
    .finest-project .desktop_big_wrapper .slick-slider .slick-prev {
        left: calc(50% - 50px) !important;
    }
    .finest-project .desktop_big_wrapper .slick-slider .slick-next {
        right: calc(50% - 50px) !important;
    }
    .finest-project .slider-position-wrapper .desktop_big_wrapper .slick-slider {
        margin-bottom: 60px;
        padding-bottom: 0;
    }
    .testimonials_2_layout .slick-slider {
        margin-bottom: 40px;
        padding-bottom: 20px;
    }
    .testimonials_2_layout .slick-slider .slick-arrow {
        top: 100%;
        transform: inherit;
    }
    .testimonials_2_layout .slick-slider .slick-prev {
        left: calc(50% - 50px) !important;
    }
    .testimonials_2_layout .slick-slider .slick-next {
        right: calc(50% - 50px) !important;
    }
    .discuss_your_project .listing_wrap ul .middle-circle {
        display: none;
    }
    .discuss_your_project .listing_wrap ul li {
        width: 100%;
    }
    .discuss_your_project .listing_wrap ul li .content_wrap {
        width: 80%;
        padding: 0 30px;
    }
    .discuss_your_project .listing_wrap ul li .hexagonal_shape_wrap {
        width: 20%;
        padding: 0px 30px;
    }
}

@media (max-width:767.98px) {
    .bannerTextWrap {
        width: 90%;
    }
    .bannerTextWrap .title {
        font-size: 22px;
        line-height: 34px;
    }
    .bannerTextWrap p {
        width: 100%;
        font-size: 14px;
        line-height: 24px;
    }
    .head_paragraph {
        font-size: 14px !important;
        line-height: 24px !important;
    }
    .Design_Dev .banner_text p {
        font-size: 14px;
        line-height: 24px;
    }
    .btnWrap a {
        margin-bottom: 10px !important;
        font-size: 13px !important;
        padding: 8px 16px !important;
    }
    .lets_Help .btnWrap a {
        font-size: 13px;
    }
    .digital_service h2 {
        font-size: 40px;
        line-height: 46px;
        margin-bottom: 30px;
    }
    .lets_Help .head_title {
        margin-bottom: 10px;
    }   
    .Design_Dev .banner_text h2 {
        font-size: 40px;
        line-height: 46px;
    } 
    .Design_Dev .big_title .rotate_title {
        margin-bottom: 12px;
    }
    .discuss_project .project_image_center .middle_img .img_wrap img.arrow.left {
        left: 0px;
        top: -25px;
        width: 100px;
    }
    .discuss_project .project_image_center .middle_img .img_wrap img.arrow.right {
        right: -19px;
        bottom: 0;
        width: 100px;
    }
    .discuss_project .media {
        flex-direction: column;
        align-items: center;
    }
    .discuss_project .media .count_text_box {
        width: 100%;
    }
    .discuss_project .media .count_text_box {
        text-align: center;
    }
    .discuss_project .three_column_layout > div {
        width: 100%;
        max-width: 100%;
        flex-basis: 100%;
    }
    .discuss_project .project_image_center .middle_img .img_wrap img.center_img {
        max-width: 200px;
    }
    .discuss_project .project_image_center .middle_img .img_wrap {
        position: relative;
        max-width: 200px;
        margin: auto;
    }
    .discuss_project .project_image_center .middle_img .img_wrap img.arrow.left {
        bottom: unset;
        left: -20px;
        top: -18px;
        transform: rotate(70deg);
        transform-origin: 0 0;
        width: 110px;
    }
    .discuss_project .project_image_center .middle_img .img_wrap img.arrow.right {
        top: unset;
        right: -44px;
        bottom: 6px;
        transform: rotate(70deg);
        width: 110px;
    }
    .discuss_project .media {
        display: flex;
        display: -webkit-flex;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
        margin: auto;
        max-width: 600px;
        width: 100%;
    }
    .discuss_project .project_to .media .icon_box {
        order: 1;
    }
    .discuss_project .project_to .media .count_text_box {
        order: 2;
    }
    .discuss_project .media .icon_box {
        max-width: 70px;
        width: 100%;
    }
    .discuss_project .media .count_text_box {
        width: calc(100% - 100px);
    }
    .discuss_project .three_column_layout img:not(.arrow) {
        width: 100%;
    }
    .serviceListHomeSliderOne {
        background-color: #f7f7f7;
    }
    .serviceListHomeSliderOne .slick-prev {
        left: 70px;
    }
    .serviceListHomeSliderOne .slick-next {
        right: -220px;
    }
    .serviceListHomeSliderOne .slick-arrow {
        bottom: 40px;
    }
    .serviceListHomeSliderOne .slide_item .slide_left_text .content_box h3.head_title {
        font-size: 40px !important;
    }
    .serviceListHomeSliderOne .slide_item .slide_left_text .content_box h4.head_paragraph {
        font-size: 26px !important;
        line-height: 36px !important;
        margin-bottom: 10px !important;
    }
    .serviceListHomeSliderOne .slide_item .slide_left_text .content_box p.head_paragraph {
        margin-bottom: 30px !important;
    }
    .serviceListHomeSliderOne .slide_item {
        height: auto;
        background-color: #f7f7f7;
        padding: 40px 0;
    }
    .serviceListHomeSliderOne .slide_item .slide_left_text .content_box a.read_more_btn {
        padding: 10px 25px;
    }
    .clients_testimonials .hiddenOnMobile {
        display: none;
    }
    .clients_testimonials .testimonial_left_side {
        padding: 40px 0;
    }
    .clients_testimonials .btnWrap a.read_all_btn {
        padding: 10px 30px;
    }
    .bannerImage_Text {
        padding: 0;
        height: 75vh;
    }
    .Design_Dev {
        background-image: none !important;
    }
    .finest-project .mobile-small-outer-wrapper:before {
        height: 218px;
        background-size: 100%;
        max-width: 200px;
    }
    
    .finest-project .mobile-small-wrapper {
        max-width: 100px;
    }
    
    .finest-project .mobile-small-outer-wrapper {
        max-width: 200px;
    }
    .finest-project .finest-project {
        padding-left: 20px;
        padding-right: 20px;
        max-width: 100%;
        margin: 0 auto;
        padding-top: 30px;
    }
    .finest-project .slider-position-wrapper .desktop_big_wrapper .slick-slide .finest-image-wrapper {
        padding: 10px;
    }    
    .finest-project {
        padding-right: 0;
        padding-left: 0;
    }
    .web-dev-passion-main .web-dev-passion-left p {
        margin-bottom: 12px !important;
    }
    .web-mobile-icons-wrap .slick-slide.slick-center .hexagonal_shape {
        transform: scale(1.2);
    }
    .slider__slide-subheading {
        font-size: 16px;
    }
    .slider__slide-heading, .slider__slide-content .title-wrapper i {
        font-size: 30px;
    }
    .slider__slide-heading span {
        min-width: 5px;
    }
    .slider__slide-readmore {
        margin-top: 0;
    }
    .slider__slide-subheading::before, .slider__slide-subheading::after {
        width: 60px;
    }
    .finest-project .btnWrap .hire_us_btn {
        padding: 8px 16px;
    }
    .finest-project .btnWrap > a {
        margin: 7px !important;
    }
    .slider__control {
        width: 26px;
        height: 26px;
    }   
    .slider__control i {
        font-size: 22px;
    } 
    .slider__control.slider__control--left {
        left: calc(50% - 40px);
    }
    .slider__control.slider__control--right {
        right: calc(50% - 40px);
    }
    .slider {
        height: calc(100vh - 73px) !important;
    }    
}
@media (min-width:780px) and (max-width:919.98px) {
    .slider {
        height: calc(100vh - 73px) !important;
    }
}
@media (min-width:600px) and (max-width:919.98px) {
    .slider__control {
        top: 50%;
        width: 26px;
        height: 26px;
    }   
    .slider__control i {
        font-size: 22px;
    } 
    .slider__control.slider__control--left {
        left: 35px;
    }    
    .slider__control.slider__control--right {
        right: 35px;
    }
}
@media (min-width:576px) and (max-width:800px) {
    .lets_Help .services_icons_wrap {
        max-width: 500px;
        margin: auto;
    }
    .lets_Help .services_icons_wrap .icons_wrap {
        width: 45px;
        height: 45px;
    }
    .lets_Help .services_icons_wrap .icons_wrap > .avatar {
        max-width: 23px;
    }
    .lets_Help .services_icons_wrap .icons_wrap.angular {
        left: 0;
    }
    .lets_Help .services_icons_wrap .icons_wrap.icon_serv_3 {
        bottom: 125px;
        right: 0;
    }
    .lets_Help .services_icons_wrap .icons_wrap.icon_serv_2 {
        top: 90px;
        right: 40px;
    }
    .lets_Help .services_icons_wrap .icons_wrap.wordpress {
        top: 20px;
        right: 70px;
    }
    .lets_Help .services_icons_wrap .icons_wrap.drupal {
        bottom: 55px;
        right: 115px;
    }
    .lets_Help .services_icons_wrap .icons_wrap.bootstrap {
        bottom: 120px;
        left: 220px;
    }
    .lets_Help .services_icons_wrap .icons_wrap.php {
        bottom: 150px;
        left: 120px;
    }
    .lets_Help .services_icons_wrap .icons_wrap.apple {
        bottom: 110px;
        left: 70px;
    }
    .lets_Help .services_icons_wrap .icons_wrap.android {
        bottom: 110px;
        left: 140px;
    }
    .lets_Help .services_icons_wrap .icons_wrap.react {
        left: 333px;
    }
    .our_clients .our_clients_right_shape_orange {
        top: calc(0px - 180px);
    }
    .our_clients .our_clients_right_shape_orange img {
        width: 40%;
    }
    .our_clients ul.slick-dots li button {
        width: 15px;
        height: 15px;
    }    
}


@media (min-width:600px) and (max-width:767.98px) {
    .right_aligned_testimonials_slider > div {
        max-width: 100%;
        flex-basis: 100%;
    } 
}


@media (min-width:320px) and (max-width:575.98px) {
    .lets_Help .services_icons_wrap {
        max-width: 320px;
        margin: auto;
    }
    .lets_Help .services_icons_wrap .icons_wrap {
        width: 40px;
        height: 40px;
    }
    .lets_Help .services_icons_wrap .icons_wrap > .avatar {
        max-width: 20px;
    }
    .lets_Help .services_icons_wrap .icons_wrap.angular {
        left: 0;
    }
    .lets_Help .services_icons_wrap .icons_wrap.icon_serv_2 {
        right: 0;
        top: 40px;
    }
    .lets_Help .services_icons_wrap .icons_wrap.react {
        left: 120px;
    }
    .lets_Help .services_icons_wrap .icons_wrap.php {
        bottom: 50px;
        left: 0;
    }
    .lets_Help .services_icons_wrap .icons_wrap.bootstrap {
        bottom: 65px;
        left: 90px;
    }
    .lets_Help .services_icons_wrap .icons_wrap.node_js {
        left: 50px;
    }
    .lets_Help .services_icons_wrap .icons_wrap.apple {
        bottom: 20px;
        left: 30px;
    }
    .lets_Help .services_icons_wrap .icons_wrap.html_5 {
        bottom: 20px;
        left: 0px;
    }
    .lets_Help .services_icons_wrap .icons_wrap.android {
        bottom: 15px;
        left: 70px;
    }
    .lets_Help .services_icons_wrap .icons_wrap.icon_serv_1 {
        top: -15px;
        right: 100px;
    }
    .lets_Help .services_icons_wrap .icons_wrap.icon_serv_3 {
        bottom: 10px;
        right: 30px;
    }
    .lets_Help .services_icons_wrap .icons_wrap.wordpress {
        top: 20px;
        right: 55px;
    }
    .lets_Help .services_icons_wrap .icons_wrap.drupal {
        top: 30px;
        bottom: 0;
        right: 85px;
    }
    .our_clients .our_clients_right_shape_orange {
        top: calc(0px - 70px);
    }
    .our_clients .our_clients_right_shape_orange img {
        width: 30% !important; 
    }
    .digital_service .media {
        flex-direction: column;
    }
    .digital_service .media .content_wrap {
        width: calc(100% - 0px);
    }
    .digital_service .services_list {
        margin: auto;
    }
    .digital_service .services_list > .serv_list_grid{
        padding: 0;
    }
    .digital_service.sec_padding {
        padding: 60px 0;
    }
}

@media (max-width:499.98px) {
    .btnWrap {
        flex-direction: column;
        align-items: center;
    }
    .lets_Help .btnWrap a {
        margin-bottom: 10px;
    }
    .discuss_project .btnWrap a {
        margin-bottom: 10px;
        min-width: 185px;
    }
    .bannerImage_Text .btnWrap a {
        justify-content: center;
    }
}

@media only screen and (max-width:1150px)
{
    
}

@media only screen and (max-width:1024px)
{
    /* .new-build-wrapper .new-build-three-flex-wrapper {
        display: none;
    } */
    .web-dev-passion-right {
        display: none;
    }
    .web-mobile-icons-wrap
    {
        display: block;
    }
    .web-dev-passion-left {
        width: 100%;
        margin-bottom: 40px;
    }
    .web-dev-passion-main .web-dev-passion-left a {
        margin-left: 10px;
    }
    
    .web-dev-passion-main .web-dev-passion-left h2
    {
        text-align: center !important;
    }
    .web-dev-passion-main .web-dev-passion-left p
    {
        text-align: center !important;
    }
    .web-dev-passion-main .web-dev-passion-left .btnWrap {
        justify-content: center;
    }
    .web-mobile-icons-wrap .slick-list {overflow: visible;}
}

@media only screen and (max-width:767px)
{

    .bannerImage_Text
    {
        height: 45vh;
    }
    .bannerTextWrap .title {
        font-size: 17px;
    }
    
    .bannerTextWrap p {
        display: none;
    }
    
    .bannerTextWrap a:nth-child(2) {
        display: none;
    }
    
    .bannerTextWrap a {
        margin: 20px;
        font-size: 14px;
        padding: 8px 10px;
    }
    
    .btnWrap a span.material-icons {
        font-size: 17px !important;
        line-height: 15px !important;
    }

    .bannerTextWrap .title span:first-child {
        font-weight: 300;
    }

    .bannerTextWrap .title span {
        line-height: 2 !important;
    }
    
    .new-build-wrapper .head_title {
        font-size: 28px !important;
        letter-spacing: 1px !important;
    }
    
    .new-build-wrapper p {
        font-size: 13px !important;
        line-height: 1.6 !important;
    }
    .new-build-wrapper .new-build-three-flex-wrapper {
        display: none;
    }

    .maingray {
        padding: 30px 0 80px;
    }
    .web-dev-passion-main .web-dev-passion-left h2 {
        font-size: 28px !important;
        letter-spacing: 1px !important;
        text-align: center !important;
        margin: 0 0 5px 0 !important;
    }

    .web-dev-passion-main .web-dev-passion-left p {
        text-align: center !important;
        padding: 0;
    }

    .web-dev-passion-right {
        display: none;
    }
    .new-lets-discuss-our-project {
        display: none;
    }
    .web-mobile-icons-wrap {
        padding: 10px 0 0;
    }
    .testimonials_2_layout .testimonial_list aside {
        margin: 50px 0;
        padding: 0 10px 20px;
    }
    .testimonials_2_layout .testimonial_list aside {
        margin: 50px 0;
        padding: 0 10px 20px;
    }
    
    .our_clients.sec_padding {
        padding: 30px 0;
    }
    
    .head_paragraph.desktop {
        display: none;
    }
    .head_paragraph.mobile {
        display: block;
    }
    .web-mobile-icons-wrap
    {
        display: block;
    }
    .our_clients h2 {
        font-size: 28px !important;
        margin: 0 !important;
        line-height: 1 !important;
    }
    .our_clients  p {
        font-size: 12px !important;
        margin: 5px 0 0 0 !important;
    }
    .finest-project h3 {
        font-size: 28px !important;
        line-height: 1 !important;
        margin: 0 0 6px !important;
    }
    
    .finest-project a.all-category {
        display: none;
    }
    
    .finest-project ul.slick-dots li {
        display: none;
    }
    .our_clients .clients_logo
    {
        margin-top: 15px;
    }
    .testimonials_2_layout {
        padding: 30px 0;
    }
    
    .testimonials_2_layout .small_head_title {
        display: none;
    }
    
    .testimonials_2_layout h3 {
        font-size: 28px !important;
        margin: 0 !important;
    }
    
    .testimonials_2_layout .testimonials_wrapping {
        padding: 25px 0 0 0;
    }
    
    .testimonials_2_layout .testimonial_list aside h3 {
        font-size: 15px !important;
    }
    
    .testimonials_2_layout .testimonial_list aside p {
        font-size: 12px !important;
    }
    .testimonials_2_layout .testimonial_list aside {
        margin-bottom: 10px;
    }
    .footer_main .footer_top {
        display: none;
    }
    .number_count h2 {
        font-size: 28px !important;
        line-height: 1.2 !important;
        margin: 0 0 30px 0 !important;
    }
    .number_count .count_list .media {
        max-width: 100%;
        margin: 0 auto;
    }
    
    .years-icon {justify-content: center;}
    
    .number_count .count_list .media .count_text_box {
        text-align: center;
    }
    .number_count .count_list .media .count_text_box h3 {
        font-size: 22px;
    }
    .number_count .count_list > .lists_grid
    {
        padding-top: 0px;
        flex-basis: 50%;
    }
    img.mobile-year-image {
        display: block;
    }
    .years-icon img {
        display: none;
    }

    .slick-center.slick-current .hexa-box:before, .slick-center.slick-current .hexa-box:after {background-color: #e05640;
        border-color: #fff;}
    
    .slick-center.slick-current .hexagonal_shape .hexa-box {
        background: #e05640;
        border-color: #fff;
    }
    
     .slick-center.slick-current .hexagonal_shape .hexa-box img {
        display: none;
    }
    
    .slick-center.slick-current .hexagonal_shape .hexa-box img.withHoverImg {
        display: block;
    }
    
}

@media (max-width: 575.98px) {    
    .slider__slide-subheading::before, .slider__slide-subheading::after {
        width: 35px;
    }
    .slider__slide-subheading {
        font-size: 15px;
        margin-bottom: 10px;
    }
    .slider__slide-heading, .slider__slide-content .title-wrapper i {
        font-size: 18px;
    }
    .slider__slide-readmore a {
        font-size: 12px !important;
        padding: 10px !important;
    }
    .slider__slide-content .title-wrapper {
        margin-bottom: 15px !important;
    }
    .slider__slide-description {
        display: none;
    }
    .discuss_your_project .hexagonal_shape_wrap .box_hexagonal {
        width: 60px;
        height: 100px;
    }
    .discuss_your_project .hexagonal_shape_wrap .box_hexagonal::before, .discuss_your_project .hexagonal_shape_wrap .box_hexagonal::after {
        width: 72px;
        height: 72px;
    }
    .discuss_your_project .hexagonal_shape_wrap .box_hexagonal img {
        width: 40px;
        height: 40px;
    }
    .discuss_your_project .listing_wrap ul li .content_wrap {
        width: 100%;
        text-align: center !important;
    }
    .discuss_your_project .listing_wrap ul li .hexagonal_shape_wrap {
        display: none;
    }
    .discuss_your_project .listing_wrap ul li:nth-child(odd) .content_wrap .head_title, .discuss_your_project .listing_wrap ul li:nth-child(odd) .content_wrap .head_paragraph , .discuss_your_project .listing_wrap ul li:nth-child(even) .content_wrap .head_title, .discuss_your_project .listing_wrap ul li:nth-child(even) .content_wrap .head_paragraph{
        text-align: center !important;
    }
    .discuss_your_project .listing_wrap ul li .content_wrap .img-wrapper-mobile .image-circle {
        background: #e05640;
        display: inline-flex;
        display: -ms-flexbox;
        align-items: center;
        justify-content: center;
        padding: 10px;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        margin-bottom: 6px;
    }
    .discuss_your_project .listing_wrap ul li .content_wrap .img-wrapper-mobile .image-circle img {
        width: 30px;
        vertical-align: middle;
        height: 30px;
    }
    .discuss_your_project .listing_wrap ul .yellow .content_wrap .img-wrapper-mobile .image-circle {
        background: #f2a139;
    }
    .discuss_your_project .listing_wrap ul .blue .content_wrap .img-wrapper-mobile .image-circle {
        background: #3177d2;
    }
    .discuss_your_project .listing_wrap ul .green .content_wrap .img-wrapper-mobile .image-circle {
        background: #5fcfb2;
    }
    .discuss_your_project .listing_wrap ul .red .content_wrap .img-wrapper-mobile .image-circle {
        background: #db2e29;
    }
}
@media (max-width: 480px) {
    .slider {
        height: 45vh !important;
    }
    .slick-next:before {
        background-position: center right -52px;
    }
}
  

/*****Responsive End****/
  