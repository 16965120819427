
@font-face {
font-family: 'Material Icons';
font-style: normal;
font-weight: 400;
src: url(../fonts/Material_Icons/MaterialIcons-Regular.eot); /* For IE6-8 */
src: url(../fonts/Material_Icons/MaterialIcons-Regular.woff2) format('woff2'),
     url(../fonts/Material_Icons/MaterialIcons-Regular.woff) format('woff'),
     url(../fonts/Material_Icons/MaterialIcons-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Josefin Sans';
    src: url('../fonts/Josefin_Sans/JosefinSans-Bold.eot');
    src: url('../fonts/Josefin_Sans/JosefinSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Josefin_Sans/JosefinSans-Bold.woff2') format('woff2'),
        url('../fonts/Josefin_Sans/JosefinSans-Bold.woff') format('woff'),
        url('../fonts/Josefin_Sans/JosefinSans-Bold.ttf') format('truetype'),
        url('../fonts/Josefin_Sans/JosefinSans-Bold.svg#JosefinSans-Bold') format('svg');
    font-weight: 800;
    font-style: normal;
}


@font-face {
    font-family: 'Josefin Sans';
    src: url('../fonts/Josefin_Sans/JosefinSans-SemiBold.eot');
    src: url('../fonts/Josefin_Sans/JosefinSans-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Josefin_Sans/JosefinSans-SemiBold.woff2') format('woff2'),
        url('../fonts/Josefin_Sans/JosefinSans-SemiBold.woff') format('woff'),
        url('../fonts/Josefin_Sans/JosefinSans-SemiBold.ttf') format('truetype'),
        url('../fonts/Josefin_Sans/JosefinSans-SemiBold.svg#JosefinSans-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Josefin Sans';
    src: url('../fonts/Josefin_Sans/JosefinSans-Light.eot');
    src: url('../fonts/Josefin_Sans/JosefinSans-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Josefin_Sans/JosefinSans-Light.woff2') format('woff2'),
        url('../fonts/Josefin_Sans/JosefinSans-Light.woff') format('woff'),
        url('../fonts/Josefin_Sans/JosefinSans-Light.ttf') format('truetype'),
        url('../fonts/Josefin_Sans/JosefinSans-Light.svg#JosefinSans-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Josefin Sans';
    src: url('../fonts/Josefin_Sans/JosefinSans-Regular.eot');
    src: url('../fonts/Josefin_Sans/JosefinSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Josefin_Sans/JosefinSans-Regular.woff2') format('woff2'),
        url('../fonts/Josefin_Sans/JosefinSans-Regular.woff') format('woff'),
        url('../fonts/Josefin_Sans/JosefinSans-Regular.ttf') format('truetype'),
        url('../fonts/Josefin_Sans/JosefinSans-Regular.svg#JosefinSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat/Montserrat-Regular.eot');
    src: url('../fonts/Montserrat/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat/Montserrat-Regular.woff2') format('woff2'),
        url('../fonts/Montserrat/Montserrat-Regular.woff') format('woff'),
        url('../fonts/Montserrat/Montserrat-Regular.ttf') format('truetype'),
        url('../fonts/Montserrat/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-Medium.eot');
    src: url('../fonts/Poppins/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-Medium.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-Medium.woff') format('woff'),
        url('../fonts/Poppins/Poppins-Medium.ttf') format('truetype'),
        url('../fonts/Poppins/Poppins-Medium.svg#Poppins-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-ExtraBold.eot');
    src: url('../fonts/Poppins/Poppins-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-ExtraBold.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-ExtraBold.woff') format('woff'),
        url('../fonts/Poppins/Poppins-ExtraBold.ttf') format('truetype'),
        url('../fonts/Poppins/Poppins-ExtraBold.svg#Poppins-ExtraBold') format('svg');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-SemiBold.eot');
    src: url('../fonts/Poppins/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-SemiBold.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-SemiBold.woff') format('woff'),
        url('../fonts/Poppins/Poppins-SemiBold.ttf') format('truetype'),
        url('../fonts/Poppins/Poppins-SemiBold.svg#Poppins-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-Regular.eot');
    src: url('../fonts/Poppins/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-Regular.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-Regular.woff') format('woff'),
        url('../fonts/Poppins/Poppins-Regular.ttf') format('truetype'),
        url('../fonts/Poppins/Poppins-Regular.svg#Poppins-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Saira Extra Condensed';
    src: url('../fonts/Saira_Extra_Condensed/SairaExtraCondensed-Regular.eot');
    src: url('../fonts/Saira_Extra_Condensed/SairaExtraCondensed-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Saira_Extra_Condensed/SairaExtraCondensed-Regular.woff2') format('woff2'),
        url('../fonts/Saira_Extra_Condensed/SairaExtraCondensed-Regular.woff') format('woff'),
        url('../fonts/Saira_Extra_Condensed/SairaExtraCondensed-Regular.ttf') format('truetype'),
        url('../fonts/Saira_Extra_Condensed/SairaExtraCondensed-Regular.svg#SairaExtraCondensed-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Saira Extra Condensed';
    src: url('../fonts/Saira_Extra_Condensed/SairaExtraCondensed-Bold.eot');
    src: url('../fonts/Saira_Extra_Condensed/SairaExtraCondensed-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Saira_Extra_Condensed/SairaExtraCondensed-Bold.woff2') format('woff2'),
        url('../fonts/Saira_Extra_Condensed/SairaExtraCondensed-Bold.woff') format('woff'),
        url('../fonts/Saira_Extra_Condensed/SairaExtraCondensed-Bold.ttf') format('truetype'),
        url('../fonts/Saira_Extra_Condensed/SairaExtraCondensed-Bold.svg#SairaExtraCondensed-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Saira Extra Condensed';
    src: url('../fonts/Saira_Extra_Condensed/SairaExtraCondensed-SemiBold.eot');
    src: url('../fonts/Saira_Extra_Condensed/SairaExtraCondensed-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Saira_Extra_Condensed/SairaExtraCondensed-SemiBold.woff2') format('woff2'),
        url('../fonts/Saira_Extra_Condensed/SairaExtraCondensed-SemiBold.woff') format('woff'),
        url('../fonts/Saira_Extra_Condensed/SairaExtraCondensed-SemiBold.ttf') format('truetype'),
        url('../fonts/Saira_Extra_Condensed/SairaExtraCondensed-SemiBold.svg#SairaExtraCondensed-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
}

::-moz-selection {
    background: rgba(224, 86, 64, 0.86);
    color: #fff;
}
::selection {
    background: rgba(224, 86, 64, 0.86);
    color: #fff;
}



/*****Common Style Start****/
/* html, body {
    overflow-x: hidden;
} */
body {
    overflow-x: hidden;
    padding-right: 0 !important;
    -webkit-text-size-adjust: none;
}
.App {
    padding-top: 73px;
    overflow: hidden;
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
  
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
  
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
  
    /* Support for IE. */
    font-feature-settings: 'liga';
}
  
sup.asterisk {
    color: red;
}
.container_custom {
    max-width: 1310px;
    margin: auto !important;
    padding: 0 15px;
    position: relative;
    z-index: 1;
}
p {
    font-family: 'Poppins', sans-serif !important;
}
a, a:hover {
    text-decoration: none !important;
}
.sec_padding {
    padding: 40px 0;
    overflow: hidden;
    box-sizing: border-box;
}
.head_title{
    font-size: 36px !important;
    color: #33384a !important;
    line-height: 1 !important;
    font-family: 'Saira Extra Condensed', sans-serif !important;
    font-weight: bold !important;
    text-align: center !important;
    width: 100% !important;
    letter-spacing: 0.3px !important;
    margin-bottom: 10px !important;
    text-transform: capitalize;
}
.head_paragraph {
    color: #33384a !important; 
    font-size: 15px !important;
    line-height: 32px !important;
    text-align: center !important;
    margin-bottom: 15px !important;
    font-family: poppins;
}
.head_title.leftalign {
    text-align: left !important;
}
.head_paragraph.leftalign {
    text-align: left !important;
}
span {
    line-height: initial !important;
}
.white_clr{
    color:#ffffff !important;
}
main {
    overflow: hidden;
    position: relative;
}
.btnWrap{
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    justify-content: center;
}
.btnWrap a.view_work {
    border-color: #fff;
    color: #fff;
    border: 1px solid #fff;
}
.btnWrap a.free_quote {
    /* background-color: #e05640; */
    border-color: #e05640;
    border: 1px solid #e05640;
    color: #fff;
}
.mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
}
.btnWrap a.free_quote:hover {
    /* background-color: #00000000; */
    border-color: #fff;
    /* color: #ffff;
    border: 1px solid #fff; */
}
.btnWrap a {
    margin: 0 10px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    padding: 14px 40px;
    line-height: 22px;
    font-size: 14px;
    transition: all 0.5s ease; 
    border-radius: 4px;
    text-transform: capitalize;
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    align-items: center;
    letter-spacing: 0.4px;
}
.bg_img_wrap {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.red_color_text {
    color: #dc302d !important;
}
.pt-0 {
    padding-top: 0 !important; 
}
.d-none {
    display: none !important;
}
[class|=MuiTouchRipple],
.header_main header .menu_wrap>li span.jss249 {
    display: none !important;
}
.text-center {
    text-align: center;
}
.text-left {
    text-align: left !important;
}
.text-right {
    text-align: right !important;
}
.text-uppercase {
    text-transform: uppercase;
}
.pt-30 {
    padding-top: 30px;
}
.pr-30 {
    padding-right: 30px;
}
.max-width-767 {
    max-width: 767px;
    margin: 0 auto !important;
}
.max-width-991 {
    max-width: 991px;
    margin: 0 auto !important;
}
.max-width-1199 {
    max-width: 1199px;
    margin: 0 auto !important;
}
.max-width-1024 {
    max-width: 1024px;
    margin: 0 auto !important;
}
img {
    max-width: 100%;
}
.ReactModalPortal {
    position: relative;
    z-index: 1100;
}
.mb-0 {
    margin-bottom: 0 !important;
}
.mb-1 {
    margin-bottom: 10px !important;
}
.mb-2 {
    margin-bottom: 20px !important;
}
.mb-3 {
    margin-bottom: 30px !important;
}
.mb-4 {
    margin-bottom: 40px !important;
}
.mb-5 {
    margin-bottom: 50px !important;
}
.btnDownloadWrap > a {
    margin: 5px;
    display: inline-block;
}
.btnDownloadWrap > a img {
    max-height: 42px;
}
.min-height-auto {
    min-height: auto !important;
}
.ml-0 {
    margin-left: 0 !important;
}
.max-width-100 {
    max-width: 100% !important;
}
.hover-btn-animation-out, .hover-btn-animation-in {
    position: relative;
    overflow: hidden;
}
.hover-btn-animation-out::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #e05640;
    z-index: -1;
    transition: transform 0.4s, -webkit-transform 0.4s;
    transform: scale(0);
}
.hover-btn-animation-out:hover::before {
    transform: scale(1);
}
.hover-btn-animation-in::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #e05640;
    z-index: -1;
    transition: transform 0.4s, -webkit-transform 0.4s;
    transform: scale(1);
}
.hover-btn-animation-in:hover::before {
    transform: scale(0);
}
form span.text-danger {
    display: block;
    margin-top: -8px;
    font-size: 14px;
    margin-bottom: 12px;
}
.chat-app-widget .rcw-widget-container {
    z-index: 999;
    left: 0;
    right: inherit;
    margin: 20px;
}
.chat-app-widget .rcw-opened button.rcw-launcher {
    align-self: flex-start;
}
.back-to-top {
    position: fixed;
    z-index: 999;
    bottom: 0;
    right: 0;
    background-color: #e05640;
    margin: 20px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
    transition: 0.5s;
}
.back-to-top i {
    font-size: 32px;
}
.back-to-top.show {
    visibility: visible;
    opacity: 1;
}
li strong {
    font-weight: 600;
}
/*****Common Style End****/



/****InnerPagesBanner Style Start*****/
.innerpagesbanner {
    height: 190px;
    overflow: hidden;
    background-image: none !important;
    background: #14212D;
}
.innerpagesbanner.bg_img_wrap:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
}
.innerpagesbanner .bannerTextWrap h1 {
    font-size: 43px;
    line-height: 1.3;
    text-transform: capitalize;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}
.innerpagesbanner .bannerTextWrap .btnWrap {
    margin-top: 20px;
}
.innerpagesbanner .bannerTextWrap .btnWrap a.view_work:hover {
    background-color: #e05640;
    border-color: #e05640;
}
/****InnerPagesBanner Style End*****/

/****Two_coloumn_Below_Banner Style Start*****/
.two_coloumn_Below_Banner .left_info {
    max-width: 55%;
    flex-basis: 55%;
}
.two_coloumn_Below_Banner .right_info {
    max-width: 45%;
    flex-basis: 45%;
}
.two_coloumn_Below_Banner .right_info .head_title, .two_coloumn_Below_Banner .right_info p.head_paragraph {
    color: #fff !important;
    text-align: right !important;
}
.head_title.big_title {
    font-size: 156px !important;
    line-height: 137px !important;
    margin-bottom: 10px;
}
.two_coloumn_Below_Banner .right_info .head_title.med_title {
    font-size: 40px !important;
    line-height: 40px !important;
    margin-bottom: 60px;
}
.two_coloumn_Below_Banner .right_info {
    padding: 140px 75px;
    background-color: #073278;
}
.two_coloumn_Below_Banner .left_info {
    padding: 30px 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.two_coloumn_Below_Banner .left_info .head_title {
    text-align: left !important;
    font-size: 50px !important;
    letter-spacing: 0.6px !important;
    margin-bottom: 20px;
}
.two_coloumn_Below_Banner .left_info .head_paragraph {
    text-align: left !important;
    line-height: 29px !important;
    width: 100%;
}

/****Two_coloumn_Below_Banner Style End*****/


/*****Number_Count Section Style Start****/
/* .number_count {
    background-color: #444a60;
} */
.number_count .count_list
{
    margin: 40px 0 0 0;
}
.number_count .count_list .media {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
}
.number_count .count_list .media .icon_box {
    margin-right: 25px;
}
.number_count .count_list .media .icon_box .icon_wrap {
    border: 1px solid #808490;
    border-radius: 50%;
    width: 84px;
    height: 84px;
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    justify-content: center;
    align-items: center;
}
.number_count .count_list .media .count_text_box h3 {
    font-family: 'Poppins', sans-serif;
    font-size: 40px;
    color: #333333;
    margin-bottom: 6px;
    text-align: center;
    font-weight: 600;
}
.number_count .count_list .media .count_text_box p {
    font-weight: 600;
    text-transform: capitalize;
    font-size: 15px;
    color: #808596;
}
.number_count .count_list > .lists_grid {
    padding: 20px;
}
.years-icon img {
    padding-right: 10px;
}

.years-icon {
    position: relative;
    padding: 10px 0 0 0;
    display: flex;
    align-items: center;
}

/*****Number_Count Section Style End****/

/*****Why_hire_us Section Style Start****/
.why_hire_us .lists_hire_us {
    list-style: none;
    padding: 0;
}
.why_hire_us ul.left_list li {
    text-align: right;
    padding-right: 30px;
}
.why_hire_us .lists_hire_us li {
    position: relative;
    color: #656c7f;
    font-size: 15px;
    line-height: 23px;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 15px;
}
.why_hire_us ul.left_list li img.tick_done {
    position: absolute;
    top: 4px;
    right: 0;
}
.why_hire_us ul.right_list li {
    padding-left: 30px;
}
.why_hire_us ul.right_list li img.tick_done {
    position: absolute;
    top: 4px;
    left: 0;
}
.why_hire_us img {
    max-width: 380px;
    margin: 0 auto;
    display: block;
}
.why_hire_us.sec_padding {
    padding-bottom: 0;
}
.why_hire_us .center_img {
    padding-top: 40px;
}
/*****Why_hire_us Section Style End****/

/*****Main_keybenefits Section Style Start****/

.key_benfits_and_why_choose.sec_padding.topkeybenefits {
    padding-top: 0px;
    padding-bottom: 0px;
}
.key_benfits_and_why_choose .title_row aside {
    margin-bottom: 70px;
}
.key_benfits_and_why_choose .title_row aside .head_title {
    margin-bottom: 20px;
    text-transform: capitalize;
}
.key_benfits_and_why_choose .key_services_list .list_key_boxes img {
    max-width: 68px;
}
.key_benfits_and_why_choose .key_services_list .list_key_boxes h4 {
    font-family: 'Saira Extra Condensed', sans-serif !important;
    font-size: 25px;
    font-weight: 700;
    color: #333333;
    margin: 15px 0;
}
.key_benfits_and_why_choose .key_services_list .list_key_boxes p {
    font-size: 13px;
    line-height: 23px;
}
.key_benfits_and_why_choose .key_services_list .boxes_key {
    padding: 15px;
    padding-top: 40px;
}
.key_benfits_and_why_choose .key_benefits_left {
    background-color: #151c37;
    padding: 70px 50px;
}
.key_benfits_and_why_choose .key_benefits_left > .head_paragraph {
    margin: 15px 0 50px !important;
}
.key_benfits_and_why_choose .key_benefits_left .head_title {
    color: #fff !important;
    text-align: left !important;
}
.key_benfits_and_why_choose .key_benefits_left .head_paragraph {
    color: #fff !important;
    text-align: left !important;
}
.key_benfits_and_why_choose .key_benefits_left .benfits_key_lists ul {
    padding: 0;
}
.key_benfits_and_why_choose .key_benefits_left .benfits_key_lists li {
    list-style: none;
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
}
.key_benfits_and_why_choose .key_benefits_left .benfits_key_lists li span.material-icons {
    color: #fff !important;
    font-weight: 900 !important;
    font-size: 18px;
    margin-top: 5px;
}
.key_benfits_and_why_choose .key_benefits_left .benfits_key_lists li .s-title {
    color: #fff !important;
    font-size: 18px;
    font-weight: bold;
}
.key_benfits_and_why_choose .key_benefits_left .benfits_key_lists li .head_paragraph {
    color: #bbbbbb !important;
    text-align: left !important;
}
.key_benfits_and_why_choose .key_benefits_left .benfits_key_lists li .content_right {
    padding-left: 10px;
}
.key_benfits_and_why_choose .list_key_boxes {
    padding: 0 10px;
}
.key_benfits_and_why_choose .list_key_boxes img {
    margin-bottom: 20px;
    width: 65px;
    height: 60px;
}
.FourColumnLayout1 .list_key_boxes .icon_wrapper img {
    max-height: 60px;
    width: auto;
    flex-shrink: 0;
    max-width: 200px;
}
.key_benfits_and_why_choose .list_key_boxes .head_title {
    font-size: 25px !important;
    text-align: left !important;
    line-height: 30px !important;
    margin-bottom: 11px;
    text-transform: capitalize;
}
.key_benfits_and_why_choose .list_key_boxes .head_paragraph {
    text-align: left !important;
    color: #666666 !important;
    font-size: 15px !important;
    line-height: 25px !important;
}
.key_benfits_and_why_choose .right_content_wrap .head_title {
    text-align: left !important;
    margin-bottom: 20px;
}
.key_benfits_and_why_choose .right_content_wrap .head_paragraph {
    text-align: left !important;
}
.key_benfits_and_why_choose .right_content_wrap {
    height: 100%;
}
.key_benfits_and_why_choose .right_content_wrap .text_wrapper {
    padding: 200px 100px;
}
.key_benfits_and_why_choose .boxes_key {
    transition: 0.3s;
}
.key_benfits_and_why_choose .boxes_key:hover {
    transform: translateY(10px);
}
/*****Main_keybenefits Section Style End****/


/*****CaseStudy Portfolios Section Style Start****/
.border_bottom_w-10 {
    position: relative;
    padding-bottom: 5px;
}
.border_bottom_w-10::After {
    content: "";
    width: 66px;
    height: 4px;
    background-color: #cdd8e8;
    position: absolute;
    left: 0;
    top: 100%;
}
.CaseStudyPortfolios {
    background-size: inherit;
    background-position: bottom right;
    background-repeat: no-repeat;
    background-color: #fff;
}
.CaseStudyPortfolios .caseStudy_info .head_title.big_title {
    margin-bottom: 50px;
    margin-left: -3px;
}
.CaseStudyPortfolios .caseStudy_info .head_paragraph.description {
    font-size: 15px !important;
    line-height: 27px !important;
}
.CaseStudyPortfolios .caseStudy_info ul.listing_of_portfolios li {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
}
.CaseStudyPortfolios .caseStudy_info ul.listing_of_portfolios li span {
    color: #33384a !important;
    font-size: 16px !important;
    line-height: 32px !important;
    text-transform: capitalize;
    font-weight: bold;
}
.CaseStudyPortfolios .caseStudy_info ul.listing_of_portfolios li p {
    color: #33384a !important;
    font-size: 16px !important;
    line-height: 32px !important;
}
.CaseStudyPortfolios .caseStudy_info {
    padding: 30px;
    padding-top: 0;
    position: relative;
    height: 100%;
    box-sizing: border-box;
    margin-left: 25px;
    background-color: #fff;
}
.CaseStudyPortfolios .caseStudy_info::After {
    content: "";
    width: 100%;
    height: 80%;
    box-shadow: 0px 0px 20px rgba(0,0,0,0.07);
    position: absolute;
    left: 0;
    bottom: 0;
}
.CaseStudyPortfolios .img_wrapper {
    height: 100%;
    text-align: right;
}
.CaseStudyPortfolios .img_wrapper img {
    width: 100%;
    vertical-align: bottom;
}
.CaseStudyPortfolios .caseStudy_info aside {
    position: relative;
    z-index: 1;
}
.CaseStudyPortfolios .slide_item:focus {
    outline: none !important;
}
.CaseStudyPortfolios .slick-dots li button:before {
    font-size: 12px;
}
.CaseStudyPortfolios .slick-dots li.slick-active button:before,
.CaseStudyPortfolios .slick-dots li button:hover:before {
    color:#dc312d;
}
.CaseStudyPortfolios .slick-dots {
    bottom: -45px;
}
/*****CaseStudy Portfolios Section Style End****/


/*****KeyBenefitsTopLayout2 Section Style Start****/
.key_benfits_layout2_and_why_choose {
    background-repeat: no-repeat;
    background-size: contain;
}
.key_benfits_layout2_and_why_choose .title_row .head_title.big_title {
    font-size: 106px !important;
    line-height: 92px !important;
}
.key_benfits_layout2_and_why_choose .title_row .head_title {
    font-size: 62px !important;
}
.background-red {
    background-color: #dc302d;
}
.red_box_layout_listing aside::before {
    content: "";
    background-image: url(../images/MernStackDevelopment/and_icon_red_big.png);
    width: 236px;
    height: 275px;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    bottom: 95%;
    right: 0;
}
.red_box_layout_listing aside {
    max-width: 625px;
    margin-left: auto;
    padding: 85px 15px;
    position: relative;
}
.red_box_layout_listing .listing_of_portfolios li {
    padding-left: 0;
    padding-right: 0;
}
.red_box_layout_listing .listing_of_portfolios li [class|=MuiListItemIcon] {
    margin-right: 0;
}
.red_box_layout_listing .listing_of_portfolios li span {
    color: #fff;
    font-size: 16px !important;
    line-height: 32px !important;
}
.key_benfits_layout2_and_why_choose .title_row aside {
    margin-bottom: 100px;
}   
.red_box_layout_listing aside .icon_three_user_wrap {
    position: absolute;
    top: 100%;
    right: 50px;
    background-color: #fff;
    width: 100px;
    height: 100px;
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    align-items: center;
    justify-content: center;
}
.key_benfits_layout2_and_why_choose .team_description_block aside {
    background-color: #151c37;
    max-width: 590px;
    margin-left: auto;
    padding: 80px;
    box-sizing: border-box;
    margin-right: 100px;
    margin-top: -260px;
}
.team_description_block aside .head_title {
    color: #fff !important;
    margin-bottom: 20px;
}
.team_description_block aside .head_paragraph {
    color: #fff !important;
}
.text_uppercase {
    text-transform: uppercase;
}
.key_benfits_layout2_and_why_choose .why_visions_layout2 .head_title.big_title {
    letter-spacing: 40px !important;
}
.key_benfits_layout2_and_why_choose .why_visions_layout2 aside section {
    max-width: 1070px;
}
.key_benfits_layout2_and_why_choose .why_visions_layout2 > div {
    background-size: contain;
    background-repeat: no-repeat;
    padding-top: 100px;
    padding-bottom: 50px;
}
.key_benfits_layout2_and_why_choose .why_visions_layout2 aside section article {
    background-color: #dc302d;
    position: relative;
    max-width: 600px;
    margin-left: auto;
    padding: 70px;
    padding-bottom: 100px;
    box-sizing: border-box;
}
.key_benfits_layout2_and_why_choose .why_visions_layout2 aside section article .icon_three_user_wrap {
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: #fff;
    width: 100px;
    height: 100px;
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
}
.key_benfits_layout2_and_why_choose .why_visions_layout2 aside section article .head_title {
    color: #fff !important;
    margin-bottom: 20px;
}
.key_benfits_layout2_and_why_choose .why_visions_layout2 aside section article .head_paragraph {
    color: #fff !important;
}
.key_benfits_layout2_and_why_choose .top_bg_img {
    background-repeat: no-repeat;
    background-size: cover;
}
[class|=MuiListItem] {
    align-items: flex-start !important;
}
[class|=MuiListItem] > [class|=MuiListItemIcon] {
    margin-top: 7px;
}
/*****KeyBenefitsTopLayout2 Section Style End****/


/*****Strategy Implementation Section Style Start****/
.strategy_implement {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 80% 0;
    margin-bottom: 30px;
    overflow: visible;
    position: relative;
}
.owl_curve_before_img {
    position: relative;
}
.owl_curve_before_img::before {
    content: "";
    background-image: url(../images/ReactNativeDevelopment/owl_curve_orange.png);
    background-repeat: no-repeat;
    background-size: contain;
    width: 300px;
    height: 540px;
    position: absolute;
    right: 0;
    top: -150px;
}
.pb-0 {
    padding-bottom: 0px !important;
}
.strategy_implement .img_wrapper {
    text-align: right;
}
.strategy_implement .img_wrapper img {
    transform: translateY(30px);
    width: 100%;
    max-width: 550px;
    vertical-align: bottom;
}
.strategy_implement .text_wrap {
    height: 100%;
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    align-items: center;
    padding-left: 30px;
}
.strategy_implement .text_wrap .head_title {
    margin-bottom: 30px;
}
.strategy_implement .text_wrap ul {
    list-style: none;
    padding-left: 25px;
}
.strategy_implement .text_wrap ul .head_paragraph {
    text-align: left !important;
    font-size: 14px !important;
    line-height: 1.6 !important;
    font-weight: 500;
    position: relative;
}
.strategy_implement .text_wrap ul .head_paragraph > svg {
    position: absolute;
    left: calc(0px - 25px);
    top: 2px;
    width: 16px;
    height: 20px;
}
/*****Strategy Implementation Section Style End****/


/*****Three Column Layout1 Section Style Start****/
.three_column_layout1 .list_wrapper .media {
    text-align: center;
    margin-bottom: 50px;
}
.three_column_layout1 .list_wrapper .media .img_wrapper {
    min-width: 75px;
    display: inline-block;
    position: relative;
}
.three_column_layout1 .list_wrapper .media .img_wrapper::before {
    content: "";
    width: 100%;
    height: 100%;
    max-width: 75px;
    max-height: 75px;
    background-color: #e25957;
    position: absolute;
    border-radius: 50%;
    left: -30px;
    top: 10px;
}
.three_column_layout1 .list_wrapper .media img {
    position: relative;
    z-index: 1;
    max-width: 75px;
    max-height: 75px;
}
.three_column_layout1 .list_wrapper .content .head_title {
    font-size: 30px !important;
    line-height: 63px !important;
}
.three_column_layout1 .list_wrapper .content .head_paragraph {
    font-size: 13px !important;
    line-height: 23px !important;
    color: #666 !important;
}
.three_column_layout1 .padding_custom {
    padding: 25px 60px;
}
/*****Three Column Layout1 Section Style End****/


/*****Magento Dev Section Style Start****/
.main_magento_dev .strategy_implement {
    margin-bottom: 0;
}
.main_magento_dev .strategy_implement .img_wrapper img {
    transform: inherit;
}
.main_magento_dev .owl_curve_before_img::before {
    content: "";
    background-image: url(../images/MagentoDevelopment/owl_curve_blue.png);
}
.white_text {
    color:#fff !important;
}
.main_magento_dev .strategy_implement .img_wrapper {
    height: 100%;
    display: flex;
    display: -webkit-flex;
    display: -o-flex;
    display: -ms-flex;
    display: -moz-flex;
    align-items: flex-end;
}
.main_magento_dev .strategy_implement aside {
    padding-bottom: 15px;
}
/*****Magento Dev Section Style End****/


/*****Explore Projects Section Style Start****/
.explore_projects {
    position: relative;
}
.explore_projects.sec_padding {
    padding-bottom: 200px;
    background-size: cover;
}
.explore_projects::After {
    content: "";
    background-image: url(../images/MagentoDevelopment/explore_projects_below_curve.png);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 200px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
}
.explore_projects .text_wrap {
    max-width: 991px;
    margin: 0 auto;
}
.explore_projects .btnWrap .hire_developers {
    background-color: #151c37;
}
.explore_projects .btnWrap .hire_developers:hover {
    color: #151c37 !important;
    background-color: #fff;
}
.explore_projects .head_title {
    margin-bottom: 20px;
}
.explore_projects .head_paragraph {
    margin-bottom: 40px !important;
}
/*****Explore Projects Section Style End****/

/*****Two Column Layout1 Section Style Start****/
.TwoColumnLayout1 .media {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    margin-bottom: 30px;
}
.TwoColumnLayout1 .media .icon_box {
    width: 150px;
    text-align: center;
    margin-right: 15px;
    margin-bottom: 15px;
}
.TwoColumnLayout1 .media .count_text_box {
    width: calc(100% - 150px);
}
.TwoColumnLayout1 .media .count_text_box p.small_title {
    color: #ed402c;
    font-size: 17px;
    font-weight: 600;
}
.TwoColumnLayout1 .media .count_text_box h3 {
    font-family: 'Poppins', sans-serif !important;
    font-size: 40px;
    line-height: 48px;
    margin: 5px 0 10px;
    color: #33384a;
    font-weight: bold;
}
.TwoColumnLayout1 .media .count_text_box p {
    font-size: 13px ;
    line-height: 25px ;
    color: #33384a ;
}
.TwoColumnLayout1 .media .icon_box img {
    max-width: 105px;
    width: 100%;
}
/*****Two Column Layout1 Section Style End****/

/*****FourColumnLayout1 Section Style Start****/
.FourColumnLayout1 {
    background-color: #151c37;
    padding-bottom: 120px;
}
.FourColumnLayout1 .title_row {
    width: 100%;
    margin-bottom: 30px;
}
.FourColumnLayout1 .title_row aside .head_title {
    margin-bottom: 20px;
    text-transform: capitalize;
}
.FourColumnLayout1 .list_key_boxes {
    background-color: #31354d;
    border-radius: 15px;
    padding: 15px;
    position: relative;
}
.FourColumnLayout1 .list_key_boxes .icon_wrapper {
    text-align: center;
    padding: 15px 0;
}
.FourColumnLayout1 .list_key_boxes .head_title {
    font-family: 'Poppins', sans-serif !important;
    font-size: 19px !important;
    text-transform: capitalize;
    line-height: 32px !important;
    margin-bottom: 10px;
    font-weight: 600 !important;
}
.FourColumnLayout1 .list_key_boxes .head_paragraph {
    font-size: 14px !important;
    line-height: 23px !important;
    color: #b9bbc9 !important;
}
.FourColumnLayout1 .list_key_boxes .head_paragraph:last-child {
    margin: 0 !important;
}
.FourColumnLayout1 .boxes_key {
    margin-bottom: 25px;
}
.FourColumnLayout1 .list_key_boxes::After {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0px;
    border-style: solid;
    border-color: #31354d transparent transparent transparent;
    border-width: 15px;
}
.FourColumnLayout1 .list_key_boxes::before {
    content: "";
    width: 20px;
    height: 20px;
    border: 2px solid #fff;
    border-radius: 50%;
    background-color: #151c37;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: calc(100% + 50px);
    z-index: 1;
}
.FourColumnLayout1 .boxes_wrapper {
    position: relative;
}
.FourColumnLayout1 .boxes_wrapper::After {
    content: "";
    width: 100vw;
    border: 1px dashed #31354d;
    position: absolute;
    top: calc(100% + 37px);
    left: 50%;
    transform: translate(-50%);
}
.main_ourTeam .FourColumnLayout1 .boxes_wrapper::after {
    top: calc(100% + 41px);
}
.FourColumnLayout1 .boxes_key {
    padding: 0 10px;
}
.border_color_green,
.border_color_green::before {
    border-color: #4f9935 !important;
}
.border_color_pink,
.border_color_pink::before {
    border-color: #913599 !important;
}
.border_color_yellow,
.border_color_yellow::before {
    border-color: #959538 !important;
}
.border_color_red,
.border_color_red::before {
    border-color: #993535 !important;
}

/*****FourColumnLayout1 Section Style End****/

/*****FourColumnLayout2 Section Style Start****/
.FourColumnLayout2 {
    background-color: #151c37;
}
.FourColumnLayout2 .title_row {
    width: 100%;
    margin-bottom: 30px;
}
.FourColumnLayout2 .title_row aside {
    max-width: 1024px;
    margin: 0 auto;
}
.FourColumnLayout2 .title_row aside .head_title {
    margin-bottom: 20px;
}
.FourColumnLayout2 .list_key_boxes .icon_wrapper {
    text-align: center;
    padding: 25px 0;
}
.FourColumnLayout2 .list_key_boxes .head_paragraph {
    font-size: 19px !important;
}
.FourColumnLayout2 .btnWrap {
    width: 100%;
    margin-top: 50px;
}
.FourColumnLayout2 .btnWrap a {
    background-color: #ed402c;
    color: #fff;
    font-weight: 400;
    font-size: 14px;
    padding: 14px 20px;
    border: 1px solid transparent;
}
.FourColumnLayout2 .btnWrap a:hover {
    border-color: #fff;
    background-color: transparent;
}
.FourColumnLayout2 .boxes_wrapper .boxes_key {
    padding: 0 20px 20px;
}
.FourColumnLayout2 .list_key_boxes .icon_wrapper img {
    max-height: 100px;
}
/*****FourColumnLayout2 Section Style End****/

/*****FourColumnLayout3 Section Style Start****/
.FourColumnLayout3 {
    position: relative;
    overflow: visible;
    padding-bottom: 0;
    background: url('../images/WebsiteDesignDevelopment/white-curve.png');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
}
/* .FourColumnLayout3::after {
    content: "";
    background-image: url('../images/WebsiteDesignDevelopment/webdesign_curve_bottom_bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 300px;
    position: absolute;
    top: 100%;
    opacity: 0;
} */
.FourColumnLayout3 .boxes_wrapper {
    margin-bottom: 250px;
}
.after_bottom_curve_opacity-0::after {
    opacity: 0;
}
.FourColumnLayout3.owl_curve_before_img::before {
    content: "";
    z-index: 1;
    width: 250px;
    bottom: -280px;
    top: inherit;
}
.FourColumnLayout3 .title_row {
    width: 100%;
    margin-bottom: 30px;
}
.FourColumnLayout3 .title_row aside {
    margin: 0 auto;
}
.FourColumnLayout3 .title_row aside .head_title {
    margin-bottom: 20px;
}
.FourColumnLayout3 .list_key_boxes .icon_wrapper {
    height: 80px;
    width: 150px;
    display: flex;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    align-items: center;
    padding-bottom: 15px;
}
.FourColumnLayout3 .list_key_boxes .head_title {
    font-size: 24px !important;
    text-transform: capitalize;
    line-height: 32px !important;
    margin-bottom: 10px;
    font-weight: 600 !important;
}
.FourColumnLayout3 .list_key_boxes .head_paragraph {
    color: #666666 !important;
    font-size: 15px !important;
    line-height: 25px !important;
}
.FourColumnLayout3 .boxes_wrapper .boxes_key {
    padding: 0 10px 20px;
}
.FourColumnLayout3 .list_key_boxes .icon_wrapper img {
    max-width: 65px;
}
.FourColumnLayout3.four-mern-section .list_key_boxes .icon_wrapper img {
    max-width: 100%;
    width: 100%;
}
.FourColumnLayout3 .boxes_wrapper .boxes_key {
    transition: 0.3s;
}
.FourColumnLayout3 .boxes_wrapper .boxes_key:hover {
    transform: translateY(10px);
}
/*****FourColumnLayout3 Section Style End****/

/*****AppDevProcessFlow1 Section Style Start****/
.app_dev_process_flow1 {
    padding-top: 350px;
}
.timeline_layout1 {
    padding-top: 50px;
}
.timeline_layout1 ul {
    padding: 0;
    margin: 0;
    list-style: none;
}
.timeline_layout1 ul li {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
}
.timeline_layout1 ul li .boxes {
    width: 33.33%;
    height: 400px;
    padding: 0 80px;
    box-sizing: border-box;
}
.timeline_layout1 ul li article .circle_count {
    display: inline-block;
    color: #444a60;
    font-size: 38px;
    font-family: 'Saira Extra Condensed', sans-serif;
    font-weight: bold;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    border: 1px solid #ddd;
    line-height: 90px;
    text-align: center;
    margin-bottom: 10px;
}
.timeline_layout1 ul li article .head_title {
    text-align: left !important;
    font-size: 28px !important;
    color: #444a60 !important;
}
.timeline_layout1 ul li article .head_paragraph {
    text-align: left !important;
    color: #656c7f !important;
    font-size: 14px !important;
    line-height: 26px !important;
    margin-bottom: 0 !important;
}
.timeline_layout1 ul li.button_row article .btnWrap a {
    background-color: #ed402c;
    color: #fff;
    min-width: max-content;
    padding: 14px 26px;
    font-size: 13px !important;
}
.timeline_layout1 ul li .box-middle {
    position: relative;
    border-width: 1px;
    border-style: solid;
    border-color: #ed402c;
}
.timeline_layout1 ul li .icon_wrapper {
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
    width: 90px;
    height: 90px;
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    z-index: 1;
}
.timeline_layout1 ul li .tr-corner {
    right: calc(0px - 45px);
    top: calc(0px - 45px);
}
.timeline_layout1 ul li .br-corner {
    right: calc(0px - 45px);
    bottom: calc(0px - 45px);
}
.timeline_layout1 ul li .l-middle {
    left: calc(0px - 45px);
    top: 50%;
    transform: translateY(-50%);
}
.timeline_layout1 ul li .r-middle {
    right: calc(0px - 45px);
    top: 50%;
    transform: translateY(-50%);
}
.d-flex {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
} 
.align-items-end {
    align-items: flex-end;
    -ms-flex-align: end!important;
}
.align-items-center {
    align-items: center;
    -ms-flex-align: center!important;
}
.timeline_layout1 ul li .box-middle.bl-0 {
    border-left: 0px;
}
.timeline_layout1 ul li .box-middle.br-0 {
    border-right: 0px;
}
.timeline_layout1 ul li .box-middle.bt-0 {
    border-top: 0px;
}
.timeline_layout1 ul li .box-middle.bb-0 {
    border-bottom: 0px;
}
.timeline_layout1 ul li .box-middle.btlradius-40px {
    border-top-left-radius: 40px;
}
.timeline_layout1 ul li .box-middle.bblradius-40px {
    border-bottom-left-radius: 40px;
}
.timeline_layout1 ul li .box-middle.btrradius-40px {
    border-top-right-radius: 40px;
}
.timeline_layout1 ul li.row6.button_row .middle {
    padding-top: 50px;
    width: 100%;
}
.timeline_layout1 ul li.button_row article .btnWrap a {
    position: relative;
}
.timeline_layout1 ul li.button_row article .btnWrap a::before {
    content: "";
    background-image: url('../images/AndroidAppDevelopment/app_process_curly_curve.png');
}
/*****AppDevProcessFlow1 Section Style End****/

/*****ASPdotNetDev Section Style Start****/
.technologies_multi_services_circle {
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
}
.technologies_multi_services_circle .img_wrap img {
    max-width: 600px;
    width: 100%;
}
/*****ASPdotNetDev Section Style End****/

/*****two_coloumn_Below_BannerLayout_2 Section Style Start****/
.two_coloumn_Below_Banner.layout_2.two_coloumn_Below_Banner.layout_2 .right_info h4 {
    color: #fff;
    font-size: 25px;
    font-family: poppins;
    line-height: 1.5;
    margin-bottom: 15px;
}
.two_coloumn_Below_Banner.layout_2 .right_info .img_wrapper img {
    max-width: calc(100% + 150px);
    margin-left: -140px;
    vertical-align: bottom;
}
.two_coloumn_Below_Banner.layout_2 .right_info .img_wrapper {
    text-align: center;
    padding-top: 50px;
}
/*****two_coloumn_Below_BannerLayout_2 Section Style End****/



/**Secure Login Screen Start**/
.secure_login {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 85vh;
    max-width: 500px;
    margin: auto;
    padding: 0 15px;
}
.secure_login * {
    box-sizing: border-box;
}
.secure_login .email {
    width: 100%;
}

.secure_login .password {
    width: 100%;
    margin: 15px 0;
}


.secure_login label {
    display: block;
    margin-bottom: 5px;
    font-family: 'Poppins';
}

.secure_login input {
    display: block;
    width: 100%;
    height: 35px;
    box-shadow: none !important;
    border: 1px solid #ccc;
    padding: 0 10px;
    font-family: 'Poppins';
    font-size: 16px;
}
.secure_login .submit_btn button {
    border: 1px solid #e15641;
    padding: 6px 25px;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 1px;
    background-color: #e15641;
    color: #fff;
    font-weight: 500;
    font-family: 'Poppins';
}
.secure_login .submit_btn button:hover {
    background-color: transparent;
    color: #e15641;
    cursor: pointer;
}
.secure_login input, .secure_login button {
    outline: none;
}
/**Secure Login Screen End**/


/**Post Design Start**/
.post_meta .head_paragraph.small {
    font-size: 12px !important;
    color: #000 !important;
    margin-bottom: 0px !important;
    font-weight: 500;
}
.post_meta .head_paragraph span.posted_date,
.post_meta .head_paragraph span.admin_name {
    color: #7e7e7e;
}
.post_meta {
    position: relative;
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 10px;
    margin-bottom: 25px;
}   
.post_meta::after {
    content: "";
    width: 100px;
    height: 5px;
    background-color: #eaeaea;
    position: absolute;
    bottom: -3px;
}
.post_meta .divider_dash {
    color: #7e7e7e;
    margin: 0 10px;
}
/**Post Design End**/


/**Right Sidebar Start**/
.right_sidebar .block {
    background-color: #f8f6f6;
    padding: 20px;
}
.right_sidebar .block .head_title {
    font-family: poppins !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 1.4 !important;
    padding-bottom: 10px;
    border-bottom: 2px solid #ddd;
}
.right_sidebar .block ul {
    padding-left: 0;
    list-style: none;
    margin-bottom: 0;
    max-width: 350px;   
}
.right_sidebar .block .head_paragraph {
    font-size: 14px !important;
    line-height: 1.5 !important;
    font-weight: 600;
    margin-bottom: 0 !important;
}
.right_sidebar .block a {
    color: #2f2f2f;
    cursor: pointer;
}
.right_sidebar .block a:hover {
    color: #e05640;
}
.right_sidebar .block .post_content {
    margin-bottom: 10px;
}
.recent_post_wrapper ul li {
    display: flex;
    display: -webkit-flex;
    display: -o-flex;
    display: -ms-flex;
    display: -moz-flex;
}
.recent_post_wrapper ul li .image_wrapper {
    flex: 1;
    margin-bottom: 10px;
}
.recent_post_wrapper ul li .post_content {
    padding-left: 15px;
    flex: 3.3;    
}
.recent_post_wrapper ul li .post_content .post_meta .head_paragraph {
    font-size: 12px !important;
    color: #000 !important;
    margin-bottom: 0px !important;
    line-height: 1 !important;
    padding-top: 5px;
    font-weight: 400;
}
.recent_post_wrapper ul li .post_content .post_meta .head_paragraph span.posted_date {
    color: #7e7e7e;
}
.recent_post_wrapper ul li.view_more_post_link a {
    padding: 0;
    margin: 0;
    text-transform: capitalize;
    font-size: 12px;
    color: #3f51b5;
}
.right_sidebar .blog_archive_wrapper .post_content {
    margin-bottom: 20px;
}
.blog_archive_wrapper ul.arrow_right_aside li {
    padding-left: 15px;
    position: relative;
}
.blog_archive_wrapper ul.arrow_right_aside li::before {
    content: "";
    background-image: url(../images/keyboard_arrow_right-24px.svg);
    width: 15px;
    height: 15px;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 18px;
    position: absolute;
    left: 0;
    top: 3px;
}
.right_sidebar .blog_archive_wrapper li:last-child .post_content {
    margin-bottom: 0;
}
.right_sidebar .block:not(:last-child) {
    margin-bottom: 30px;
}
.right_sidebar .block .post_content .post_meta {
    margin-bottom: 0;
    padding-bottom: 0;
    border: 0;
}
.right_sidebar .block .post_content .post_meta::after {
    width: 0;
    height: 0;
    background: transparent;
}
/**Right Sidebar End**/

.boxes_wrapper,
.boxes_listing {
    justify-content: center;
}


/**Banner Designing Start**/
.cloud_based {
    background-color: #151c37;
    position: relative;
}
.cloud_based.sec_padding {
    padding: 100px 0;
}
.cloud_based .left_info {
    padding: 10px 0 50px;
}
.cloud_based .img_wrapper {
    padding-left: 50px;
    text-align: center;
}
.cloud_based .img_wrapper img {
    max-width: 767px;
    width: 100%;
}
.angle_div_skew-10 {
    position: relative;
}
.angle_div_skew-10::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    border-style: solid;
    border-color: transparent;
    border-width: 32vh 100vw;
    border-bottom-color: #fff;
    border-right-color: #fff;
}
.main_banner_design .bottomkeybenefits.angle_div_skew-10 {
    padding-bottom: 250px;
}
.main_banner_design .bottomkeybenefits.angle_div_skew-10::before {
    content: "";
    border-bottom-color: #151c37;
    border-right-color: #151c37;
}
.responsive_animated {
    background-color: #151c37;
    position: relative;
}
.responsive_animated.angle_div_skew-10::before {
    content: "";
    z-index: 2;
}
.major_social_networks .title_row {
    margin-bottom: 30px;
}
.major_social_networks .major_networks_wrap .content_box h5 {
    text-transform: capitalize;
    font-family: poppins;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
}
.major_social_networks .major_networks_wrap .content_box .head_paragraph {
    font-size: 14px !important;
    line-height: 1.8 !important;
    max-width: 85%;
    margin: 0 auto 0 !important;
}
.major_social_networks .major_networks_wrap .content_box {
    padding: 25px;
    text-align: center;
    z-index: 2;
    position: relative;
    background-color: white;
}
.major_social_networks .major_networks_wrap .content_box .icon {
    margin-bottom: 20px;
    width: 65px;
    height: 65px;
}
.major_social_networks .major_networks_wrap .content_box.middle_img {
    padding: 0;
}
.major_social_networks .major_networks_wrap .aside {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    flex-direction: column;
    justify-content: center;
}
.major_social_networks .major_networks_wrap .aside .content_box {
    margin: 60px 0;
}
.responsive_animated .img_wrapper {
    margin-bottom: -180px;
}
/**Banner Designing End**/


/**Broucher Design Start**/
.main_broucher_design .two_coloumn_Below_Banner.layout_2 .right_info {
    padding: 30px;
    padding-top: 70px;
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    flex-direction: column;
    justify-content: flex-end;
}
.two_coloumn_Below_Banner .right_info p.head_paragraph.text-left {
    text-align: left !important;
}
/**Broucher Design End**/


/**Service Page Design***/
/* .services-listing .row-flex {
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    text-align: center;
    overflow: hidden;
 } */
 .services-listing .row-flex .col-flex {
    /* -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1; */
    width: 100%;
 }
 .services-listing .col-flex .img-txt-wrap {
    display: inline-block;
    width: 100%;
 }
 .services-listing .col-flex .img-txt-wrap .img-wrap {
    margin: 0 0 15px 0;
 }
 .services-listing .col-flex .img-txt-wrap p {
    font-family: 'Saira Extra Condensed', sans-serif !important;
    font-size: 20px;
    font-weight: bold;
    color: #444663;
    text-transform: uppercase;
    line-height: 1.2;
 }
 .services-listing .col-flex:not(:last-child) .img-txt-wrap {
    border-right: 1px solid #dedede;
 }
 .services-listing {
    border-bottom: 1px solid #dedede;
    padding: 30px 0;
 }
 .services-detail-content .content-wrapper .head_title {
    margin: 0 0 20px 0;
 }
 /* .services-detail-content .content-wrapper, .services-detail-content .image-wrap {
    margin: 40px 0;
} */
 .services-detail-content .content-wrapper p {
    line-height: 1.7;
    color: #33384a;
    font-size: 15px;
    margin: 0 0 15px 0;
 }
 .services-detail-content .content-wrapper .btnWrap {
    -ms-flex-pack: start;
    justify-content: flex-start;
 }
 .services-detail-content .content-wrapper .btnWrap .btn-service {
    margin: 0 0 0 0;
    text-transform: capitalize;
    font-weight: 400;
    font-size: 14px;
    padding: 13px 40px;
 }
 .services-detail-content .content-wrapper .btnWrap .btn-service:hover {
    color: #e05640;
}
 .services-detail-content .image-wrap {
    padding: 0 0 1.5em 0;
 }
 .services-detail-content .image-wrap img {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    display: block;
 }
 .services-detail-content.grey-color {
    background: #f1f1f1;
 }
 .services-detail-content.blue-color.only-center {
    background: #fff url(../images/services/grey-curve.png);
    padding-bottom: 200px;
    background-position: 0% calc(100% + 1px);
    background-repeat: no-repeat;
    background-size: 100% auto;
    color: #fff;
    position: relative;
    overflow: visible;
 }
 .services-detail-content.blue-color.only-center .content-wrapper {
    margin: 0 auto;
    max-width: 1070px;
    margin-bottom: 15px;
 }
 .services-detail-content.blue-color.only-center::before{
    background-image: url(../images/ReactNativeDevelopment/owl_curve_orange.png);
    content: "";
    position: absolute;
    bottom: -120px;
    right: -70px;
    width: 296px;
    height: 433px;
    background-size: 100%;
    background-repeat: no-repeat;
    z-index: 2;
 }
 .services-detail-content.only-center .content-wrapper .btnWrap {
    -webkit-justify-content: center;
    justify-content: center;
 }
 .services-detail-content.cms-dev .image-wrap img {
    max-width: 350px;
 }
 .services-detail-content .custom-row {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -15px;
 }
 .services-detail-content .custom-row .custom-col-7 {
    -webkit-flex: 0 0 54%;
    flex: 0 0 54%;
    max-width: 54%;
    padding: 0 15px;
 }
 .services-detail-content .custom-row .custom-col-5 {
    -webkit-flex: 0 0 46%;
    flex: 0 0 46%;
    max-width: 46%;
    padding: 0 15px;
 }
 .services-detail-content.app-dev, .services-detail-content.graph-design {
    padding-bottom: 0;
 }
 
 .services-detail-content.app-dev .image-wrap {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
 }
 .services-detail-content.app-dev .image-wrap img {
    position: absolute;
    right: 0;
    width: 860px;
    max-width: unset;
    z-index: -1;
    bottom: -4px;
 }
 .services-detail-content.app-dev .content-wrapper, .services-detail-content.graph-design .content-wrapper{
    margin-bottom: 60px;
 }
 .services-detail-content .custom-row {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -15px;
    -ms-flex-align: center;
    align-items: center;
 }
 .services-detail-content.app-dev .custom-row {
    -ms-flex-align: end;
    align-items: flex-end;
 }
 .services-detail-content.web-design .custom-row .image-wrap img, .services-detail-content.web-design .custom-row .image-wrap img, .services-detail-content.digi-market .image-wrap img {
    margin-left: 0;
    max-width: 470px;
 }
 .services-detail-content.graph-design .image-wrap {
    padding-bottom: 0;
 }
 .main_services * {
    box-sizing: border-box;
 }
 .services-listing .col-flex .img-txt-wrap .img-wrap img {
    height: 40px;
}
.services-detail-content .content-wrapper .tech_icons_wrapper {
    margin-bottom: 5px;
}
.services-detail-content .content-wrapper .tech_icons_wrapper img {
    width: 40px;
    height: 40px;
    vertical-align: middle;
    transition: 0.8s;
}
.services-detail-content .content-wrapper .tech_icons_wrapper a {
    margin: 0 30px 20px 0;
    display: inline-block;
}
.content-wrapper.title_row .tech_icons_wrapper a:hover img {
    transform: rotate(360deg);
}
.services-detail-content.blue-color.only-center .btnWrap a.view_work {
    border-color: #e05640;
}
/* .main_services .services-listing .row-flex {
    flex-direction: column;
    position: relative;
    overflow: visible;
} */
.main_services .services-listing .img-txt-wrap {
    border-right: 0px !important;
    padding: 15px 0;
    cursor: pointer;
    position: relative;
    text-align: center;
}
.main_services .services-listing .col-flex:not(:first-child) .img-txt-wrap::before {
    content: "";
    width: 85%;
    height: 1px;
    background-color: #bbb;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    margin: 0 auto;
    z-index: -2;
}
.main_services .services-listing .img-txt-wrap:hover {
    cursor: pointer;
}
.main_services .services-listing .img-txt-wrap .img-wrap {
    margin-bottom: 5px;
}
.main_services .services-listing .img-txt-wrap .img-wrap img {
    height: 30px;
    vertical-align: bottom;
    width: 30px;
}
.main_services .services-listing .img-txt-wrap p {
    font-size: 16px;
    padding: 0 7px;
}
.main_services .serviceListWrapper .services-detail-content {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    align-items: center;
    padding: 40px 0;
}
.main_services .services-listing .row-flex .col-flex.active::after {
    content: "";
    background-image: url(../images/services/servicesTabsOverlayBg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    width: 120px;
    height: 118px;
    position: absolute;
    top: -5px;
    left: -5px;
    z-index: -1;
}

.main_services .services-listing .row-flex .col-flex{
    position: relative;
}

.main_services .services-listing > .container_custom {
    padding: 0;
}
.main_services .services-listing .col-flex.active .img-txt-wrap p {
    color: #fff;
}
.main_services .services-listing .row-flex.tab-1::before {
    top: -5px;
}
.main_services .services-listing .row-flex.tab-2::before {
    top: 96px;
}
.main_services .services-listing .row-flex.tab-3::before {
    top: 199px;
}
.main_services .services-listing .row-flex.tab-4::before {
    top: 302px;
}
.main_services .services-listing .row-flex.tab-5::before {
    top: 405px;
}
.main_services .services-listing .row-flex.tab-6::before {
    top: 407px;
}
.main_services .services-listing .col-flex.active .img-wrap img.withActive {
    display: inline-block;
}
.main_services .services-listing .col-flex .img-wrap img.withoutActive {
    display: inline-block;
}
.main_services .services-listing .col-flex .img-wrap img.withActive {
    display: none;
}
.main_services .services-listing .col-flex.active .img-wrap img.withoutActive {
    display: none;
}
.main_services .serivceTabsSide {
    position: relative;
}
.main_services .services-listing {
    position: fixed;
    border-bottom: 0;
    top: 130px;
    right: -300px;
    background-color: #fff;
    z-index: 99;
    border-radius: 15px 0px 0px 15px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    padding: 15px 0;
    transition: 0.5s;
    width: 115px;
}
.main_services .serivceTabsSide .toggle-btn {
    position: fixed;
    top: 102px;
    right: 0;
    background-color: #e02d34;
    color: #fff;
    width: 28px;
    height: 28px;
    line-height: 24px;
    text-align: center;
    border-radius: 6px 0 0 5px;
    cursor: pointer;
}   
.main_services .services-listing.showTab {
    right: 0px;
}
.main_services .serivceTabsSide .toggle-btn i.material-icons {
    font-size: 18px;
    line-height: 28px;
    transition: 0.5s;
}
.main_services .services-listing.showTab .toggle-btn i.material-icons {
    transform: rotate(180deg);
}

/***Web Development Page Start***/
.service_category .sec_one .head_title {
    font-size: 36px !important;
    margin-bottom: 10px !important;
}
.main_web_dev .FourColumnLayout1 {
    background-color: #fff;
}
.main_web_dev .FourColumnLayout1 .list_key_boxes {
    background-color: #e6e6e6;
}
.service_category strong {
    font-weight: 600;
}
.main_web_dev .FourColumnLayout1 .list_key_boxes .head_paragraph {
    color: #333333 !important;
}
.main_web_dev .FourColumnLayout1 .list_key_boxes::after {
    border-color: #e6e6e6 transparent transparent transparent;
}
.main_web_dev .FourColumnLayout1 .list_key_boxes::before {
    background-color: #fff;
}
.main_web_dev .major_networks_wrap {
    overflow: hidden;
}
.serviceCatPortfolioSlider aside {
    padding: 0 10px;
    position: relative;
}
.serviceCatPortfolioSlider aside .title {
    margin: 0px 0 5px;
    color: #444a60;
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;
}
.serviceCatPortfolioSlider aside .link {
    color: #828282;
    font-size: 12px;
    font-weight: 500;
}
.serviceCatPortfolioSlider aside img {
    border-radius: 2px;
}
.serviceCatPortfolioSlider aside .website_img {
    position: relative;
    overflow: hidden;
}
.serviceCatPortfolioSlider aside .website_img::before {
    content: "";
    width: 0;
    height: 0;
    background: rgba(94, 83, 83, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    transition: 0.5s ease-in-out;
    border-radius: 50%;
}
.serviceCatPortfolioSlider aside:hover .website_img::before {
    width: 200px;
    height: 200px;
    transform: scale(5);
}
.serviceCatPortfolioSlider aside .content_wrap {
    position: relative;
    padding: 10px 0px;
    transition: all 0.5s;
}
.serviceCatPortfolioSlider aside:hover .content_wrap {
    padding: 10px 10px;
}
.serviceCatPortfolioSlider .slick-next {
    right: 15px;
}
.serviceCatPortfolioSlider .slick-prev {
    left: 15px;
}
.serviceCatPortfolioSlider .slick-arrow {
    z-index: 1;
    top: 40%;
    width: 30px;
    height: 30px;
}
.serviceCatPortfolioSlider .slick-arrow::before {
    content: "" !important;
    background-image: url('../images/WebsiteDesignDevelopment/slick-arrow.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    opacity: 1;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
}
.serviceCatPortfolioSlider .slick-next::before {
    transform: rotate(180deg);
}
.main_web_dev .FourColumnLayout3 .serviceCatPortfolioSlider aside .title {
    color: #fff;
}
.main_web_dev .FourColumnLayout1 .boxes_wrapper {
    margin-bottom: 100px;
}
.main_web_dev .FourColumnLayout1 .boxes_wrapper.margin-bottom-change {
    margin-bottom: 100px;
}
.main_web_dev .technologies_multi_services_circle .serviceCatPortfolioSlider {
    padding-top: 40px;
    padding-bottom: 0;
}
.serviceCatPortfolioSlider2 {
    padding: 0 10px;
}
.serviceCatPortfolioSlider2 aside .title {
    margin: 0px 0 5px;
    color: #444a60;
    font-size: 24px;
    font-weight: bold;
    text-transform: capitalize;
    font-family: 'Saira Extra Condensed' !important;
}
.serviceCatPortfolioSlider2 aside .link {
    color: #828282;
    font-size: 15px;
    font-weight: 500;
}
.serviceCatPortfolioSlider2 aside .content_wrap {
    position: relative;
    padding: 10px 0px;
    text-align: center;
    margin-top: 40px;
}
.serviceCatPortfolioSlider2 aside .content_wrap .tech_icons_wrapper {
    display: flex;
    display: -ms-flexbox;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}
.serviceCatPortfolioSlider2 aside .content_wrap .tech_icons_wrapper .tech_icons {
    margin: 0 5px;
}
.serviceCatPortfolioSlider2 .slick-list {
    padding-top: 19px !important;
}
.serviceCatPortfolioSlider2 .slick-list::before {
    content: "";
    z-index: 1;
    background-image: url('../images/WebsiteDesignDevelopment/laptop-portfolio-middle.png');
    background-size: 100%;
    background-position: center top;
    background-repeat: no-repeat;
    max-width: 100%;
    width: 585px;
    height: 325px;
    position: absolute;
    left: 3px;
    top: 0px;
    margin: 0 auto;
    right: 0;
    pointer-events: none;
}
.serviceCatPortfolioSlider2 .slick-slider::before {
    content: "";
    width: 32.2%;
    height: 263px;
    background: linear-gradient(45deg, rgb(255, 255, 255), rgba(255, 255, 255, 0.6));
    position: absolute;
    left: 0;
    top: 19px;
    z-index: 1;
    pointer-events: none;
}
.serviceCatPortfolioSlider2 .slick-slider::after {
    content: "";
    width: 32.3%;
    height: 263px;
    background: linear-gradient(-45deg, rgb(255, 255, 255), rgba(255, 255, 255, 0.6));
    position: absolute;
    right: 0;
    top: 19px;
    z-index: 1;
    pointer-events: none;
}
.serviceCatPortfolioSlider2 .slick-slider .slick-slide:not(.slick-center) {
    pointer-events: none;
}
.serviceCatPortfolioSlider2 .slick-slider .slick-slide:not(.slick-center) aside .content_wrap {
    opacity: 0;
    visibility: hidden;
}
.serviceCatPortfolioSlider2 .slick-slider aside .website_img img {
    margin: 0 auto;
}
.serviceCatPortfolioSlider2:not(.serviceCatPortfolioSlider3) .slick-slider aside .website_img img {
    width: 100%;
}
.serviceCatPortfolioSlider2 aside a:hover .content_wrap .link {
    color: #e05640;
}
/***Web Development Page End***/

/***E-Commerce Development Page Start***/

.main_ecommerce_dev .major_social_networks .major_networks_wrap {
    overflow: hidden;
}
.main_ecommerce_dev .FourColumnLayout3 {
    background-image: none;
    padding-bottom: 60px;
}
.main_ecommerce_dev .FourColumnLayout3 .boxes_wrapper {
    margin-bottom: 0;
}
.main_ecommerce_dev .FourColumnLayout3 .serviceCatPortfolioSlider2 .slick-slider::before {
    background: linear-gradient(45deg, rgb(20, 28, 55), rgba(20, 28, 55, 0.6));
}
.main_ecommerce_dev .FourColumnLayout3 .serviceCatPortfolioSlider2 .slick-slider::after {
    background: linear-gradient(-45deg, rgb(20, 28, 55), rgba(20, 28, 55, 0.6));
}
.main_ecommerce_dev .FourColumnLayout3 .serviceCatPortfolioSlider2  aside .title {
    color: #fff;
}
.main_ecommerce_dev .FourColumnLayout3 .serviceCatPortfolioSlider2 aside .link {
    color: #9ea2ae;
}
.main_ecommerce_dev .FourColumnLayout3 aside {
    max-width: inherit;
}
.six-services {
    display: flex;
    display: -ms-flexbox;
    list-style: none;
}
.six-services .head_title {
    font-size: 26px !important;
    line-height: 1.2 !important;
    margin-bottom: 10px;
}
.six-services .head_paragraph {
    font-size: 13px !important;
    line-height: 1.5 !important;
    color: #666666 !important;
}
.six-services .circle_wrapper {
    margin-bottom: 90px;
    position: relative;
}
.six-services .circle_wrapper .icon_wrap {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: #f7f7f7;
    box-shadow: 0px 4px 10px rgba(0,0,0,0.2);
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    position: relative;
    z-index: 1;
}
.six-services .circle_wrapper .icon_wrap img {
    height: 40px;
    width: 40px;
}
.six-services .circle_wrapper .semi-cicle {
    height: 185px;
    width: 185px;
    margin: 0 auto;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    
}
.six-services .circle_wrapper .semi-cicle::before, .six-services .circle_wrapper .semi-cicle::after {
    content: "";
    width: 100%;
    height: 50%;
    background: #0075c1;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    transition: all 0.5s ease-in-out;
}
.six-services .circle_wrapper .semi-cicle::after {
    transform-origin: top center;
}
.six-services li:hover .circle_wrapper .semi-cicle::after {
    transform: rotate(180deg);
    transform-origin: top center;
}

.six-services .circle_wrapper .semi-cicle::before {
    margin-bottom: 0.5px;
}
.six-services .circle_wrapper::before {
    content: "";
    width: 2px;
    height: 70px;
    background: #2975c0;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
}
.six-services .circle_wrapper::after {
    content: "";
    width: 15px;
    height: 15px;
    background: #2975c0;
    border-radius: 50%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -70px;
    margin: 0 auto;
}
.six-services li {
    padding: 0 10px;
    transition: all 0.5s ease-in-out;
}
.six-services li:nth-child(odd) {
    padding-top: 100px;
}
.six-services li:nth-child(odd):hover {
    padding-top: 80px;
}
.six-services .blue .circle_wrapper::before, 
.six-services .blue .circle_wrapper::after,
.six-services .blue .circle_wrapper .semi-cicle::before,
.six-services .blue .circle_wrapper .semi-cicle::after {
    content: "";
    background: #2975c0;
}
.six-services .red .circle_wrapper::before, 
.six-services .red .circle_wrapper::after,
.six-services .red .circle_wrapper .semi-cicle::before,
.six-services .red .circle_wrapper .semi-cicle::after {
    content: "";
    background: #ff596c;
}
.six-services .green .circle_wrapper::before, 
.six-services .green .circle_wrapper::after,
.six-services .green .circle_wrapper .semi-cicle::before,
.six-services .green .circle_wrapper .semi-cicle::after {
    content: "";
    background: #007a4c;
}
.six-services .yellow .circle_wrapper::before, 
.six-services .yellow .circle_wrapper::after,
.six-services .yellow .circle_wrapper .semi-cicle::before,
.six-services .yellow .circle_wrapper .semi-cicle::after {
    content: "";
    background: #faa300;
}
.six-services .orange .circle_wrapper::before, 
.six-services .orange .circle_wrapper::after,
.six-services .orange .circle_wrapper .semi-cicle::before,
.six-services .orange .circle_wrapper .semi-cicle::after {
    content: "";
    background: #eb6e3c;
}
.six-services .lightgreen .circle_wrapper::before, 
.six-services .lightgreen .circle_wrapper::after,
.six-services .lightgreen .circle_wrapper .semi-cicle::before,
.six-services .lightgreen .circle_wrapper .semi-cicle::after {
    content: "";
    background: #4bf0da;
}
.main_ecommerce_dev .content_box.middle_img .six-services {
    display: block;
    position: relative;
    padding: 0;
    height: 440px;
}
.main_ecommerce_dev .content_box.middle_img .six-services::before {
    content: "";
    width: 80%;
    height: 80%;
    border: 2px dashed #bbb;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.main_ecommerce_dev .content_box.middle_img .six-services li {
    padding: 15px;
    position: absolute;
}
.main_ecommerce_dev .content_box.middle_img .six-services .circle_wrapper::before, .main_ecommerce_dev .content_box.middle_img .six-services .circle_wrapper::after {
    opacity: 0;
}
.main_ecommerce_dev .content_box.middle_img .six-services .circle_wrapper {
    margin: 0px;
}
.main_ecommerce_dev .content_box.middle_img .six-services li:nth-child(1) {
    left: 0;
    top: 70px;
}
.main_ecommerce_dev .content_box.middle_img .six-services li:nth-child(2) {
    left: 0;
    bottom: 70px;
}
.main_ecommerce_dev .content_box.middle_img .six-services li:nth-child(3) {
    top: 0;
    right: 0;
    left: 0;
    margin: 0 auto;
}
.main_ecommerce_dev .content_box.middle_img .six-services li:nth-child(4) {
    bottom: 0;
    right: 0;
    left: 0;
    margin: 0 auto;
}
.main_ecommerce_dev .content_box.middle_img .six-services li:nth-child(5) {
    right: 0;
    top: 70px;
}
.main_ecommerce_dev .content_box.middle_img .six-services li:nth-child(6) {
    right: 0;
    bottom: 70px;
}
.main_ecommerce_dev .content_box.middle_img .six-services .circle_wrapper .icon_wrap {
    width: 100px;
    height: 100px;
}
.main_ecommerce_dev .content_box.middle_img .six-services .circle_wrapper .semi-cicle {
    height: 125px;
    width: 125px;
}

#shopify-development .blue .circle_wrapper .semi-cicle {
    transform: translate(-50%, -50%) rotate(-62deg);
}

#shopify-development .red .circle_wrapper .semi-cicle {
    transform: translate(-50%, -50%) rotate(-120deg);
}

#shopify-development .yellow .circle_wrapper .semi-cicle {
    transform: translate(-50%, -50%) rotate(180.1deg);
}

#shopify-development .orange .circle_wrapper .semi-cicle {
    transform: translate(-50%, -50%) rotate(62deg);
}

#shopify-development .lightgreen .circle_wrapper .semi-cicle {
    transform: translate(-50%, -50%) rotate(120deg);
}
.main_ecommerce_dev .content_box.middle_img .middle-text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.main_ecommerce_dev .content_box.middle_img .middle-text h5 {
    margin: 0 !important;
    color: #a0a6ad;
    font-size: 32px;
    font-family: 'Saira Extra Condensed';
    line-height: 1.2;
    font-weight: 700;
}
/***E-Commerce Development Page End***/

/***Application Development Page Start***/
.bg-lightgrey {
    background-color: #f1f1f1;
}
.bg-lightgrey .serviceCatPortfolioSlider2 .slick-slider::before {
    background: linear-gradient(45deg, rgb(241, 241, 241), rgba(241, 241, 241, 0.6));
}
.bg-lightgrey .serviceCatPortfolioSlider2 .slick-slider::after {
    background: linear-gradient(-45deg, rgb(241, 241, 241), rgba(241, 241, 241, 0.6));
}
.bg-darkblue {
    background-color: #151c37;
}
.bg-darkblue .head_title, 
.bg-darkblue .head_paragraph {
    color: #fff !important;
}
.key_benfits_and_why_choose.bg-darkblue .list_key_boxes .head_paragraph,
.bg-darkblue .serviceCatPortfolioSlider2 aside .title {
    color: #fff !important;
}
.bg-darkblue .serviceCatPortfolioSlider2 aside .link {
    color: #cdcfd2;
}
.bg-darkblue .serviceCatPortfolioSlider2 .slick-slider::before {
    background: linear-gradient(45deg, rgb(20, 28, 55), rgba(20, 28, 55, 0.6));
}
.bg-darkblue .serviceCatPortfolioSlider2 .slick-slider::after {
    background: linear-gradient(-45deg, rgb(20, 28, 55), rgba(20, 28, 55, 0.6));
}
.serviceCatPortfolioSlider3 .slick-list::before {
    content: "";
    background-image: url('../images/ApplicationDevelopment/mobile_frame.png');
    width: 222px;
    height: 434px;
    left: 0px;
}
.serviceCatPortfolioSlider3 aside {
    padding: 0 15px;
}
.serviceCatPortfolioSlider3 .slick-slider::before {
    content: "";
    width: 36%;
    height: 412px;
    top: 12px;
}
.serviceCatPortfolioSlider3 .slick-slider::after {
    content: "";
    width: 36%;
    height: 412px;    
    top: 12px;
}
.bg-lightgrey .serviceCatPortfolioSlider3 .slick-slider::before {
    background: linear-gradient(45deg, rgb(241, 241, 241), rgba(241, 241, 241, 0.6));
}
.bg-lightgrey .serviceCatPortfolioSlider3 .slick-slider::after {
    background: linear-gradient(45deg, rgb(241, 241, 241), rgba(241, 241, 241, 0.6));
}
.serviceCatPortfolioSlider3 .slick-list {
    padding-top: 12px !important;
}
.serviceCatPortfolioSlider3 aside .content_wrap {
    margin-top: 20px;
}
.serviceCatPortfolioSlider3 aside .content_wrap .btnDownloadWrap a {
    margin: 10px;
}
.serviceCatPortfolioSlider3 aside .content_wrap .btnDownloadWrap img {
    width: 30px;
    height: 30px;
}
.serviceCatPortfolioSlider3 aside .content_wrap .btnDownloadWrap a:hover img {
    cursor: pointer;
    filter: drop-shadow(2px 4px 6px #e05640);
}
/***Application Development Page End***/

/***UI/UX Page Start***/
.print-principles, .print-principles .print-principles-inner  {
    width: 100%;
}
.principles-listing ul li {
    list-style: none;
    position: absolute;
}
.principles-listing ul {
    padding: 0;
    height: 220px;
    position: relative;
    max-width: 630px;
    margin: 0 auto;
    margin: 140px auto 0;
}
.print-below-listing {
    text-align: center;
}
.principles-listing ul li .pr-listing-inner {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    text-align: center;
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    justify-content: center;
    padding: 15px;
}
.principles-listing ul li:nth-child(3) .pr-listing-inner {
    background: #00b2bb;
}
.principles-listing ul li:nth-child(1) .pr-listing-inner {
    background: #399fa9;
}
.principles-listing ul li:nth-child(2) .pr-listing-inner {
    background: #24353f;
}
.principles-listing ul li:nth-child(4) .pr-listing-inner {
    background: #5e718a;
}
.principles-listing ul li:nth-child(5) .pr-listing-inner {
    background: #7a7f83;
}
.principles-listing ul li:nth-child(1) {
    top: auto;
    bottom: -66px;
    left: -8px;
}
.principles-listing ul li:nth-child(3) {
    top: -6px;
    left: 49%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}
.principles-listing ul li:nth-child(2) {
    top: 23%;
    left: 14%;
}
.principles-listing ul li:nth-child(4) {
    right: 14%;
    top: 19%;
}
.principles-listing ul li:nth-child(5) {
    bottom: -64px;
    right: -20px;
}
.principles-listing ul li .box-content {
    position: absolute;
    opacity: 1;
    left: -300px;
    transition: all 0.5s;
    top: auto;
    bottom: 90px;
    width: 300px;
    box-shadow: 0 0 3px 2px transparent;
    padding: 10px;
    background: #fff;
}
.principles-listing ul li:hover .box-content {
    opacity: 1;
}
.principles-listing ul li .box-content h4 {
    font-size: 24px !important;
    line-height: 1.2 !important;
    text-transform: capitalize;
}
.print-principles .print-principles-inner ul li .box-content p {
    font-size: 13px !important;
    color: #666666 !important;
    margin: 5px 0 0 0 !important;
    line-height: 1.6 !important;
}
.principles-listing ul li:nth-child(3) .box-content {
    top: -140px;
    bottom: auto;
    left: 50%;
    transform: translateX(-50%);
}
.principles-listing ul li:nth-child(4) .box-content {
    right: -300px;
    left: inherit;
    top: auto;
    bottom: 90px;
}
.principles-listing ul li:nth-child(5) .box-content {
    right: -300px;
    left: inherit;
    top: auto;
    bottom: 90px;
}
.principles-listing ul li:nth-child(1) .pr-listing-inner:before {
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    position: absolute;
    border-left: 15px solid #34949d;
    content: "";
    right: -6px;
    bottom: 15px;
    transform: rotate(28deg);
}
.principles-listing ul li:nth-child(1):hover .box-content {
    background: #fff;
    box-shadow: 0 0 3px 2px #3799a2;
    border-radius: 5px;
    padding: 10px;
    bottom: 60px;
}
.principles-listing ul li:nth-child(2):hover .box-content {
    background: #fff;
    box-shadow: 0 0 3px 2px #25363f;
    border-radius: 5px;
    padding: 10px;
    bottom: 110px;
}
.principles-listing ul li:nth-child(3):hover .box-content {
    background: #fff;
    box-shadow: 0 0 3px 2px #00b2bb;
    border-radius: 5px;
    padding: 10px;
    top: -160px;
}
.principles-listing ul li:nth-child(4):hover .box-content {
    background: #fff;
    box-shadow: 0 0 3px 2px #5e718a;
    border-radius: 5px;
    padding: 10px;
    bottom: 110px;
}
.principles-listing ul li:nth-child(5):hover .box-content {
    background: #fff;
    box-shadow: 0 0 3px 2px #7a7f83;
    border-radius: 5px;
    padding: 10px;
    bottom: 60px;
}


.principles-listing ul li:nth-child(5):before {
    border: 5px solid #7a7f83;
    right: 118px;
    bottom: -5px;
}

.principles-listing ul li:nth-child(1) .box-content,.principles-listing ul li:nth-child(5) .box-content {
    bottom: 40px;
}

.principles-listing ul li .pr-listing-inner .img-wrap {
    background-color: #f7f7f7;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    justify-content: center;
    transition: all 0.5s;
}
.principles-listing ul li:hover .pr-listing-inner .img-wrap {
    transform: scale(1.1);
    transition: all 0.5s;
}
.principles-listing ul li .pr-listing-inner .img-wrap img {
    transition: all 0.5s;
    max-width: 100%;
    height: 30px;
}
.principles-listing ul li:nth-child(5):after {
    background: #797e82;
    right: 129px;
    bottom: 6px;
}
.principles-listing ul li:before {
    position: absolute;
    content: "";
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: #fff;
}
.principles-listing ul li:nth-child(1):before {
    border: 6px solid #399ea8;
    padding: 0px;
    right: -48px;
    bottom: 0;
}
.principles-listing ul li:after
{
    position: absolute;
    content: "";
    width: 15px;
    height: 15px;
    border-radius: 50%;
}
.principles-listing ul li:nth-child(1):after {
    background: #379ba4;
    right: -36px;
    bottom: 12px;
}

.principles-listing ul li:nth-child(2) .pr-listing-inner:before {
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    position: absolute;
    border-left: 15px solid #202e37;
    content: "";
    right: 13px;
    bottom: -7px;
    transform: rotate(56deg);
}
.principles-listing ul li:nth-child(2):before {
    border: 5px solid #22323b;
    right: -19px;
    bottom: -40px;
}

.principles-listing ul li:nth-child(2):after {
    background: #22323a;
    right: -8px;
    bottom: -29px;
}
.principles-listing ul li:nth-child(3) .pr-listing-inner:before {
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    position: absolute;
    border-left: 15px solid #00b2bb;
    content: "";
    right: 43px;
    bottom: -17px;
    transform: rotate(90deg);
}
.principles-listing ul li:nth-child(3):before {
    border: 5px solid #00a5ae;
    right: 31px;
    bottom: -57px;
}

.principles-listing ul li:nth-child(3):after {
    background: #00b1bb;
    right: 42px;
    bottom: -46px;
}


.principles-listing ul li:nth-child(4) .pr-listing-inner:before {
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    position: absolute;
    border-left: 15px solid #596c83;
    content: "";
    right: 73px;
    bottom: -6px;
    transform: rotate(122deg);
}
.principles-listing ul li:nth-child(4):before {
    border: 5px solid #54667c;
    right: 89px;
    bottom: -45px;
}

.principles-listing ul li:nth-child(4):after {
    background: #5a6e86;
    right: 100px;
    bottom: -34px;
}

.principles-listing ul li:nth-child(5) .pr-listing-inner:before {
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    position: absolute;
    border-left: 15px solid #757a7e;
    content: "";
    right: 88px;
    bottom: 12px;
    transform: rotate(148deg);
}
.print-below-listing img:nth-child(1) {
    margin: -90px 0 0 0;
}
.print-below-listing img:nth-child(2) {
    width: 100%;
    max-width: 320px;
    margin: -90px auto 0;
    display: block;
    transition: all 0.5s;
}
.print-below-listing img:nth-child(3) {
    max-width: 390px;
    margin: -150px 0 0 0;
}

.bg-skyblue {
    background-color: #2978cb;
}
.bg-skyblue .head_title, 
.bg-skyblue .head_paragraph {
    color: #fff !important;
}
.key_benfits_and_why_choose.bg-skyblue .list_key_boxes .head_paragraph,
.bg-skyblue .serviceCatPortfolioSlider2 aside .title {
    color: #fff !important;
}
.bg-skyblue .serviceCatPortfolioSlider2 aside .link {
    color: #cdcfd2;
}
.bg-skyblue .serviceCatPortfolioSlider2 .slick-slider::before {
    background: linear-gradient(45deg, rgb(43, 120, 203), rgba(43, 120, 203, 0.6));
}
.bg-skyblue .serviceCatPortfolioSlider2 .slick-slider::after {
    background: linear-gradient(-45deg, rgb(43, 120, 203), rgba(43, 120, 203, 0.6));
}
#print-media-design .principles-listing {
    padding-top: 50px;
}
.principles-listing .blue::before {
    border-color: #2975c0 !important;
}
.principles-listing .blue::after {
    background: #2975c0 !important;
}
.principles-listing .blue .pr-listing-inner {
    background: #2975c0 !important;
}
.principles-listing .blue .pr-listing-inner::before {
    border-left-color: #2975c0 !important;
}
.principles-listing .blue:hover .box-content {
    box-shadow: 0 0 3px 2px #2a74c1 !important;
    background: #fff;
    z-index: 9;
}
.principles-listing .red::before {
    border-color: #ff596c !important;
}
.principles-listing .red::after {
    background: #ff596c !important;
}
.principles-listing .red .pr-listing-inner {
    background: #ff596c !important;
}
.principles-listing .red .pr-listing-inner::before {
    border-left-color: #ff596c !important;
}
.principles-listing .red:hover .box-content {
    box-shadow: 0 0 3px 2px #ff596c !important;
}
.principles-listing .green::before {
    border-color: #007a4c !important;
}
.principles-listing .green::after {
    background: #007a4c !important;
}
.principles-listing .green .pr-listing-inner {
    background: #007a4c !important;
}
.principles-listing .green .pr-listing-inner::before {
    border-left-color: #007a4c !important;
}
.principles-listing .green:hover .box-content {
    box-shadow: 0 0 3px 2px #007a4c !important;
}
.principles-listing .yellow::before {
    border-color: #faa300 !important;
}
.principles-listing .yellow::after {
    background: #faa300 !important;
}
.principles-listing .yellow .pr-listing-inner {
    background: #faa300 !important;
}
.principles-listing .yellow .pr-listing-inner::before {
    border-left-color: #faa300 !important;
}
.principles-listing .yellow:hover .box-content {
    box-shadow: 0 0 3px 2px #faa300 !important;
}
.principles-listing .orange::before {
    border-color: #eb6e3c !important;
}
.principles-listing .orange::after {
    background: #eb6e3c !important;
}
.principles-listing .orange .pr-listing-inner {
    background: #eb6e3c !important;
}
.principles-listing .orange .pr-listing-inner::before {
    border-left-color: #eb6e3c !important;
}
.principles-listing .orange:hover .box-content {
    box-shadow: 0 0 3px 2px #eb6e3c !important;
}

.three-box-shape {
    display: flex;
    display: -ms-flexbox;
    max-width: 1024px;
    margin: 30px auto;
}
.three-box-shape ul {
    list-style: none;
    padding: 0;
    width: 50%;
    position: relative;
    margin: 0;
}
.three-box-shape ul.leftSide {
    padding-top: 106px;
}
.three-box-shape ul li {
    margin: 58px 0;
    margin-left: auto;
    position: relative;
}
.three-box-shape ul li aside {
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    padding: 34px 10px;
    z-index: 3;
    position: relative;
    background: #fff;
}
.three-box-shape ul li::before {
    content: "";
    width: 60%;
    height: 100%;
    box-shadow: 0px 0px 20px rgba(0,0,0,0.2);
    border-radius: 50%;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: -1;
}
.three-box-shape ul li .icon-wrap img {
    height: 45px;
    width: 45px;
}
.three-box-shape ul li aside .icon-wrap {
    width: 45px;
}
.three-box-shape ul li .text-wrap {
    padding: 0 15px;
    width: calc(100% - 45px);
}
.three-box-shape ul li .text-wrap h6 {
    font-family: poppins;
    font-weight: 600;
    font-size: 20px;
    color: #444a60;
    text-transform: capitalize;
}
.three-box-shape ul li .text-wrap .head_paragraph {
    line-height: 1.4 !important;
    margin: 0 !important;
    font-size: 13px !important;
    color: #666 !important;
    min-height: 54px;
}
.three-box-shape ul li::after {
    content: "";
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 238px;
    height: calc(100% + 53px);
    position: absolute;
    top: -27px;
}
.three-box-shape ul.leftSide li {
    padding-right: 58px;
}
.three-box-shape ul.rightSide li {
    padding-left: 58px;
}
.three-box-shape ul.leftSide li::after {
    content: "";
    right: calc(0px - 27px);
}
.three-box-shape ul.rightSide li::after {
    content: "";
    left: calc(0px - 27px);
}
.three-box-shape ul li.yellow::after {
    content: "";
    background-image: url('../images/GraphicDesign/hexagonal-yellow.png');
}
.three-box-shape ul li.red::after {
    content: "";
    background-image: url('../images/GraphicDesign/hexagonal-red.png');
}
.three-box-shape ul li.blue::after {
    content: "";
    background-image: url('../images/GraphicDesign/hexagonal-blue.png');
}
.broucherSlider .slick-slide:not(.slick-center) {
    transform: scale(0.8) translateY(43px);
}
.broucherSlider .slick-slide img {
    margin-bottom: -2px;
}
.broucherSlider .slick-slide aside {
    position: relative;
}
.broucherSlider .slick-slide:not(.slick-center) aside::before {
    content: "";
    width: 100%;
    height: 100%;
    background:rgba(241, 241, 241, 0.7);
    position: absolute;
    left: 0;
    bottom: 0;
}
/* .broucherSlider {
    padding: 0 30px;
} */
.logoSlider .slick-slide > div > div {
    display: block !important;
}
.logoSlider aside .logo_img {
    position: relative;
    overflow: hidden;
    /* padding: 36%; */
    transition: all 0.5s ease-in-out;
}
.logoSlider aside .logo_img:hover {
    transform: scale(1.025);
    box-shadow:0px 0px 20px rgba(0,0,0,0.3);
    z-index: 1;
    border-radius: 4px;
}
/* .logoSlider aside .logo_img img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: inherit;
} */
.sliderNavForDesign {
    position: relative;
}
.sliderNavForDesign .mobileSlider::before, .sliderNavForDesign .mobileSlider::after,
.sliderNavForDesign .mobileSlider .slick-slider::before, .sliderNavForDesign .mobileSlider .slick-slider::after {
    opacity: 0;
}
.sliderNavForDesign .mobileSlider .slick-list::before {
    background-image: url('../images/GraphicDesign/mobile_Frame.png');
    width: 110px;
    height: 232px;
    left: 0;
    background-size: calc(100% + 5px);
}
.sliderNavForDesign .mobileSlider {
    width: 110px;
    height: 226px;
    overflow: hidden;
    position: absolute;
    top: 80px;
    left: 0;
    right: -400px;
    margin: 0 auto;
    z-index: 1;
}
.sliderNavForDesign .mobileSlider .slick-list {
    padding-top: 26px !important;
}
.graphicsShape1 .box-wrapper .img-wrap {
    background-color: #f7f7f7;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    width: 100%;
    height: 100%;
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    justify-content: center;
}
.graphicsShape1 .box-wrapper .img-wrap img {
    height: 30px;
    margin: auto;
}
.graphicsShape1 .box-wrapper .circleShape {
    background-color: #2975c0;
    padding: 15px;
    width: 100px;
    height: 100px;
    margin: 0 auto 15px;
    border-radius: 50%;
    position: relative;
    transition: 0.8s;
}
.graphicsShape1 .box-wrapper .circleShape::after {
    content: "";
    width: 0;
    height: 0;
    border-width: 15px;
    border-style: solid;
    border-color: #2a74c1 transparent transparent transparent;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: calc(100% - 5px);
}
.graphicsShape1 .box-wrapper:hover .circleShape {
    padding: 12px;
}
.graphicsShape1 .box-wrapper .content .head_title {
    font-size: 24px !important;
    line-height: 1.2 !important;
}
.graphicsShape1 .box-wrapper .content .head_paragraph {
    margin: 0 !important;
    color: #666666 !important;
    line-height: 1.6 !important;
}
.graphicsShape1 .blue .circleShape {
    background-color: #2975c0;
}
.graphicsShape1 .blue .circleShape::after {
    border-top-color: #2975c0;
}
.graphicsShape1 .red .circleShape {
    background-color: #ff596c;
}
.graphicsShape1 .red .circleShape::after {
    border-top-color: #ff596c;
}
.graphicsShape1 .green .circleShape {
    background-color: #26794c;
}
.graphicsShape1 .green .circleShape::after {
    border-top-color: #26794c;
}
.graphicsShape1 .yellow .circleShape {
    background-color: #f7a302;
}
.graphicsShape1 .yellow .circleShape::after {
    border-top-color: #f7a302;
}
.graphicsShape1 .orange .circleShape {
    background-color: #eb6e3c;
}
.graphicsShape1 .orange .circleShape::after {
    border-top-color: #eb6e3c;
}
/***UI/UX Page End***/

/***HireUsFor Page Start***/
.form_layout * {
    font-family: poppins !important;
}
.form_layout .inner-fields {
    width: calc(100% - 30px);
    box-sizing: border-box;
    margin: 15px;
}
.form_layout .inner-fields span.text-danger {
    margin: 0;
}
.form_layout .inner-fields .custom-label {
    color: rgba(0, 0, 0, 0.54);
}
.form_layout .submit-btn input[type="submit"] {
    background: #e05640;
    color: #fff;
    font-size: 17px;
    text-transform: capitalize;
    font-weight: 600;
    letter-spacing: 1px;
    border-radius: 4px;
    float: right;
    margin-left: auto;
    cursor: pointer;
    border: 1px solid transparent;
    padding: 14px 40px;
    transition: 0.5s;
    width: 150px;
    -webkit-appearance: none;
}
.form_layout .submit-btn input[type="submit"]:hover {
    background: #fff;
    color: #e05640;
    border-color: #e05640;
}
/***HireUsFor Page End***/

/**Server Page Start***/
.img-row.text-center {
    margin: 20px 0 0 0;
}
/**Server Page End***/

/***Digital Marketing Page Start***/

.globel-smo-text .textWrap.text-left {
    text-align: left;
}

.globel-smo-text .textWrap.text-left .head_title,.globel-smo-text .textWrap.text-left .head_paragraph {
    text-align: left !important;
}

.globel-smo-text .textWrap.text-left .head_title {
    margin: 0 0 20px 0;
}


.globel-smo .globel-smo-experience .smo-wrap .innerDiv {
    padding: 25px 15px;
    background: #555a86;
    text-align: center;
    margin: 0 0 10px 0;
}
.globel-smo .globel-smo-experience .smo-wrap {
    display: flex;
    display: -ms-flexbox;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
}
.globel-smo .globel-smo-experience .smo-wrap .smocolumn {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0px 5px;
}
.globel-smo .globel-smo-experience .smo-wrap .innerDiv .text-wrapper h4 {
    font: 700 25px/1.3 'Saira Extra Condensed', sans-serif;
    color: #fff;
    margin: 0 0 15px 0;
}

.globel-smo .globel-smo-experience .smo-wrap .innerDiv .text-wrapper  p {
    font: 400 13px/1.2 Poppins;
    color: #fff;
    margin: 0 0 10px 0;
}

.globel-smo .globel-smo-experience .smo-wrap .innerDiv .img-wrapper {
    display: inline-block;
    width: 130px;
    height: 130px;
    border: 20px solid #fff;
    border-radius: 50%;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 30px;
}

.globel-smo .globel-smo-experience .smo-wrap .innerDiv .img-wrapper img {
    width: auto;
    max-width: 50px;
}


.globel-smo .globel-smo-experience .smo-wrap .innerDiv .img-wrapper.local-seo {
    background: #ff4b69;
}
.globel-smo .globel-smo-experience, .globel-smo-text {
    padding: 0 20px;
}

.globel-smo .globel-smo-experience .smo-wrap .innerDiv .img-wrapper.local-business {
    background: #00d9bf;
}

.globel-smo .globel-smo-experience .smo-wrap .innerDiv .img-wrapper.large-enterprice {
    background: #00cfff;
}

.globel-smo .globel-smo-experience .smo-wrap .innerDiv.years-exp {
    background: #151c37;
    padding: 35px 15px 45px 15px;
}

.globel-smo .globel-smo-experience .smo-wrap .innerDiv.years-exp .exp-text p {
    font: 700 30px/1 poppins;
    color: #fff;
}

.globel-smo .globel-smo-experience .smo-wrap .innerDiv.years-exp .exp-text p span {
    font-size: 50px;
    color: #ea5e3f;
}
.bg-oliveblue {
    background-color: #0b1352;
}
#social-media-optimization .boxes_listing {
    margin-bottom: 30px !important;
}
#local-seo .principles-listing {
    padding-top: 40px;
}
.graphicsShape2 {
    position: relative;
    max-width: 1200px;
    margin: 0 auto 320px;
}
.graphicsShape2::before {
    content: attr(data-text);
    background: radial-gradient(#f0f7f9, #e6edef);
    width: 285px;
    height: 285px;
    border-radius: 50%;
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    justify-content: center;
    padding: 40px;
    box-sizing: border-box;
    font-family: 'Saira Extra Condensed', sans-serif !important;
    font-weight: bold;
    font-size: 50px;
    color: #788181;
    line-height: 1.2;
    text-align: center;
    box-shadow: 0px 0px 2px rgba(0,0,0,0.15);
    margin: 0 auto;
}
.graphicsShape2::after {
    content: "";
    width: 345px;
    height: 345px;
    border-radius: 50%;
    display: block;
    border: 8px solid #c1cdc9;
    border-top-color: transparent;
    border-left-color: transparent;
    position: absolute;
    top: -35px;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: rotate(45deg);
}
.graphicsShape2 .content .head_title {
    font-family: poppins !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #666 !important;
    line-height: 1.2 !important;
    margin-bottom: 8px;
}
.graphicsShape2 .content .head_paragraph {
    font-size: 13px !important;
    font-weight: 500 !important;
    color: #666 !important;
    line-height: 1.4 !important;
    margin: 0 !important;
}
.graphicsShape2 .circleShape {
    width: 155px;
    height: 155px;
    border-radius: 50%;
    border: 2px dashed #ffc400;
    padding: 10px;
    margin-bottom: 20px;
    position: relative;
    z-index: 1;
}
.graphicsShape2 .circleShape .img-wrap {
    background: #ffc400;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    padding: 20px;
    transition: all 0.3s ease-in-out;
}
.graphicsShape2 .box-wrapper:hover .circleShape .img-wrap {
    transform: scale(1.2);
}
.graphicsShape2 .circleShape .img-wrap .img-wrap-2 {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    background: #ffe876;
    padding: 5px;
    border: 2px solid #e1b30b;
    box-shadow: -20px 35px 12px rgba(0,0,0,0.2);
    transition: all 0.3s ease-in-out;
}
.graphicsShape2 .box-wrapper:hover .circleShape .img-wrap .img-wrap-2 {
    box-shadow: 0px 0px 12px rgba(0,0,0,0.2);
}
.graphicsShape2 .circleShape .img-wrap .img-circle {
    background: radial-gradient(#fff, #e8eef7);
    height: 100%;
    width: 100%;
    border-radius: 50%;
    border: 2px solid #e1b30b;
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    justify-content: center;
    padding: 16px;
}
.graphicsShape2 .circleShape .img-wrap .img-circle img {
    width: 40px;
    height: 40px;
    vertical-align: middle;
}
.graphicsShape2 .yellow .circleShape {
    border-color: #ffc400;
    margin-left: auto;
}
.graphicsShape2 .yellow .circleShape .img-wrap {
    background: #ffc400;
}
.graphicsShape2 .yellow .circleShape .img-wrap .img-wrap-2 {
    background: #ffe876;
    border-color: #e1b30b;
}
.graphicsShape2 .yellow .circleShape .img-wrap .img-wrap-2 .img-circle {
    border-color: #e1b30b;
}
.graphicsShape2 .yellow .circleShape .small-circles-wrapper {
    border-color: #ffc400;
}
.graphicsShape2 .yellow .circleShape .small-circles-wrapper .circles-2 {
    background: #ffc400;
    border-color: #ffe876;
}
.graphicsShape2 .navygreen .circleShape {
    border-color: #00d9be;
    margin-left: auto;
}
.graphicsShape2 .navygreen .circleShape .img-wrap {
    background: #00d9be;
}
.graphicsShape2 .navygreen .circleShape .img-wrap .img-wrap-2 {
    background: #54f4d9;
    border-color: #43c0a7;
}
.graphicsShape2 .navygreen .circleShape .img-wrap .img-wrap-2 .img-circle {
    border-color: #43c0a7;
}
.graphicsShape2 .navygreen .circleShape .small-circles-wrapper {
    border-color: #00d9be;
}
.graphicsShape2 .navygreen .circleShape .small-circles-wrapper .circles-2 {
    background: #00d9be;
    border-color: #54f4d9;
}
.graphicsShape2 .red .circleShape {
    border-color: #ff4a69;
    margin-left: auto;
}
.graphicsShape2 .red .circleShape .img-wrap {
    background: #ff4a69;
}
.graphicsShape2 .red .circleShape .img-wrap .img-wrap-2 {
    background: #ffa6c7;
    border-color: #d45c72;
}
.graphicsShape2 .red .circleShape .img-wrap .img-wrap-2 .img-circle {
    border-color: #d45c72;
}
.graphicsShape2 .red .circleShape .small-circles-wrapper {
    border-color: #ff4a69;
}
.graphicsShape2 .red .circleShape .small-circles-wrapper .circles-2 {
    background: #ff4a69;
    border-color: #ffa6c7;
}
.graphicsShape2 .pink .circleShape {
    border-color: #ff6fde;
}
.graphicsShape2 .pink .circleShape .img-wrap {
    background: #ff6fde;
}
.graphicsShape2 .pink .circleShape .img-wrap .img-wrap-2 {
    background: #ffa5e9;
    border-color: #f544d7;
}
.graphicsShape2 .pink .circleShape .img-wrap .img-wrap-2 .img-circle {
    border-color: #f544d7;
}
.graphicsShape2 .pink .circleShape .small-circles-wrapper {
    border-color: #ff6fde;
}
.graphicsShape2 .pink .circleShape .small-circles-wrapper .circles-2 {
    background: #ff6fde;
    border-color: #ffa5e9;
}
.graphicsShape2 .blue .circleShape {
    border-color: #00cfff;
}
.graphicsShape2 .blue .circleShape .img-wrap {
    background: #00cfff;
}
.graphicsShape2 .blue .circleShape .img-wrap .img-wrap-2 {
    background: #57e3ff;
    border-color: #29c0f7;
}
.graphicsShape2 .blue .circleShape .img-wrap .img-wrap-2 .img-circle {
    border-color: #29c0f7;
}
.graphicsShape2 .blue .circleShape .small-circles-wrapper {
    border-color: #00cfff;
}
.graphicsShape2 .blue .circleShape .small-circles-wrapper .circles-2 {
    background: #00cfff;
    border-color: #57e3ff;
}
.graphicsShape2 .parrotgreen .circleShape {
    border-color: #97da00;
}
.graphicsShape2 .parrotgreen .circleShape .img-wrap {
    background: #97da00;
}
.graphicsShape2 .parrotgreen .circleShape .img-wrap .img-wrap-2 {
    background: #aee632;
    border-color: #8bc802;
}
.graphicsShape2 .parrotgreen .circleShape .img-wrap .img-wrap-2 .img-circle {
    border-color: #8bc802;
}
.graphicsShape2 .parrotgreen .circleShape .small-circles-wrapper {
    border-color: #97da00;
}
.graphicsShape2 .parrotgreen .circleShape .small-circles-wrapper .circles-2 {
    background: #97da00;
    border-color: #aee632;
}
.graphicsShape2 .yellow .content *, .graphicsShape2 .navygreen .content *, .graphicsShape2 .red .content * {
    text-align: right !important;
}
.graphicsShape2 .pink .content *, .graphicsShape2 .blue .content *, .graphicsShape2 .parrotgreen .content * {
    text-align: left !important;
}
.graphicsShape2 .content {
    max-width: 320px;
    position: relative;
    z-index: 1;
}
.graphicsShape2 .yellow .content , .graphicsShape2 .navygreen .content , .graphicsShape2 .red .content  {
    margin-left: auto;
}
.graphicsShape2 .box-wrapper {
    position: absolute;
    width: 400px;
}
.graphicsShape2 .yellow {
    top: 70px;
    left: 0px;
}
.graphicsShape2 .yellow .content {
    margin-right: 140px;
}
.graphicsShape2 .navygreen {
    top: 232px;
    left: 50px;
}
.graphicsShape2 .navygreen .content {
    margin-right: 50px;
}
.graphicsShape2 .red {
    top: calc(100% + 50px);
    left: 190px;
}
.graphicsShape2 .red .content {
    margin-right: 10px;
}
.graphicsShape2 .pink {
    top: calc(100% + 50px);
    right: 190px;
}
.graphicsShape2 .pink .content {
    margin-left: 10px;
}
.graphicsShape2 .blue {
    top: 232px;
    right: 50px;
}
.graphicsShape2 .blue .content {
    margin-left: 50px;
}
.graphicsShape2 .parrotgreen {
    top: 70px;
    right: 0px;
}
.graphicsShape2 .parrotgreen .content {
    margin-left: 140px;
}
.graphicsShape2 .circleShape .small-circles-wrapper {
    width: 30px;
    height: 30px;
    background: #f7f7f7;
    border-radius: 50%;
    border: 6px solid #f9c401;
    padding: 3px;
    position: absolute;
}
.graphicsShape2 .circleShape .small-circles-wrapper .circles-2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #f9c401;
    border: 3px solid #fce876;
}
.graphicsShape2 .circleShape .small-circles-wrapper, .graphicsShape2 .circleShape .small-circles-wrapper * {
    box-sizing: border-box;
}
.graphicsShape2 .yellow .circleShape .small-circles-wrapper {
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    left: calc(100% + 10px);
}
.graphicsShape2 .parrotgreen .circleShape .small-circles-wrapper {
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    right: calc(100% + 10px);
}
.graphicsShape2 .navygreen .circleShape .small-circles-wrapper {
    top: 0;
    left: calc(100% + -6px);
}
.graphicsShape2 .blue .circleShape .small-circles-wrapper {
    top: 0;
    right: calc(100% + -6px);
}
.graphicsShape2 .red .circleShape .small-circles-wrapper {
    top: -36px;
    right: 24px;
}
.graphicsShape2 .pink .circleShape .small-circles-wrapper {
    top: -36px;
    left: 24px;
}
.slick-slider.graphicsShape2 .box-wrapper {
    position: static;
    width: auto;
}
.slick-slider.graphicsShape2 .box-wrapper .circleShape {
    margin: 20px auto;
}
.slick-slider.graphicsShape2 .box-wrapper .circleShape .small-circles-wrapper {
    display: none;
}
.slick-slider.graphicsShape2 .box-wrapper .content {
    max-width: 320px;
    margin: 0 auto;
}
.slick-slider.graphicsShape2 .box-wrapper .content * {
    text-align: center !important;
}
.slick-slider.graphicsShape2::before,
.slick-slider.graphicsShape2::after {
    display: none;
}
.slick-slider.graphicsShape2 {
    margin: 0;
    max-width: 100%;
}
/***Digital Marketing Page End***/

/* .service_category .section_title_space .title_row .head_title {
    margin-top: 40px;
    line-height: 1.5 !important;
}  */
.main_our_approach .two_coloumn_Below_Banner.layout_2 .right_info h3 {
    font-size: 26px !important;
}
.main_our_approach .two_coloumn_Below_Banner.layout_2 .right_info img {
    margin-left: 0;
    max-width: 100%;
}
.main_hireDevelopers .major_social_networks .major_networks_wrap .content_box {
    background-color: #f1f1f1;
}
form#hire-us-form {
    max-width: 1000px;
    margin: 0 auto;
}
span.des_count {
    font-size: 14px;
}
span.des_count.green {
    color: #6a8c6a;
}
span.des_count.red {
    color: red;
}
.hereToHelpYou > div {
    background-color: transparent;
    box-shadow: none;
}
.we-are-help-you .right_side img {
    max-width: 450px;
    margin: 0 auto;
    display: block;
    box-sizing: border-box;
    width: 100%;
}
.we-are-help-you .hereToHelpYou {
    padding: 50px 0;
}

.we-are-help-you .hereToHelpYou .expMain .expMainHead {
    padding: 0;
    border-bottom: 1px solid #dedede;
}

/* .we-are-help-you .hereToHelpYou .expMain .expMainHead div {
    margin: 0;
} */
.we-are-help-you .hereToHelpYou .expMain  .expMainContent {
    padding: 10px;
}

.we-are-help-you .hereToHelpYou .expMain {
    margin: 0;
}

#select-country:focus,
#select-hireList:focus {
    background-color: transparent !important;
}
.main_application_dev .major_social_networks .major_networks_wrap .content_box.middle_img img {
    width: 100%;
}
/* =======12-dec-2019-S-css-start =======*/
.main_hireDevelopers .content_box.middle_img img,
.main_application_dev .major_social_networks .major_networks_wrap .content_box.middle_img img{
    -webkit-transition:0.5s ease-in-out 0s all;
    -moz-transition:0.5s ease-in-out 0s all;
    transition:0.5s ease-in-out 0s all;
}
.main_hireDevelopers .content_box.middle_img img,
.main_application_dev .major_social_networks .major_networks_wrap .content_box.middle_img img {
    -moz-animation: rotate 20s  infinite;
    -webkit-animation: rotate 20s  infinite;
    animation: rotate 20s  infinite;
    animation-delay: 3s;
}
@-moz-keyframes rotate {
    from {
        -moz-transform: rotate(0deg);
    }
    to { 
        -moz-transform: rotate(360deg);
    }
}
@-webkit-keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
    }
    to { 
        -webkit-transform: rotate(360deg);
    }
}
@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to { 
        transform: rotate(360deg);
    }
}
/* =======green-color-overlay-effect ========*/
.FourColumnLayout1 .border_color_green.list_key_boxes:hover::before{
    -webkit-animation: pulse-green 1s ease-out infinite;
            animation: pulse-green 1s ease-out infinite;
  }
  
  @-webkit-keyframes pulse-green {
    from {
      box-shadow: 0 0 0 0 rgba(79, 153,53, 0.4);
    }
    to {
      box-shadow: 0 0 0 25px rgba(79, 153,53, 0);
    }
  }
  
  @keyframes pulse-green {
    from {
      box-shadow: 0 0 0 0 rgba(79, 153,53, 0.4);
    }
    to {
      box-shadow: 0 0 0 25px rgba(79, 153,53, 0);
    }
  }

/* =======pink-color-overlay-effect ========*/
  .FourColumnLayout1 .border_color_pink.list_key_boxes:hover::before{
    -webkit-animation: pulse-pink 1s ease-out infinite;
            animation: pulse-pink 1s ease-out infinite;
  }
  
  @-webkit-keyframes pulse-pink {
    from {
      box-shadow: 0 0 0 0 rgba(145, 53,153, 0.4);
    }
    to {
      box-shadow: 0 0 0 25px rgba(145, 53,153, 0);
    }
  }
  
  @keyframes pulse-pink {
    from {
      box-shadow: 0 0 0 0 rgba(145, 53,153, 0.4);
    }
    to {
      box-shadow: 0 0 0 25px rgba(145, 53,153, 0);
    }
  }
.technologies_multi_services_circle .img_wrap.text-center {
    position: relative;
    margin: 20px 0;
}

/* .technologies_multi_services_circle .img_wrap.text-center:before {
    position: absolute;
    content: 'Technologies';
    top: 50%;
    left: 50%;
    font-size: 44px;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    font-weight: 800;
    color: #444a60;
    text-transform: uppercase;
    font-family: 'Saira Extra Condensed', sans-serif !important;
} */
/* =======yellow-color-overlay-effect ========*/
  .FourColumnLayout1 .border_color_yellow.list_key_boxes:hover::before{
    -webkit-animation: pulse-yellow 1s ease-out infinite;
            animation: pulse-yellow 1s ease-out infinite;
  }
  
  @-webkit-keyframes pulse-yellow {
    from {
      box-shadow: 0 0 0 0 rgba(149, 149,56, 0.4);
    }
    to {
      box-shadow: 0 0 0 25px rgba(149, 149,56, 0);
    }
  }
  
  @keyframes pulse-yellow {
    from {
      box-shadow: 0 0 0 0 rgba(149, 149,56, 0.4);
    }
    to {
      box-shadow: 0 0 0 25px rgba(149, 149,56, 0);
    }
  }
  /* =======red-color-overlay-effect ========*/
  .FourColumnLayout1 .border_color_red.list_key_boxes:hover::before{
    -webkit-animation: pulse-red 1s ease-out infinite;
            animation: pulse-red 1s ease-out infinite;
  }
  
  @-webkit-keyframes pulse-red {
    from {
      box-shadow: 0 0 0 0 rgba(153,53,53, 0.4);
    }
    to {
      box-shadow: 0 0 0 25px rgba(153,53,53, 0);
    }
  }
  
  @keyframes pulse-red {
    from {
      box-shadow: 0 0 0 0 rgba(153,53,53, 0.4);
    }
    to {
      box-shadow: 0 0 0 25px rgba(153,53,53, 0);
    }
  }
.list_key_boxes {
    -webkit-transition: 0.5s ease-in-out 0s all;
    -moz-transition: 0.5s ease-in-out 0s all;
    -ms-transition: 0.5s ease-in-out 0s all;
    transition: 0.5s ease-in-out 0s all;
}
.FourColumnLayout1 .border_color_green.list_key_boxes:hover {
    box-shadow: 0 0 10px rgba(79, 153,53, 0.4);
}
.FourColumnLayout1 .border_color_pink.list_key_boxes:hover{
    box-shadow: 0 0 10px rgba(145, 53,153, 0.4);
}
.FourColumnLayout1 .border_color_yellow.list_key_boxes:hover{
    box-shadow: 0 0 10px rgba(149, 149,56, 0.4);
}
.FourColumnLayout1 .border_color_red.list_key_boxes:hover{
    box-shadow: 0 0 10px rgba(153,53,53, 0.4);
}

.services-detail-content .content-wrapper p strong {
    font-weight: 600;
}

/* =======12-dec-2019-S-css-end =======*/

/* =========13-dec-2019-S-css-start=========== */
.FourColumnLayout1 .border_color_green.list_key_boxes:hover::after {
    filter:drop-shadow(0px 3px 2px rgba(79, 153,53, 0.2));
}
.FourColumnLayout1 .border_color_pink.list_key_boxes:hover::after{
    filter:drop-shadow(0px 3px 2px rgba(145, 53,153, 0.2));
}
.FourColumnLayout1 .border_color_yellow.list_key_boxes:hover::after{
    filter:drop-shadow(0px 3px 2px rgba(149, 149,56, 0.2));
}
.FourColumnLayout1 .border_color_red.list_key_boxes:hover::after{
    filter:drop-shadow(0px 3px 2px rgba(153,53,53, 0.2));
}
/* .buttonStyleFxd {
    position: fixed;
    top: 50%;
    left: 4px;
    transform: rotate(-90deg) translateX(-50%);
    transform-origin: left center;
    z-index: 999;
    transition: all 0.5s ease-in-out;
} */
.buttonStyleFxd {
    position: fixed;
    top: 40%;
    left: 12px;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    z-index: 999;
    transition: all 0.5s ease-in-out;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
}
.buttonStyleFxd:hover {
    left: 18px;
}
/* .buttonStyleFxd .buttonLinkGet {
    background-color: #ed402c;
    color: #fff;
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    text-transform: capitalize;
    font-weight: 500;
    letter-spacing: 0.4px;
    padding: 25px 15px 8px;
    display: inline-block;
    border-radius: 4px;
    cursor: pointer;
} */
.buttonStyleFxd .buttonLinkGet {
    background-color: #ed402c;
    color: #fff;
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    text-transform: capitalize;
    font-weight: 500;
    letter-spacing: -7px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 4px;
    cursor: pointer;
    /* writing-mode: vertical-rl;
    text-orientation: upright; */
    padding: 12px;
    padding-right: 6px;
}
.innerContentButton,
span.upSlide i,
.buttonAdjustToggle span.upSlide,
.buttonStyleFxd a,
.buttonAdjustToggle{
    -webkit-transition: 0.5s ease-in-out 0s all;
    -moz-transition: 0.5s ease-in-out 0s all;
    -ms-transition: 0.5s ease-in-out 0s all;
    transition: 0.5s ease-in-out 0s all;
}
.buttonAdjustToggle {
    display: inline-block;
    background-color: #ed402c;
    position: absolute;
    right: 38%;
    top: 20%;
    z-index: -1;
    cursor: pointer;
    width: 60px;
    height: 60px;
    border-radius: 50%;
}
.buttonAdjustToggle span.upSlide {
    position: absolute;
    right: 19px;
    top: 30px;
    color: #fff;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    display: block;
}
/* .buttonAdjustToggle span.upSlide i{
    background-color: transparent;
    font-size: 20px;
}
.buttonStyleFxd .innerContentButton {
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
}
.buttonStyleFxd.active .innerContentButton {
    -webkit-transform: translateY(0%);
    -ms-transform: translateY(0%);
    transform: translateY(0%);
}
.buttonStyleFxd.active span.upSlide {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    right: 22px;
} */
.buttonStyleFxd.active:hover .buttonAdjustToggle,
.buttonStyleFxd.active:hover .buttonLinkGet,
.buttonStyleFxd .buttonLinkGet:hover{
    background-color: #444a60;
}
.buttonAdjustToggle {
    -webkit-animation: button-pulse 1s infinite;
            animation: button-pulse 1s infinite;
  }
  @-webkit-keyframes button-pulse {
    from {
      box-shadow: 0 0 0 0  rgba(237, 64, 44,0.6);
    }
    to {
      box-shadow: 0 0 0 15px rgba(237, 64, 44,0);
    }
  }
  
  @keyframes button-pulse {
    from {
      box-shadow: 0 0 0 0 rgba(237, 64, 44,0.6);
    }
    to {
      box-shadow: 0 0 0 15px rgba(237, 64, 44,0);
    }
  }
.buttonStyleFxd.active .buttonAdjustToggle{
    -webkit-animation: none;
    animation: none;
    display: none;
}
.buttonStyleFxd .buttonAdjustToggle{
    display: none;
}
.buttonStyleFxd.hide {
    transform: translateX(-200%);
    -webkit-transform: translateX(-200%);
    -ms-transform: translateX(-200%);
    -moz-transform: translateX(-200%);
    left: 0;
}


/* =========13-dec-2019-S-css-end=========== */

.btn-wrap a.dark-btn-effect {
    margin-top: 12px;
}
.btn-wrap .dark-btn-effect{
    position: relative;
    overflow: hidden;
    z-index: 1;
    border:1px solid #ed402c;
}
.btn-wrap .dark-btn-effect:hover{
    background-color:transparent;
    border:1px solid #444a60;
}
.btn-wrap .dark-btn-effect::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #444a60;
    z-index: -1;
    transition: transform 0.4s;
    transform: scale(0);
}
.btn-wrap .dark-btn-effect:hover::before {
    transform: scale(1);
}
.btn-wrap {
    text-align: center;
}
/* .serviceCatPortfolioSlider2 .slick-slider .slick-arrow {
    top: inherit;
    bottom: 70px;
    z-index: 6;
    margin: 0 auto;
    left: 0;
    right: 0;
}
.serviceCatPortfolioSlider2 .slick-slider .slick-prev {
    left: calc(0px - 30px) !important;
}
.serviceCatPortfolioSlider2 .slick-slider .slick-next {
    right: calc(0px - 30px) !important;
}
.serviceCatPortfolioSlider2 .slick-prev:before, .serviceCatPortfolioSlider2 .slick-next:before {
    color: #e05640 !important;
}
.serviceCatPortfolioSlider2.serviceCatPortfolioSlider3 .slick-slider .slick-arrow {
    bottom: 95px;
} */
#magento-development .serviceCatPortfolioSlider2 aside a:hover .content_wrap .link {
    color: #b3b3b3;
}
/* #magento-development .serviceCatPortfolioSlider2 button.slick-arrow::before {
    color: #fff !important;
} */
.we-are-help-you .hereToHelpYou p {
    font: 400 14px/1.8 Poppins;
}

.we-are-help-you .hereToHelpYou strong {
    font-weight: 600;
    font-size: 15px;
}
.we-are-help-you .hereToHelpYou ::before {
    display: none;
}
#mevn-stack-development {
    padding-bottom: 60px;
}
#magento-extension-development .list_key_boxes .all-category-mobile {
    margin: 0;
    font-size: 12px;
    padding: 5px 15px;
}   
.main_hireDevelopers .title_row .head_title {
    margin-bottom: 15px;
}
.main_pageNotFound .errorPageContent .head_title {
    color: #ed402d !important;
    line-height: 1 !important;
    font-family: 'Josefin Sans' !important;
    font-size: 54px !important;
    margin: 0 !important;
}
.main_pageNotFound .errorPageContent .head_paragraph a {
    font-size: 16px;
    color: grey;
}
.main_pageNotFound .errorPageContent .head_paragraph a:hover {
    color: #ed402d;
}
.main_pageNotFound .errorPageContent .head_paragraph a i.material-icons {
    transform: translate(0px, 6px);
    -webkit-transform: translate(0px, 6px);
    -moz-transform: translate(0px, 6px);
    -ms-transform: translate(0px, 6px);
}
.main_pageNotFound .errorPageContent {
    height: 50vh;
    display: flex;
    display: -ms-flexbox;
    align-items: center;
}
.why_hire_us .head_title, .we-are-help-you .head_title {
    line-height: normal !important;
}
.serviceCatPortfolioSlider2 aside .link.btn {
    background-color: #444a60;
    color: #fff;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 12px;
}
.serviceCatPortfolioSlider2 aside .link.btn:hover, .serviceCatPortfolioSlider2 aside a:hover .link.btn {
    background-color: #000000;
    color: #fff !important;
}
.contact_page_wrapper span.des_count.green, .apply_form_wrapper span.des_count.green, .get-free-quote-wrapper span.des_count.green {
    margin-top: -10px;
    display: block;
    margin-bottom: 10px;
}
#openCart-development .six-services .circle_wrapper .icon_wrap img {
    width: 50px;
    height: 50px;
}
.hire-us-form .sec_padding {
    padding-top: 0;
}
.main_hireDevelopers .inner-fields .country_custom > div:nth-child(3) {
    background-color: #fff;
    z-index: 999;
}
.serviceCatPortfolioSlider2 .custom_slider_wrap {
    display: flex;
    display: -ms-flexbox;
    justify-content: center;
    flex-wrap: wrap;
}
.serviceCatPortfolioSlider2 .custom_slider_wrap > div {
    padding: 15px;
    max-width: 420px;
    width: 100%;
}
.serviceCatPortfolioSlider2 .custom_slider_wrap .website_img {
    position: relative;
    padding-top: 14px;
}
.serviceCatPortfolioSlider2 .custom_slider_wrap .website_img img {
    width: 300px;
    margin: 0 auto;
    display: block;
}
.serviceCatPortfolioSlider2 .custom_slider_wrap aside {
    position: relative;
}
.serviceCatPortfolioSlider2 .custom_slider_wrap .website_img::before {
    content: "";
    background-image: url(../images/WebsiteDesignDevelopment/laptop-portfolio-middle.png);
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    width: 100%;
    height: 225px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    pointer-events: none;
}
.serviceCatPortfolioSlider2 .custom_slider_wrap .content_wrap {
    margin-top: 40px;
}
.serviceCatPortfolioSlider2.serviceCatPortfolioSlider3 .custom_slider_wrap > div {
    max-width: 250px;
}
.serviceCatPortfolioSlider2.serviceCatPortfolioSlider3 .custom_slider_wrap .website_img::before {
    background-image: url('../images/ApplicationDevelopment/mobile_frame.png');
    height: calc(100% + 5px);
}
.serviceCatPortfolioSlider2.serviceCatPortfolioSlider3 .custom_slider_wrap aside {
    padding: 0;
}
.serviceCatPortfolioSlider2.serviceCatPortfolioSlider3 .custom_slider_wrap aside .btnDownloadWrap img {
    filter: invert(1);
}
.serviceCatPortfolioSlider2.serviceCatPortfolioSlider3 .custom_slider_wrap aside .btnDownloadWrap a:hover img {
    filter: invert(1) drop-shadow(2px 4px 6px #e05640);
}
.serviceCatPortfolioSlider2.serviceCatPortfolioSlider3 .custom_slider_wrap .website_img {
    padding: 0 7px;
    padding-top: 14px;
}
.aboutus_main .why_hire_us .all-category-mobile {
    margin: 35px auto;
}
/* #mern-stack-development .serviceCatPortfolioSlider2 .slick-arrow:before {
    color: #fff !important;
} */
.main_hireDevelopers p strong {
    font-weight: 600;
}
.custom_select_tag #select-hireList {
    color: #757575;
    display: flex;
    flex-wrap: wrap;
}
.custom-checkbox-hire {
    color:#ed402c !important;
}
form .inner-fields input, form .inner-fields textarea, form .inner-fields select {
    color: #757575;
}
.main_hireDevelopers .inner-fields .country_custom > div:nth-of-type(1) {
    min-height: 48px;    
    cursor: pointer;
}
.main_hireDevelopers .inner-fields .country_custom > div:nth-of-type(1):hover {
    border-color: #000;
}
.main_hireDevelopers .inner-fields .country_custom > .css-1pahdxg-control {
    border-color: #3f51b5;
    box-shadow: 0px 0px 0px 1px #3f51b5;
}
.main_hireDevelopers .inner-fields .country_custom > div:nth-of-type(1) .css-1hwfws3 .css-1uccc91-singleValue {
    color: #757575;
}
.number_count {
    background: #fbfbfb;
}
.main_server_admin #number_count {
    background: #fff;
}

/****CSS Start For InnerPagesBelowBannerTwoColumn_Layout_3***/
.max-width-655 {
    max-width: 655px;
}
.ml-auto {
    margin-left: auto;
}
.two_coloumn_Below_Banner_layout_3 .left_side {
    height: 100%;
    display: flex;
    display: -ms-flexbox;
    flex-direction: column;
    justify-content: center;
}
.two_coloumn_Below_Banner_layout_3 .left_side, .two_coloumn_Below_Banner_layout_3 .right_side {
    padding: 0 15px;
    box-sizing: border-box;
}
.head_paragraph strong {
    font-weight: 600;
}
.two_coloumn_Below_Banner_layout_3 ul {
    padding-left: 20px;
    list-style: none;
}
.two_coloumn_Below_Banner_layout_3 ul li {
    position: relative;
    display: inline-block;
}
.two_coloumn_Below_Banner_layout_3 ul li::before {
    content: "";
    width: 8px;
    height: 8px;
    background: #ed402d;
    border-radius: 40px;
    position: absolute;
    left: -15px;
    top: 12px;
}
.two_coloumn_Below_Banner_layout_3 .img_wrap img {
    width: 100%;
    vertical-align: middle;
}
.two_coloumn_Below_Banner_layout_3 .right_side .img_wrap {
    padding-left: 50px;
    margin-top: -110px;
    position: relative;
}
.two_coloumn_Below_Banner_layout_3 .right_side .left-aligned-text {
    font-size: 17px;
    line-height: 1.2;
    color: #151c37;
    font-family: 'Poppins' !important;
    font-weight: 600;
    text-transform: uppercase;
    display: inline-block;
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    transform-origin: 0 0px;
    position: absolute;
    bottom: -20px;
    left: 15px;
    box-sizing: border-box;
}
.two_coloumn_Below_Banner_layout_3 .contentWrap {
    display: flex;
    display: -ms-flexbox;
    align-items: baseline;
    z-index: 1;
    position: relative;
}
.two_coloumn_Below_Banner_layout_3 .contentWrap p.blue_box {
    font-size: 20px;
    line-height: 1.3;
    color: #fff;
    font-family: 'Poppins' !important;
    font-weight: 500;
    background-color: #002d77;
    display: inline-block;
    padding: 30px 20px;
    width: 300px;
    box-sizing: border-box;
    margin-right: 15px;
}
.two_coloumn_Below_Banner_layout_3 .contentWrap p.red_box {
    font-size: 17px;
    line-height: 1.2;
    color: #ffffff;
    font-family: 'Poppins' !important;
    font-weight: 600;
    background-color: #f34934;
    display: inline-block;
    padding: 8px 20px;
    box-sizing: border-box;
}

/****CSS End For InnerPagesBelowBannerTwoColumn_Layout_3***/

.custom-chips {
    margin: 0px 5px 5px 0px;
}

.sec_padding.privacy-page {
    width: 100%;
}

.sec_padding.privacy-page .privacy_title {
    text-align: left;
    font-size: 40px;
    line-height: 1.3;
    letter-spacing: 0.6px;
    margin: 0 0 0px 0;
    font-family: 'Saira Extra Condensed', sans-serif;
    font-weight: bold;
    color: #33384a;
    display: block;
    width: 100%;
}
.sec_padding.privacy-page .privacy_text {
    margin: 0 0 20px 0;
}
.drag_drop_files .dropzone {
    background: #f7f7f7;
    border: 2px dashed #bbb;
}
.drag_drop_files .dropzone:focus {
    outline: none;
}
.drag_drop_files .dropzone p {
    margin: 0;
    text-align: center;
    padding: 20px;
    cursor: pointer;
    color: #757575;
    font-size: 16px;
}
.drag_drop_files .dropzone:hover {
    background: #f9f1f1;
}
.drag_drop_files p.note_condition {
    font-size: 12px;
    color: #6b6b6b;
    margin: 5px 0;
}
.bg-darkblue .slick-arrow::before {
    color: #fff !important;
}
.bg-darkblue .serviceCatPortfolioSlider3 aside .content_wrap .btnDownloadWrap a img {
    filter: invert(1);
}
.bg-darkblue .serviceCatPortfolioSlider3 aside .content_wrap .btnDownloadWrap a:hover img {
    filter: invert(1) drop-shadow(2px 4px 6px #e05640);
}
.bannerDesigningSlider .img_wrap img {
    padding: 0 40px;
    margin: 0 auto;
}
.bannerDesigningSlider {
    margin-bottom: 15px;
}
#magento-extension-development.key_benfits_and_why_choose .boxes_listing {
    padding-bottom: 25px;
}
#ios-app-development .major_networks_wrap > div:nth-child(2) .content_box:nth-child(1) {
    z-index: 2;
}
.slick-prev:before, .slick-next:before {
    color: #e05640 !important;
    font-size: 30px !important;
    content: "" !important;
    opacity: 1 !important;
    display: inline-block;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    background-image: url("../images/slider_colored_arrows.png");
    background-size: cover;
}
.slick-prev:before {
    background-position: 3px; 
}
.slick-next:before {
    background-position: -30px;
}
.slick-prev {
    left: -800px !important;
}
.slick-slider:hover .slick-prev {
    left: 34px !important;
}
.slick-arrow {
    z-index: 5;
    width: 40px !important;
    height: 40px !important;
    border-radius: 50%;
    transition: 0.5s;
}
.slick-next {
    right: -800px !important;
}
.slick-slider:hover .slick-next {
    right: 34px !important;
}
.serviceCatPortfolioSlider2 .slick-slider {
    padding: 0;
}
.serviceCatPortfolioSlider2 .slick-slider .slick-arrow {
    top: 36%;
}
.serviceCatPortfolioSlider2 .slick-slider .slick-prev {
    left: -800px !important ;
}
.serviceCatPortfolioSlider2 .slick-slider:hover .slick-prev {
    left: 10px !important ;
}
.serviceCatPortfolioSlider2 .slick-slider .slick-next {
    right: -800px !important;
}
.serviceCatPortfolioSlider2 .slick-slider:hover .slick-next {
    right: 10px !important;
}
.serviceCatPortfolioSlider2.serviceCatPortfolioSlider3 .slick-slider:hover .slick-prev {
    left: 20px !important;
}
.serviceCatPortfolioSlider2.serviceCatPortfolioSlider3 .slick-slider:hover .slick-next {
    right: 20px !important;
}
.finest-project .mobile-small-wrapper .slick-slider {
    padding: 0;
}
.finest-project .slick-slider .slick-prev {
    left: -800px !important;
}
.finest-project:hover .slick-slider .slick-prev {
    left: 40px !important;
}
.finest-project .slick-slider .slick-next {
    right: -800px !important;
}
.finest-project:hover .slick-slider .slick-next {
    right: 40px !important;
}
.bannerDesigningSlider .slick-slider, .broucherSlider .slick-slider {
    padding: 0 70px;
}
.main_hireDevelopers .hirDeveloperAppSize{
    max-width:900px;
    margin: 0 auto;
}
.loader-style-form {
    float: right;
    display: table-cell;
    margin: 8px 10px 0 0;
}
.form-fields {
    display: table!important;
}
.loader-style-form {
    float: right;
    margin: 8px 25px 0 0;
}
.loader-style-form  svg circle {
    color: #e05640;
}
.note_condition{
    display: flex;
    display: -ms-flexbox;
}
.note_condition strong {
    padding-right: 5px;
    min-width: 40px;
}
.mainContentNote > span{
    display: block;
    margin-bottom: 2px;
}
.main_hireDevelopers .hirDeveloperAppSize.hireDeveloperStyleSize{
    max-width: 100%;
}

.font-arial {
    font-family: arial !important;
}

/* .slick-next:before {
    background-position: -28px;
} */

/*****Artificial Intelligence Services Start****/
.ai-list-wrap ul {
    list-style: none;
    padding: 0;
    display: flex;
    display: -ms-flexbox;
    flex-wrap: wrap;
    justify-content: center;
}
.ai-list-wrap ul li {
    width: 20%;
}
.ai-list-wrap ul li .media .content-box-wrap {
    padding: 0 5px;
}
.ai-list-wrap ul li .media .content-box-wrap .head_title {
    font-size: 26px !important;
}
.ai-list-wrap ul li .media .content-box-wrap .head_paragraph {
    font-size: 14px !important;
    line-height: 1.8 !important;
}
.ai-list-wrap ul li .media .icon-box-wrap {
    border-bottom: 2px solid #8c8c8c;
    margin-bottom: 30px;
    padding: 25px;
    position: relative;
}
.ai-list-wrap ul li .media .icon-box-wrap .small-btm-circle {
    width: 25px;
    height: 25px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    bottom: -13px;
    left: 0;
    right: 0;
    margin: 0 auto;
    border: 2px solid #8c8c8c;
}
.ai-list-wrap ul li .media .icon-box-wrap .small-btm-circle span.dot {
    display: inline-block;
    background: #febc05;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 3px solid #fff;
}
.ai-list-wrap ul li .media .icon-box-wrap .icon-box-wrap-step1 {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin: 0 auto 25px;
    border: 2px solid #8c8c8c;
    position: relative;
    transition: 0.5s;
}
.ai-list-wrap ul li .media .icon-box-wrap .icon-box-wrap-step1::before {
    content: "";
    width: 25px;
    height: 25px;
    background: #fff;
    border: 2px solid #8c8c8c;
    position: absolute;
    bottom: -14px;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: scaleX(0.7) rotate(45deg);
    z-index: -1;
    transition: 0.5s;
}
.ai-list-wrap ul li:hover .media .icon-box-wrap .icon-box-wrap-step1, .ai-list-wrap ul li:hover .media .icon-box-wrap .icon-box-wrap-step1::before {
    border-color: #f8bc04;
}
.ai-list-wrap ul li .media .icon-box-wrap .icon-box-wrap-step2 {
    width: 100%;
    height: 100%;
    background: #fff;
    padding: 20px;
    border-radius: 50%;
    position: relative;
    transition: 0.5s;
}
.ai-list-wrap ul li:hover .media .icon-box-wrap .icon-box-wrap-step2 {
    padding: 15px;
}
.ai-list-wrap ul li .media .icon-box-wrap .icon-box-wrap-step2::before {
    content: "";
    width: calc(100% - 10px);
    height: 50%;
    background: transparent;
    border-top-right-radius: 90px;
    border-top-left-radius: 90px;
    position: absolute;
    border-width: 12px 12px 0px 12px;
    border-style: solid;
    border-color: #f8bc04;
    left: -7px;
    top: -7px;
}
.ai-list-wrap ul li .media .icon-box-wrap .icon-wrapper {
    background: linear-gradient(0deg, #fdbb05, #ffed00);
    text-align: center;
    border-radius: 50%;
    height: 100%;
    width: 100%;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.3);
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    justify-content: center;
}
.ai-list-wrap ul li .media .icon-box-wrap .icon-wrapper img {
    max-width: 50px;
    width: 100%;
}
.ai-list-wrap ul li.yellow .media .icon-box-wrap .icon-wrapper {
    background: linear-gradient(0deg, #febc05, #ffed00);
}
.ai-list-wrap ul li.yellow .media .icon-box-wrap .icon-box-wrap-step2::before {
    border-color: #febc05;
}
.ai-list-wrap ul li.yellow .media .icon-box-wrap .small-btm-circle span.dot {
    background: #febc05;
}
.ai-list-wrap ul li.yellow:hover .media .icon-box-wrap .icon-box-wrap-step1, .ai-list-wrap ul li.yellow:hover .media .icon-box-wrap .icon-box-wrap-step1::before, .ai-list-wrap ul li.yellow:hover .media .icon-box-wrap .small-btm-circle {
    border-color: #febc05;
}
.ai-list-wrap ul li.redpink .media .icon-box-wrap .icon-wrapper {
    background: linear-gradient(0deg, #e14679, #f5aec7);
}
.ai-list-wrap ul li.redpink .media .icon-box-wrap .icon-box-wrap-step2::before {
    border-color: #e14679;
}
.ai-list-wrap ul li.redpink .media .icon-box-wrap .small-btm-circle span.dot {
    background: #e14679;
}
.ai-list-wrap ul li.redpink:hover .media .icon-box-wrap .icon-box-wrap-step1, .ai-list-wrap ul li.redpink:hover .media .icon-box-wrap .icon-box-wrap-step1::before, .ai-list-wrap ul li.redpink:hover .media .icon-box-wrap .small-btm-circle {
    border-color: #e14679;
}
.ai-list-wrap ul li.navyblue .media .icon-box-wrap .icon-wrapper {
    background: linear-gradient(0deg, #4786d4, #6b92fe);
}
.ai-list-wrap ul li.navyblue .media .icon-box-wrap .icon-box-wrap-step2::before {
    border-color: #4786d4;
}
.ai-list-wrap ul li.navyblue .media .icon-box-wrap .small-btm-circle span.dot {
    background: #4786d4;
}
.ai-list-wrap ul li.navyblue:hover .media .icon-box-wrap .icon-box-wrap-step1, .ai-list-wrap ul li.navyblue:hover .media .icon-box-wrap .icon-box-wrap-step1::before, .ai-list-wrap ul li.navyblue:hover .media .icon-box-wrap .small-btm-circle {
    border-color: #4786d4;
}
.ai-list-wrap ul li.green .media .icon-box-wrap .icon-wrapper {
    background: linear-gradient(0deg, #00b793, #66e9ae);
}
.ai-list-wrap ul li.green .media .icon-box-wrap .icon-box-wrap-step2::before {
    border-color: #00b793;
}
.ai-list-wrap ul li.green .media .icon-box-wrap .small-btm-circle span.dot {
    background: #00b793;
}
.ai-list-wrap ul li.green:hover .media .icon-box-wrap .icon-box-wrap-step1, .ai-list-wrap ul li.green:hover .media .icon-box-wrap .icon-box-wrap-step1::before, .ai-list-wrap ul li.green:hover .media .icon-box-wrap .small-btm-circle {
    border-color: #00b793;
}
.ai-list-wrap ul li.skyblue .media .icon-box-wrap .icon-wrapper {
    background: linear-gradient(0deg, #1291d1, #81d8ff);
}
.ai-list-wrap ul li.skyblue .media .icon-box-wrap .icon-box-wrap-step2::before {
    border-color: #1291d1;
}
.ai-list-wrap ul li.skyblue .media .icon-box-wrap .small-btm-circle span.dot {
    background: #1291d1;
}
.ai-list-wrap ul li.skyblue:hover .media .icon-box-wrap .icon-box-wrap-step1, .ai-list-wrap ul li.skyblue:hover .media .icon-box-wrap .icon-box-wrap-step1::before, .ai-list-wrap ul li.skyblue:hover .media .icon-box-wrap .small-btm-circle {
    border-color: #1291d1;
}
/*****Artificial Intelligence Services End****/



/*****Responsive Start****/
@media (min-width:600px) {
    .logoSlider aside .logo_img.hidden {
        visibility: hidden;
    }
}
@media (min-width:1200px) {
    .services-detail-content.blue-color.only-center .image-wrap img {
        max-width: 100%;
        width: 450px;
    }
    .services-detail-content.blue-color.only-center + div {
        padding-top: 100px;
    }
    /* .main_services .services-listing {
        top: 100px;
    }    */
    /* .main_services .serviceListWrapper .services-detail-content {
        min-height: calc(100vh - 75px);
    } */
    /* .section_title_space {
        min-height: calc(100vh - 75px);
    } */
}

@media (min-width:1280px) {
    .container_custom.team_description_block {
        max-width: 1310px;
    }
    /* .FourColumnLayout1 .boxes_key:not(:nth-child(1n+4)) {
        padding-right: 25px;
    } */
    .TwoColumnLayout1 .first_col {
        padding-right: 40px;
    }
    .TwoColumnLayout1 .second_col {
        padding-left: 40px;
    }
    .FourColumnLayout1 .list_key_boxes {
        min-height: 280px;
        height: calc(100% - 0px);
    }
    /* .FourColumnLayout3 .boxes_wrapper .boxes_key:nth-child(1) {
        padding-left: 0;
    } */
    .FourColumnLayout3 .boxes_wrapper .boxes_key:nth-child(4) {
        padding-right: 0;
    }
    .two_coloumn_Below_Banner .right_info {
        min-height: 550px;
    }
    .two_coloumn_Below_Banner.layout_2 .left_info {
        padding-top: 100px;
    }
    .two_coloumn_Below_Banner.layout_2 .right_info {
        padding: 100px 75px;
    }
    .main_broucher_design .major_social_networks .major_networks_wrap .content_box .icon {
        display: inline-block;
    }
    .main_broucher_design .major_social_networks .major_networks_wrap .aside.left img.icon {
        position: absolute;
        right: 0;
        top: 25px;
        max-width: 64px;
    }
    .main_broucher_design .major_social_networks .major_networks_wrap .aside.right img.icon {
        position: absolute;
        left: 0;
        top: 25px;
        max-width: 64px;
    }
    .main_broucher_design .major_social_networks .major_networks_wrap .aside.left .content_box {
        padding-right: 60px;
    }
    .main_broucher_design .major_social_networks .major_networks_wrap .aside.right .content_box {
        padding-left: 60px;
    }
    .main_broucher_design .major_social_networks .major_networks_wrap .content_box.middle_img {
        min-height: 470px;
        display: flex;
        display: -webkit-flex;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        align-items: center;
        justify-content: center;
    }
    .main_broucher_design .major_social_networks .major_networks_wrap .middle_box .content_box:first-child {
        display: flex;
        display: -webkit-flex;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .main_broucher_design .major_social_networks .major_networks_wrap .middle_box .content_box:first-child img.icon {
        order: 3;
        margin: 25px 0 0;
    }
    .main_broucher_design .major_social_networks .major_networks_wrap {
        position: relative;
    }
    .main_broucher_design .major_social_networks .major_networks_wrap::before {
        content: "";
        border: 2px dashed #ddd;
        width: 900px;
        height: 800px;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
    }
    .main_web_dev .FourColumnLayout1 .boxes_wrapper::after {
        top: calc(100% + 37px);
    }
    .main_application_dev .major_social_networks .major_networks_wrap .content_box .icon {
        display: inline-block;
    }
    .main_application_dev .major_social_networks .major_networks_wrap .content_box.middle_img {
        display: flex;
        display: -ms-flexbox;
        height: 100%;
        align-items: center;
    }
    .main_application_dev .major_social_networks .major_networks_wrap {
        position: relative;
    }
    .main_application_dev .major_social_networks .major_networks_wrap::before {
        content: "";
        width: 55%;
        height: 85%;
        border-radius: 50%;
        border: 2px dashed #bbb;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 3;
        pointer-events: none;
    }
    .main_application_dev .major_social_networks .major_networks_wrap .aside .content_box {
        z-index: 3;
    }
    #print-media-design .graphicsShape1, #local-seo .graphicsShape1 {
        display: none;
    }
    .slick-slider.graphicsShape2 {
        display: none;
    }
    .main_hireDevelopers .two_coloumn_Below_Banner.layout_2 .left_info {
        padding-top: 0;
    }
    .two_coloumn_Below_Banner_layout_3 .right_side {
        padding-right: 0px;
    }
    .two_coloumn_Below_Banner_layout_3.sec_padding {
        padding: 60px 0 40px;
        max-width: 1920px;
        margin: 0 auto;
    }
    .major_social_networks .major_networks_wrap .content_box .icon {
        display: inline-block;
        margin: 0;
    }
    .major_networks_wrap .content_box img.icon {
        position: absolute;
    }
    .major_networks_wrap > div:nth-child(1) .content_box, .major_networks_wrap > div:nth-child(3) .content_box, .major_networks_wrap > div:nth-child(2) .content_box:nth-child(1), .major_networks_wrap > div:nth-child(2) .content_box:nth-child(3) {
            z-index: 3;
    }
    .major_networks_wrap > div:nth-child(1) .content_box:nth-child(1) img.icon {
        right: -56px;
        bottom: 12px;
    }
    .major_networks_wrap > div:nth-child(3) .content_box:nth-child(1) img.icon {
        left: -56px;
        bottom: 0px;
    }
    .major_networks_wrap > div:nth-child(1) .content_box:nth-child(2) img.icon {
        right: -56px;
        top: 12px;
    }
    .major_networks_wrap > div:nth-child(3) .content_box:nth-child(2) img.icon {
        left: -56px;
        top: 0px;
    }
    .major_networks_wrap > div:nth-child(2) .content_box:nth-child(1) img.icon {
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 90%;
    }
    .major_networks_wrap > div:nth-child(2) .content_box:nth-child(3) img.icon {
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: 90%;
    }
    /***OverRight CSS**/
    #banner-designing .major_networks_wrap > div:nth-child(3) .content_box:nth-child(2) img.icon {
        top: 25px;
    }
    #banner-designing .major_networks_wrap > div:nth-child(3) .content_box:nth-child(1) img.icon {
        bottom: -20px;
    }
    #shopify-development .circle_wrapper.icon {
        display: none;
    }
    #ios-app-development .major_networks_wrap .content_box img.icon {
        position: static;
        margin-bottom: 10px;
    }    
}

@media (min-width:1440px){
    .main_services .services-listing .toggle-btn {
        display: none;
    }
    .main_services .services-listing {
        right: 0px;
    }
}

@media (min-width:1600px) {
    .container_custom {
        max-width: 1310px;
    }
    .services-detail-content.blue-color.only-center .image-wrap img {
        max-width: 100%;
        width: 500px;
    }
}
@media (min-width:960px) and (max-width:1366px) {
    .number_count .count_list .media .count_text_box h3 {
        font-size: 36px;
    }
    .number_count .count_list .media .icon_box .icon_wrap {
        width: 70px;
        height: 70px;
    }
}

@media (min-width:1024px) and (max-width:1366px) {
    .two_coloumn_Below_Banner .right_info {
        padding: 80px 35px;
    }
}

@media (min-width:1150px) {
    .why_hire_us .head_paragraph {
        margin-bottom: 50px !important;
    }
}

@media (min-width:1280px) and (max-width:1599.98px) {
    .main_services .services-listing{
        width: 95px;
    }
    .main_services .services-listing .img-txt-wrap p {
        font-size: 13px;
    }
    .main_services .services-listing .img-txt-wrap .img-wrap img {
        height: 24px;
        width: 24px;
    }
    .main_services .services-listing .img-txt-wrap {
        padding: 10px 0;
    }
    .main_services .services-listing .row-flex .col-flex.active::after {
        height: 95px;
        width: 100px;
    }
    .main_services .services-listing .row-flex.tab-1::before {
        top: -7px;
    }
    .main_services .services-listing .row-flex.tab-2::before {
        top: 72px;
    }
    .main_services .services-listing .row-flex.tab-3::before {
        top: 152px;
    }
    .main_services .services-listing .row-flex.tab-4::before {
        top: 231px;
    }
    .main_services .services-listing .row-flex.tab-5::before {
        top: 308px;
    }
    .main_services .services-listing .row-flex.tab-6::before {
        top: 316px;
    }
    .two_coloumn_Below_Banner_layout_3 .contentWrap p.blue_box {
        font-size: 17px;
        padding: 20px 10px;
        width: 210px;
    }
    .two_coloumn_Below_Banner_layout_3 .right_side .img_wrap {
        margin-top: -90px;
    }
    .two_coloumn_Below_Banner_layout_3 .right_side .left-aligned-text {
        font-size: 14px;
        bottom: -18px;
        left: 20px;
    }
}

@media (min-width:1024px) and (max-width:1150px) {
    .why_hire_us .lists_hire_us li {
        margin-bottom: 10px;
    }
}

/* @media (min-width:992px) and (max-width:1439.98px) {
    .container_custom {
        max-width: 90%;
    }
} */

@media (min-width:992px) and (max-width:1310px) {
    .serviceCatPortfolioSlider2:not(.serviceCatPortfolioSlider3) {
        max-width: 960px;
        margin: 0 auto;
    }
    .serviceCatPortfolioSlider2:not(.serviceCatPortfolioSlider3) .slick-list::before {
        width: 436px;
        height: 255px;
    }
    .serviceCatPortfolioSlider2:not(.serviceCatPortfolioSlider3) .slick-list {
        padding-top: 14px !important;
    }
    .serviceCatPortfolioSlider2:not(.serviceCatPortfolioSlider3) .slick-slider::before, .serviceCatPortfolioSlider2:not(.serviceCatPortfolioSlider3) .slick-slider::after  {
        height: 197px;
        top: 14px;
    }
    .sliderNavForDesign .mobileSlider {
        width: 75px;
        height: 158px;
        overflow: hidden;
        position: absolute;
        top: 70px;
        left: 0;
        right: -300px;
        margin: 0 auto;
        z-index: 1;
    }
    .sliderNavForDesign .mobileSlider .slick-list {
        padding-top: 18px !important;
    }
    /* .serviceCatPortfolioSlider2.serviceCatPortfolioSlider3 .slick-slider::before, .serviceCatPortfolioSlider2.serviceCatPortfolioSlider3 .slick-slider::after {
        height: 331px;
        top: 10px;
        width: 40.6%;
    } */
    /* .serviceCatPortfolioSlider2.serviceCatPortfolioSlider3 .slick-list {
        padding-top: 10px !important;
    } */
    /* .serviceCatPortfolioSlider2.serviceCatPortfolioSlider3  .slick-list::before {
        width: 178px;
        height: 355px;
        left: 0;
    } */
}

@media (min-width:992px) and (max-width:1150px) {
    .number_count .count_list .media .icon_box {
        margin-right: 5px;
    }
}

@media (min-width:960px) and (max-width:1199.98px) {
    .CaseStudyPortfolios .caseStudy_info .head_title.big_title {
        font-size: 100px !important;
        line-height: 100px !important;
        margin-bottom: 20px;
    }
    .three_column_layout1 .padding_custom {
        padding: 25px 20px;
    }
}

@media (min-width:768px) and (max-width:1279.98px){
    .app_dev_process_flow1 {
        padding-top: 180px;
    }
}

@media (min-width:600px) and (max-width:1279.98px) {
    /* .FourColumnLayout1 .boxes_key:not(:nth-child(even)) {
        padding-right: 25px;
    } */
    .FourColumnLayout1 .list_key_boxes {
        height: 100%;
    }
    .FourColumnLayout1 .boxes_key {
        margin-bottom: 120px;
        position: relative;
        z-index: 1;
    }
    .FourColumnLayout1 .boxes_key:last-child, 
    .FourColumnLayout1 .boxes_key:nth-last-child(2) {
        margin-bottom: 60px;
    }
    .FourColumnLayout1 .boxes_wrapper::After {
        content: "";
        width: 0;
        height: 100%;
        border: 1px dashed #31354d;
        position: absolute;
        top: 0;
        left: 75%;
        -webkit-transform: translate(-50%);
        transform: translate(-50%);
    }
    .FourColumnLayout1 .boxes_wrapper::before {
        content: "";
        width: 0;
        height: 100%;
        border: 1px dashed #31354d;
        position: absolute;
        top: 0;
        left: calc(25% - 12.5px);
        -webkit-transform: translate(-50%);
        transform: translate(-50%);
    }
    .FourColumnLayout3 .boxes_wrapper .boxes_key:nth-child(odd) {
        padding-left: 0;
    }
    .FourColumnLayout3 .boxes_wrapper .boxes_key:nth-child(even) {
        padding-right: 0;
    }
    .main_web_dev .FourColumnLayout1 .boxes_wrapper::before {
        left: calc(25% - 0px);
    }
    .main_web_dev .FourColumnLayout1 .boxes_wrapper::before, .main_web_dev .FourColumnLayout1 .boxes_wrapper::after {
        height: calc(100% - 0px);
    }
    .FourColumnLayout1 .list_key_boxes::After {
        top: calc(100% - 2px);
    }
    .major_social_networks .major_networks_wrap > div:not(.aside) > .content_box {
        width: 50%;
    }
}

@media (min-width:992px) and (max-width:1279.98px) {
    .key_benfits_layout2_and_why_choose .why_visions_layout2 .head_title.big_title {
        letter-spacing: 20px !important;
    }
}

@media (min-width:960px) {
    .number_count .count_list > .lists_grid:not(:last-child) {
        border-right: 1px solid #d7d7d7
    }
    /* .key_benfits_and_why_choose .boxes_key:last-child .list_key_boxes, 
    .key_benfits_and_why_choose .boxes_key:nth-last-child(2) .list_key_boxes, 
    .key_benfits_and_why_choose .boxes_key:nth-last-child(3) .list_key_boxes {
        margin-bottom: 0;
    } */
}

@media (min-width: 2000px) {
    .FourColumnLayout3 .boxes_wrapper {
        margin-bottom: 350px;
    }
}

@media (max-width: 1440px) {
    .main_services .services-listing .col-flex .img-txt-wrap p {
        display: none;
    }
    .main_services .services-listing {
        width: auto;
    }
    .main_services .services-listing .img-txt-wrap .img-wrap {
        padding: 0px 14px;
        margin-bottom: 0;
    }
    .main_services .services-listing .img-txt-wrap .img-wrap img {
        height: 30px;
        width: 30px;
        vertical-align: bottom;
    }
    .main_services .services-listing .img-txt-wrap {
        padding: 12px 0;
    }
    .main_services .services-listing .row-flex .col-flex.active::after {
        width: 61px;
        height: 65px;
        /* top: -12px;
        left: -3px; */
    }
    .main_services .services-listing .row-flex.tab-2::before {
        top: 48px;
    }
    .main_services .services-listing .row-flex.tab-3::before {
        top: 102px;
    }
    .main_services .services-listing .row-flex.tab-4::before {
        top: 156px;
    }
    .main_services .services-listing .row-flex.tab-5::before {
        top: 212px;
    }
    .main_services .services-listing .row-flex.tab-6::before {
        top: 212px;
    }
}

@media (max-width:1279.98px) {
    .FourColumnLayout3 .list_key_boxes .icon_wrapper {
        margin: 0 auto;
        justify-content: center;
    }
    .key_benfits_and_why_choose .right_content_wrap .img_wrapper img {
        max-width: 100%;
        width: 100%;
    }
    .key_benfits_and_why_choose .right_content_wrap .text_wrapper {
        padding: 25px;
        padding-bottom: 0px;
        background-color: #fff;
        margin-top: -50px;
        position: relative;
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
        box-sizing: border-box;
    }
    .red_box_layout_listing aside {
        max-width: 90%;
        margin: 0 auto;
        box-sizing: border-box;
    }
    .red_box_layout_listing aside .listing_of_portfolios {
        max-width: 625px;
        margin: 0 auto;
    }
    .key_benfits_layout2_and_why_choose .team_description_block aside {
        margin: -50px 0 0;
        max-width: 100%;
    }
    .key_benfits_layout2_and_why_choose .top_bg_img {
        background-image: none !important;
    }
    .red_box_layout_listing aside .icon_three_user_wrap {
        display: none;
    }
    .strategy_implement::before {
        width: 220px;
        height: 400px;
    }
    .TwoColumnLayout1 .media .icon_box {
        width: 60px;
    }
    .TwoColumnLayout1 .media .count_text_box {
        width: calc(100% - 60px);
    }
    .four_circle_row {
        display: none !important;
    }
    .FourColumnLayout1 {
        padding-bottom: 60px;
    }
    .FourColumnLayout3::after {
        background-size: 100% auto;
        top: calc(100% - 0px);
        height: 152px;
    }
    .FourColumnLayout3.owl_curve_before_img::before {
        width: 115px;
        height: 215px;
        bottom: -67px;
    }
    .two_coloumn_Below_Banner .container_custom {
        display: block;
        max-width: 100%;
        padding: 0;
    }
    .two_coloumn_Below_Banner .left_info, .two_coloumn_Below_Banner .right_info {
        max-width: 100%;
        flex-basis: 100%;
        padding: 60px !important;
    }
    .two_coloumn_Below_Banner.layout_2 .right_info .img_wrapper img {
        max-width: 100%;
        margin: auto;
    }
    .angle_div_skew-10::before {
        content: "";
        border-width: 12vh 100vw;
    }
    .main_banner_design .bottomkeybenefits.angle_div_skew-10 {
        padding-bottom: 100px;
    }
    .major_social_networks .major_networks_wrap .content_box.middle_img {
        display: none;
    }
    .major_social_networks .major_networks_wrap .content_box .icon {
        max-height: 55px;
    }
    .major_social_networks .major_networks_wrap > div {
        display: flex;
        display: -webkit-flex;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        flex-direction: row !important;
    }
    .major_social_networks .major_networks_wrap .aside .content_box {
        margin: 0;
        flex: 1;
    }    
    .main_web_dev .FourColumnLayout1 .boxes_wrapper {
        margin-bottom: 40px;
    }
    .serviceCatPortfolioSlider aside .content_wrap {
        text-align: center;
    }
    .key_benfits_and_why_choose .list_key_boxes, .key_benfits_and_why_choose .list_key_boxes .head_title, .key_benfits_and_why_choose .list_key_boxes .head_paragraph, .FourColumnLayout3 .list_key_boxes .icon_wrapper, .FourColumnLayout3 .list_key_boxes .head_title, .FourColumnLayout3 .list_key_boxes .head_paragraph {
        text-align: center !important;
    }
    #print-media-design .principles-listing, #local-seo .principles-listing {
        display: none;
    }
    .graphicsShape2 {
        display: none;
    }
    .globel-smo .globel-smo-experience .smo-wrap .innerDiv.years-exp .exp-text p {
        font: 700 26px/1 poppins;
    }
    .globel-smo .globel-smo-experience .smo-wrap .innerDiv.years-exp .exp-text p span {
        font-size: 34px;
    }
    #shopify-development .content_box .circle_wrapper {
        max-height: unset;
        margin: 10px auto 25px auto;
        width: auto;
        height: auto;
    }

    #shopify-development .content_box .circle_wrapper::before,#shopify-development .content_box .circle_wrapper::after {
        display: none;
    }

    #shopify-development .content_box .circle_wrapper .icon_wrap {
        width: 70px;
        height: 70px;
    }

    #shopify-development .content_box .circle_wrapper  .semi-cicle {
        height: 85px;
        width: 85px;
    }
    #shopify-development .content_box:hover .circle_wrapper .semi-cicle::after {
        transform: rotate(180deg);
        transform-origin: top center;
    }
    #openCart-development .six-services {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        justify-content: center;
    }
    
    #openCart-development .six-services li {
        padding: 0 10px;
        -ms-flex: 0 0 33.33%;
        flex: 0 0 33.33%;
        max-width: 33.33%;
    }
    #openCart-development li .icon_wrap {
        width: 80px;
        height: 80px;
    }
    #openCart-development li  .semi-cicle {
        height: 95px;
        width: 95px;
    }
    #magento-extension-development .all-category-mobile {
        margin: auto;
    }
    .serviceCatPortfolioSlider2 aside .link {
        font-size: 12px;
    }
    #openCart-development .six-services .circle_wrapper .icon_wrap img {
        width: 35px;
        height: 35px;
    }
    #shopify-development .six-services .circle_wrapper .icon_wrap img {
        width: 35px;
        height: 35px;
    }
    .main_hireDevelopers .title_row .head_title {
        font-size: 30px !important;
    }
    .two_coloumn_Below_Banner_layout_3 .left_side.max-width-655.ml-auto {
        margin-left: 0;
        max-width: 100%;
        margin-bottom: 50px;
    }
    .two_coloumn_Below_Banner_layout_3 .left_side .text-left:not(ul) {
        text-align: center !important;
    }
    .two_coloumn_Below_Banner_layout_3 ul {
        max-width: 991px;
        margin: 0 auto;
    }
    .key_benfits_and_why_choose .boxes_listing .boxes_key:not(:last-child) .list_key_boxes {
        margin-bottom: 40px;
    }
    #local-seo .graphicsShape2 {
        display: block;
    }
    #magento-extension-development .list_key_boxes .all-category-mobile {
        margin: 0 auto;
    }
    .two_coloumn_Below_Banner_layout_3 .left_side {
        display: block;
    }
    .slick-prev {
        left: 34px !important;
    }
    .slick-next {
        right: 34px !important;
    }
    .serviceCatPortfolioSlider2 .slick-slider .slick-prev {
        left: 10px !important ;
    }
    .serviceCatPortfolioSlider2 .slick-slider .slick-next {
        right: 10px !important;
    }
    .serviceCatPortfolioSlider2.serviceCatPortfolioSlider3 .slick-slider .slick-prev {
        left: 20px !important;
    }
    .serviceCatPortfolioSlider2.serviceCatPortfolioSlider3 .slick-slider .slick-next {
        right: 20px !important;
    }
    .finest-project .slick-slider .slick-prev {
        left: 40px !important;
    }
    .finest-project .slick-slider .slick-next {
        right: 40px !important;
    }    
    .serviceCatPortfolioSlider2 aside .content_wrap {
        margin-top: 25px;
    }
    .slick-slider.graphicsShape1 {
        padding: 0 70px;
    }
    .slick-slider.graphicsShape1 .box-wrapper {
        padding: 0 15px;
    }
}

@media (max-width:1199.98px) {
    .services-detail-content.blue-color.only-center {
        padding-bottom: 100px;
    }
    .services-detail-content.blue-color.only-center::before {
        width: 216px;
        height: 313px;
    }
    .services-detail-content.app-dev .image-wrap img {
        width: 660px;
    }
    .three-box-shape {
        flex-direction: column;
        max-width: 576px;
        margin: 0 auto 30px;
    }
    .three-box-shape ul {
        width: 100%;
    }  
    .three-box-shape ul li {
        padding: 0 !important;
        margin: 0px;
    } 
    .three-box-shape ul.leftSide {
        padding-top: 0;
    }
    .three-box-shape ul li::before, .three-box-shape ul li::after {
        opacity: 0;
    }  
    .three-box-shape ul.leftSide li aside {
        flex-direction: column-reverse;
    }
    .three-box-shape ul.rightSide li aside {
        flex-direction: column;
    }
    .three-box-shape ul li aside .text-wrap * {
        text-align: center !important;
    } 
    .ai-list-wrap ul li {
        width: 33.3333%;
    }
}
@media (max-width: 1024px) {
    .serviceCatPortfolioSlider2 .slick-slider .slick-arrow {
        top: 30%;
    }
    .serviceCatPortfolioSlider2.serviceCatPortfolioSlider3 .slick-slider .slick-arrow {
        top: 40%;
    }
    .serviceCatPortfolioSlider2 .slick-slider::before {
        left: -2px;
        width: 32.4%;
    }
    .serviceCatPortfolioSlider2 .slick-slider::after {
        right: -2px;
        width: 32.4%;
    }
}

@media (max-width: 959.98px) {
    .why_hire_us ul.left_list li {
        padding-left: 30px;
        padding-right: 0;
        text-align: left;
    }
    .why_hire_us ul.left_list li img.tick_done {
        right: unset;
        left: 0;
    }
    .why_hire_us .left_side_list, .why_hire_us .right_side_list, .why_hire_us .left_side_list, .why_hire_us .center_img {
        margin: auto;
        width: 500px;
    }
    .why_hire_us .center_img {
        padding-top: 0;
        padding-bottom: 50px;
        order: 1;
    }    
    .why_hire_us .left_side_list {
        order: 2;
    }
    .why_hire_us .right_side_list {
        order: 3;
    }
    .why_hire_us .lists_hire_us {
        margin: 0;
    }
    .why_hire_us.sec_padding {
        padding-bottom: 40px;
    }
    .CaseStudyPortfolios .caseStudy_info {
        margin-left: 0;
        margin-top: 50px;
    }
    .red_box_layout_listing aside::before {
        width: 164px;
        height: 220px;
        bottom: 90%;
    }
    .key_benfits_layout2_and_why_choose .title_row aside {
        margin: 50px 0;
    }
    .key_benfits_layout2_and_why_choose .title_row .head_title.big_title {
        font-size: 62px !important;
        line-height: 42px !important;
    }
    .key_benfits_layout2_and_why_choose .title_row .head_title {
        font-size: 40px !important;
        line-height: 40px !important;
    }
    .strategy_implement {
        background-position: right;
        padding: 60px 0;
    }
    .strategy_implement .text_wrap {
        padding-left: 0;
        margin-top: 50px;
        height: auto;
    }
    .strategy_implement::before {
        top: -60px;
    }
    .CaseStudyPortfolios.sec_padding {
        padding-bottom: 80px;
    }
    .key_benfits_layout2_and_why_choose.sec_padding {
        padding-bottom: 0;
    }
    .strategy_implement {
        margin-bottom: 0;
    }
    .three_column_layout1 .list_wrapper .media {
        margin-bottom: 20px;
    }
    .post_meta::after {
        left: 0;
        right: 0;
        margin: 0 auto;
    }
    .cloud_based .img_wrapper {
        padding-left: 0;
    }
    .responsive_animated .left_info {
        order: 1;
    }
    .responsive_animated .right_info {
        order: 2;
    }
    .cloud_based.sec_padding {
        padding: 50px 0 100px;
    }
    .services-detail-content .content-wrapper {
        max-width: 100%;
    }
    .form_layout .submit-btn input[type=submit] {
        float: none;
        margin: 0 auto;
    }
    .get-free-quote-wrapper .get-quote-inner-field.full.button input {
        float: none;
        margin: 20px auto 0;
    }
    .we-are-help-you .left_side {
        order: 2;
    }
}



@media (min-width:992px) {
    .two_coloumn_Below_Banner .container_custom {
        flex-wrap: unset;
    }
    .two_coloumn_Below_Banner .left_info {
        padding-right: 70px;
    } 
    /* .main_services #Web-Development .title_row .head_title,
    .main_services #UI-UX-Design .title_row .head_title,
    .main_services #App-Development .title_row .head_title,
    .main_services #E-commerce-Solution .title_row .head_title,
    .main_services #Digital-marketing .title_row .head_title,
    .main_services #Admin-Support .title_row .head_title,
    .main_services .section_title_space .title_row .head_title {
        margin-top: 40px;
        line-height: 1.5 !important;
    }    */
}

@media (min-width:801px) and (max-width:1150px) {
    .number_count .count_list > .lists_grid{
        padding: 10px;
    }
}

@media (max-width:991.98px) {
    
    .key_benfits_and_why_choose .key_benefits_left {
        padding: 35px 25px 5px;
    }
    .container_custom.why_visions_layout2 {
        max-width: 100%;
        padding: 0;
    }
    .key_benfits_layout2_and_why_choose .why_visions_layout2 > div {
        background-image: none !important;
        background-color: #dc302d;
        width: 100%;
    }
    .key_benfits_layout2_and_why_choose .why_visions_layout2 .head_title.big_title {
        letter-spacing: 14px !important;
        color: #fff !important;
        font-size: 100px !important;
        line-height: 90px !important;
    }
    .key_benfits_layout2_and_why_choose .why_visions_layout2 aside section article .icon_three_user_wrap {
        display: none;
    }
    .container_custom.why_visions_layout2 aside {
        max-width: 1310px;
        margin: 0 auto;
        padding: 0 15px;
        box-sizing: border-box;
    }
    .key_benfits_layout2_and_why_choose .why_visions_layout2 aside section article {
        padding: 15px 0 0;
        max-width: 100%;
    } 
    .angle_div_skew-10::before {
        content: "";
        border-width: 6vh 100vw;
    }
    .services-detail-content .custom-row .custom-col-5, .services-detail-content .custom-row .custom-col-7 {
        -webkit-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .services-detail-content .custom-row .custom-col-5 {
        -ms-flex-order: 1;
        order: 1;
    }
    .services-detail-content .custom-row .custom-col-7 {
        -ms-flex-order: 2;
        order: 2;
    }
    .services-detail-content.app-dev .image-wrap img {
        position: relative;
        right: 0;
        width: 100%;
        max-width: 400px;
    }
    .services-detail-content.web-design .custom-row .image-wrap img, .services-detail-content.web-design .custom-row .image-wrap img, .services-detail-content.digi-market .image-wrap img{
        margin-left: auto;
        max-width: 400px;
    }
    .services-detail-content .content-wrapper, .services-detail-content .content-wrapper .head_title {
        text-align: center !important;
    }
    .services-detail-content .content-wrapper .btnWrap {
        justify-content: center;
    }
    .main_services .serviceListWrapper .services-detail-content .container_custom > div {
        display: flex;
        display: -webkit-flex;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        flex-direction: column-reverse;
    }
    .services-detail-content .image-wrap {
        margin-bottom: 0;
    }
    .services-detail-content .content-wrapper {
        margin-top: 0;
    }
    .serviceCatPortfolioSlider aside .title {
        font-size: 14px;
    }
    .serviceCatPortfolioSlider aside .link {
        font-size: 10px;
    }
    .serviceCatPortfolioSlider2 {
        max-width: 730px;
        margin: 0 auto;
    }
    .serviceCatPortfolioSlider2 .slick-list::before {
        width: 329px;
        height: 200px;
    }
    .serviceCatPortfolioSlider2 .slick-slider::before, .serviceCatPortfolioSlider2 .slick-slider::after {
        height: 150px;
        top: 11px;
    }
    .serviceCatPortfolioSlider2 .slick-list {
        padding-top: 11px !important;
    }
    .main_web_dev.main_services #Web-Development .title_row .head_title,
    .main_web_dev.main_services #UI-UX-Design .title_row .head_title,
    .main_web_dev.main_services #App-Development .title_row .head_title,
    .main_web_dev.main_services #E-commerce-Solution .title_row .head_title,
    .main_web_dev.main_services #Digital-marketing .title_row .head_title,
    .main_web_dev.main_services #Admin-Support .title_row .head_title {
        margin-top: 55px;
    } 
    .service_category .sec_one .head_title {
        font-size: 32px !important;
        line-height: 1.2 !important;
    }
    .key_benfits_and_why_choose .list_key_boxes .head_title {
        font-size: 23px !important;
        line-height: 1.2 !important;
    }
    .service_category .head_title {
        font-size: 32px !important;
        line-height: 1.2 !important;
    }
    .major_social_networks .major_networks_wrap .content_box h5 {
        margin-bottom: 8px !important;
    }
    .major_social_networks .major_networks_wrap .content_box {
        padding-top: 0;
    } 
    .technologies_multi_services_circle .img_wrap img {
        max-width: 400px;
        margin: 0 auto;
        width: 100%;
    }
    .technologies_multi_services_circle .img_wrap.text-center:before {
        font-size: 18px;
    }
    .sliderNavForDesign .mobileSlider {
        width: 50px;
        height: 109px;
        overflow: hidden;
        position: absolute;
        top: 63px;
        left: 0;
        right: -230px;
        margin: 0 auto;
        z-index: 1;
    }
    .sliderNavForDesign .mobileSlider .slick-list {
        padding-top: 12px !important;
    }  
    .globel-smo .globel-smo-experience .smo-wrap {
        margin: 30px auto;
        width: 100%;
        max-width: 600px;
    }
    .globel-smo-text .textWrap.text-left .head_title, .globel-smo-text .textWrap.text-left .head_paragraph {
        text-align: center !important;
    }
    #openCart-development .six-services li {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .serviceCatPortfolioSlider2.serviceCatPortfolioSlider3 .slick-slider::before, .serviceCatPortfolioSlider2.serviceCatPortfolioSlider3 .slick-slider::after {
        height: 360px;
        top: 7px;
        width: 37%;
    }
    .serviceCatPortfolioSlider2.serviceCatPortfolioSlider3 .slick-list {
        padding-top: 7px !important;
    }
    .serviceCatPortfolioSlider2.serviceCatPortfolioSlider3  .slick-list::before {
        width: 185px;
        height: 360px;
        left: 0;
    }
    .FourColumnLayout3 .boxes_wrapper {
        margin-bottom: 140px;
    }
    .graphicsShape1 .box-wrapper {
        max-width: 380px;
        margin: 0 auto;
        padding: 0 15px;
    }
    .innerpagesbanner .bannerTextWrap h1 {
        font-size: 30px;
        line-height: 1.2;
    }
    .portfolio_work_header .portfolio_list p {
        font-size: 12px;
    }
    /* .back-to-top {
        width: 30px;
        height: 30px;
    }
    .back-to-top i {
        font-size: 20px;
    } */
    .two_coloumn_Below_Banner_layout_3 .contentWrap p.blue_box {
        font-size: 20px;
        padding: 30px 20px;
        width: 300px;
    }
    .two_coloumn_Below_Banner_layout_3 .right_side .img_wrap {
        padding-left: 30px;
        margin-top: -110px;
    }
    .two_coloumn_Below_Banner_layout_3 .right_side .left-aligned-text {
        font-size: 14px;
        bottom: -17px;
        left: 7px;
    }
    .drag_drop_files .dropzone p {
        padding: 15px;
    }
    .key_benfits_and_why_choose .list_key_boxes .head_paragraph {
        font-size: 14px !important;
    }
    .head_paragraph {
        font-size: 14px !important;
    }
    .bannerDesigningSlider .slick-slider, .broucherSlider .slick-slider, .slick-slider.graphicsShape1 {
        padding: 0 0 15px 0;
        margin-bottom: 30px;
    }
    .bannerDesigningSlider .slick-slider .slick-arrow, .broucherSlider .slick-slider .slick-arrow, .slick-slider.graphicsShape1 .slick-arrow {
        top: 100%;
        transform: inherit;
    }
    .bannerDesigningSlider .slick-slider .slick-prev, .broucherSlider .slick-slider .slick-prev, .slick-slider.graphicsShape1 .slick-prev {
        left: calc(50% - 50px) !important;
    }
    .bannerDesigningSlider .slick-slider .slick-next, .broucherSlider .slick-slider .slick-next, .slick-slider.graphicsShape1 .slick-next {
        right: calc(50% - 50px) !important;
    }
    #brochure-designing {
        padding-bottom: 50px !important;
    }
    .slick-arrow {
        width: 30px !important;
        height: 30px !important;
    }
}

@media (min-width:801px) and (max-width:991.98px) {
    .number_count .count_list .media .icon_box {
        margin: 0 15px;
    }
}

@media (min-width:576px) and (max-width:991.98px) {
    .two_coloumn_Below_Banner .right_info {
        padding: 75px;
    }
}

@media (max-width:800.98px) {
    .chat-app-widget .rcw-widget-container {
        margin: 0;
        z-index: 9999;
    }
    .chat-app-widget .rcw-widget-container .rcw-launcher {
        right: inherit;
        left: 0;
    }
}

@media (max-width:767.98px) {
    .head_title br, .head_paragraph br {
        display: none;
    }    

    .head_title {
        font-size: 30px !important;
        margin-bottom: 10px !important;
    }
    .head_paragraph {
        font-size: 14px !important;
        line-height: 24px !important;
    }
    .innerpagesbanner .bannerTextWrap .btnWrap {
        flex-direction: column;
        align-items: center;
    }
    .innerpagesbanner .bannerTextWrap .btnWrap a {
        margin-bottom: 10px;
        min-width: 192px;
        padding: 10px 0;
        justify-content: center;
        font-size: 14px;
    }
    .number_count .count_list .media .count_text_box h3 {
        font-size: 40px;
    }
    .number_count .count_list .media .count_text_box p {
        font-size: 14px;
    }
    .number_count .container_custom .count_list {
        margin: auto;
    }
    .number_count .count_list .media .icon_box .icon_wrap {
        width: 55px;
        height: 55px;
    }
    .number_count .count_list .media {
        justify-content: center;
        max-width: 240px;
        margin: 0 auto;
    }
    .number_count .count_list .media .icon_box {
        margin-right: 10px;
        width: 65px;
    }
    .number_count .count_list .media .icon_box .icon_wrap img {
        max-width: 23px;
    }
    .number_count .count_list .media .count_text_box {
        width: 100%;
    }
    .two_coloumn_Below_Banner .left_info, .two_coloumn_Below_Banner .right_info {
        padding: 25px !important;
    }
    .two_coloumn_Below_Banner .left_info .head_title {
        font-size: 28px !important;
        text-align: center !important;
    }
    .two_coloumn_Below_Banner .left_info .head_paragraph {
        padding: 0;
        line-height: 24px !important;
        font-size: 14px !important;
        text-align: center !important;
    }
    .two_coloumn_Below_Banner .right_info .head_title, 
    .two_coloumn_Below_Banner .right_info p.head_paragraph {
        text-align: center !important;
    }
    .innerpagesbanner .bannerTextWrap h1 {
        font-size: 28px;  
    }
    .service_category .head_title {
        font-size: 26px !important;
    }
    .service_category .sec_one .head_title {
        font-size: 26px !important;
    }
    .head_title.big_title {
        font-size: 120px !important;
        line-height: 115px !important;
    }
    .two_coloumn_Below_Banner .right_info .head_title.med_title {
        font-size: 28px !important;
        line-height: 36px !important;
        margin-bottom: 15px;
    }
    .CaseStudyPortfolios .caseStudy_info .head_title.big_title {
        font-size: 80px !important;
        line-height: 90px !important;
        margin-bottom: 20px;
    }
    .CaseStudyPortfolios .caseStudy_info::After {
        height: 86%;
    }
    .key_benfits_layout2_and_why_choose .team_description_block aside {
        padding: 30px;
    }
    .strategy_implement::before {
        width: 120px;
        height: 220px;
        top: -30px;
    }
    .three_column_layout1 .padding_custom {
        padding: 25px 0px 0px;
    }
    .key_benfits_and_why_choose .key_benefits_left .head_title, 
    .key_benfits_and_why_choose .key_benefits_left .head_paragraph {
        text-align: center !important;
    }
    .key_benfits_and_why_choose .right_content_wrap .head_title, 
    .key_benfits_and_why_choose .right_content_wrap .head_paragraph {
        text-align: center !important;
    }
    .key_benfits_and_why_choose .list_key_boxes,
    .key_benfits_and_why_choose .list_key_boxes .head_title, 
    .key_benfits_and_why_choose .list_key_boxes .head_paragraph {
        text-align: center !important;
    }
    .head_title.leftalign, 
    .head_paragraph.leftalign {
        text-align: center !important;
    }
    .border_bottom_w-10::After {
        right: 0;
        margin: 0 auto;
    }
    .explore_projects .btnWrap .hire_developers {
        font-size: 14px;
    }
    .FourColumnLayout3 .list_key_boxes .icon_wrapper {
        text-align: center;
        padding-top: 0;
    }
    .FourColumnLayout3.owl_curve_before_img::before {
        width: 80px;
        height: 190px;
    }
    .two_coloumn_Below_Banner .container_custom {
        max-width: 100%;
        padding: 0;
    }
    .key_benfits_and_why_choose.topkeybenefits .container_custom {
        max-width: 100%;
        padding: 0;
    }
    .key_benfits_layout2_and_why_choose .container_custom.team_description_block {
        max-width: 100%;
        padding: 0;
    }
    .sec_padding {
        padding: 40px 0;
    }
    .key_benfits_and_why_choose .boxes_key:last-child .list_key_boxes {
        margin-bottom: 0;
    }
    .explore_projects .boxes_wrapper .boxes_key:last-child .list_key_boxes p {
        margin-bottom: 0 !important;
    }
    .explore_projects.sec_padding {
        padding-bottom: 110px;
    }
    .explore_projects::After {
        height: 110px;
    }
    .TwoColumnLayout1 .media .count_text_box h3 {
        font-size: 28px;
        line-height: 36px;
    }
    .red_box_layout_listing aside::before {
        width: 124px;
        height: 180px;
        bottom: calc(100% - 50px);
    }
    .key_benfits_layout2_and_why_choose .title_row aside {
        margin-top: 0;
    }
    .red_box_layout_listing aside {
        padding-top: 40px;
    }
    .key_benfits_layout2_and_why_choose .why_visions_layout2 > div {
        padding: 40px 0;
    }
    .key_benfits_layout2_and_why_choose .why_visions_layout2 .head_title.big_title {
        font-size: 66px !important;
        line-height: 70px !important;
    }
    .key_benfits_and_why_choose .title_row aside,
    .main_hireMagento_dev .explore_projects .text_wrap aside {
        margin-bottom: 40px;
    }
    .slick-dots li {
        width: 10px !important;
        height: 10px !important;
    }
    .CaseStudyPortfolios .caseStudy_info {
        padding-left: 15px;
        padding-right: 15px;
    }
    .main_meanstack .key_benfits_and_why_choose.bottomkeybenefits {
        padding-top: 15px;
    } 
    .app_dev_process_flow1 {
        padding-top: 100px;
    }
    .two_coloumn_Below_Banner.layout_2.two_coloumn_Below_Banner.layout_2 .right_info h4 {
        text-align: center;
    }
    .services-detail-content.blue-color.only-center::before {
        width: 126px;
        height: 233px;
    }
    .services-detail-content.app-dev .content-wrapper, .services-detail-content.graph-design .content-wrapper {
        margin-bottom: 40px;
    }
    .services-detail-content .content-wrapper p{
        font-size: 14px;
        margin: 0 0 15px 0;
    }
    .services-detail-content .content-wrapper .tech_icons_wrapper img {
        width: 25px;
        height: 25px;
    }
    .services-detail-content .image-wrap img {
        width: 80%;
    }
    .serviceCatPortfolioSlider2 .slick-slider::before, .serviceCatPortfolioSlider2 .slick-slider::after {
        opacity: 0;
    }
    .serviceCatPortfolioSlider2 .slick-list::before {
        opacity: 0;
    }
    /* .serviceCatPortfolioSlider2:not(.serviceCatPortfolioSlider3) {
        width: 100vw;
        position: relative;
        left: 50%;
        margin-left: -50vw;
    } */
    
    .serviceCatPortfolioSlider2 .slick-slider .slick-slide:not(.slick-center) .website_img img {
        transform: scale(0.9);
        opacity: 0.3;
    }
    .serviceCatPortfolioSlider2 .slick-slider aside .website_img img {
        width: 100%;
        transition: 0.5s;
    }
    .FourColumnLayout3 {
        background-position: 50% 100%;
    }
    .sliderNavForDesign .mobileSlider {
        display: none;
    }
    .broucherSlider .slick-slide:not(.slick-center), .broucherSlider .slick-slide {
        transform: scale(1) translateY(0px);
    }
    .broucherSlider .slick-slide img {
        margin: 0 auto;
    }
    .broucherSlider .slick-slide:not(.slick-center) aside::before {
        opacity: 0;
    }
    #openCart-development .six-services li {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .FourColumnLayout3 .boxes_wrapper {
        margin-bottom: 80px;
    }
    .buttonStyleFxd {
        left: 10px;
        display: none;
    }
    .buttonStyleFxd .buttonLinkGet {
        padding-right: 6px;
    }
    .main_pageNotFound .errorPageContent .head_paragraph a {
        font-size: 16px;
    }
    .main_pageNotFound .errorPageContent .head_title {
        font-size: 26px !important;
    }
    .main_pageNotFound .errorPageContent {
        height: calc(100vh - 360px);
    }
    .two_coloumn_Below_Banner_layout_3 ul li::before {
        top: 8px;
    }
    .two_coloumn_Below_Banner_layout_3 .right_side .img_wrap {
        margin-top: 0;
        padding: 0;
    }
    .two_coloumn_Below_Banner_layout_3 .right_side .left-aligned-text {
        transform: inherit;
        display: block;
        background: #f34835;
        width: 100%;
        position: static;
        font-size: 17px;
        line-height: 1.2;
        color: #ffffff;
        font-family: 'Poppins' !important;
        font-weight: 600;
        text-align: center;
        padding: 8px 20px;
    }
    .two_coloumn_Below_Banner_layout_3 .contentWrap p.blue_box {
        width: 100%;
        margin-right: 0;
    }
    .two_coloumn_Below_Banner_layout_3 .contentWrap p.red_box {
        width: 100%;
    }
    .two_coloumn_Below_Banner_layout_3 .contentWrap {
        flex-direction: column;
        text-align: center;
    }
    .drag_drop_files .dropzone p {
        padding: 10px;
    }
    .bannerDesigningSlider .img_wrap img {
        padding: 0px;
    }
    .number_count .count_list .media .count_text_box h3 {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .number_count .count_list .media .count_text_box h3 img {
        padding-right: 10px;
    }
    .our_clients.sec_padding {
        padding: 30px 0;
    }
    .number_count .count_list > .lists_grid:nth-child(even) .media {
        border: 0;
    }
    .number_count .count_list > .lists_grid
    {
        padding: 0 0 40px 0;
    }
    .number_count.sec_padding {
        padding: 30px 0 10px 0;
    }
    .we-are-help-you .hereToHelpYou p {
        font-size: 12px;
        padding-right: 40px;
    }
    .we-are-help-you .hereToHelpYou strong {
        font-size: 13px;
    }
    .our_clients .slick-slider, .testimonials_2_layout .slick-slider {
        padding: 0;
    }
    .serviceCatPortfolioSlider2 .slick-slider .slick-arrow {
        top: 36%;
    }
    .serviceCatPortfolioSlider2.serviceCatPortfolioSlider3{
        padding-top: 30px !important;
    }
    .ai-list-wrap ul li {
        width: 50%;
    }
}


@media (min-width:600px) and (max-width:959.98px) {
    .key_benfits_and_why_choose .boxes_key:last-child .list_key_boxes, 
    .key_benfits_and_why_choose .boxes_key:nth-last-child(2) .list_key_boxes {
        margin-bottom: 0;
    }
}

@media (max-width:599.98px) {
    .FourColumnLayout3 .list_key_boxes .icon_wrapper,.FourColumnLayout1 .list_key_boxes .icon_wrapper,.key_benfits_and_why_choose .list_key_boxes img {
        min-height: auto;
    }
    .TwoColumnLayout1 .media {
        flex-direction: column;
        margin-bottom: 30px;
    }
    .TwoColumnLayout1 .second_col .media {
        margin-bottom: 0;
    }
    .TwoColumnLayout1 .media .icon_box {
        width: 100%;
    }
    .TwoColumnLayout1 .media .count_text_box {
        width: 100%;
        text-align: center;
    }
    .TwoColumnLayout1 .media .icon_box img {
        max-width: 80px;
    }
    .FourColumnLayout1 .boxes_key {
        margin-bottom: 120px;
        position: relative;
        z-index: 1;
    }
    .FourColumnLayout1 .boxes_key:last-child {
        margin-bottom: 60px;
    }
    .FourColumnLayout1 .boxes_wrapper::after {
        content: "";
        width: 0;
        height: 100%;
        border: 1px dashed #31354d;
        position: absolute;
        top: 5px;
        left: 50%;
        -webkit-transform: translate(-50%);
        transform: translate(-50%);
    }
    .FourColumnLayout2 .list_key_boxes .icon_wrapper img {
        max-height: 60px;
    }
    .FourColumnLayout2 .list_key_boxes .head_paragraph {
        font-size: 14px !important;
    }
    .FourColumnLayout2 .boxes_wrapper .boxes_key {
        padding: 0 10px 20px;
    }
    .FourColumnLayout2 .btnWrap {
        margin-top: 20px;
    }
    .FourColumnLayout2 .btnWrap a {
        font-size: 12px;
        padding: 10px;
    }
    .FourColumnLayout3 .boxes_wrapper .boxes_key {
        padding-left: 0;
        padding-right: 0;
    }
    .number_count .count_list > .lists_grid {
        padding: 0px 0 30px 0;
    }
    .number_count .count_list > .lists_grid:last-child {
        padding-bottom: 0;
    }
    .key_benfits_and_why_choose.bottomkeybenefits .boxes_key:last-child .head_paragraph {
        margin-bottom: 0 !important;
    }
    .major_social_networks .major_networks_wrap > div {
        flex-direction: column !important;
    }
    .services-detail-content .content-wrapper .tech_icons_wrapper {
        text-align: center;
    }
    .FourColumnLayout1 .boxes_key , .serviceCatPortfolioSlider aside , .key_benfits_and_why_choose .list_key_boxes{
        padding: 0px;
    }
    .logoSlider aside .logo_img {
        padding: 0;
    }   
    .logoSlider aside .logo_img img {
        position: static;
        transform: inherit;
        width: 100%;
        padding: 10px;
    }
    .globel-smo .globel-smo-experience .smo-wrap .smocolumn {
        flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        max-width: 100%;
    }
    .globel-smo .globel-smo-experience .smo-wrap .smocolumn:first-child {
        order: 2;
    }
    .App {
        padding-top: 67px;
    }
    .why_hire_us .center_img img {
        max-width: 100%;
    }
    .ai-list-wrap ul li {
        width: 100%;
    }
}   
@media (max-width:480.98px){
    .serviceCatPortfolioSlider2 .slick-slider .slick-arrow {
        top: 27%;
    }
}